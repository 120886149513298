import {
  GET_USER_GROUPS_PENDING,
  GET_USER_GROUPS_FULFILLED,
  GET_USER_GROUPS_REJECTED,
  GET_USER_GROUPS_DROPDOWN_PENDING,
  GET_USER_GROUPS_DROPDOWN_FULFILLED,
  GET_USER_GROUPS_DROPDOWN_REJECTED,
  ADD_USER_GROUP_PENDING,
  ADD_USER_GROUP_FULFILLED,
  ADD_USER_GROUP_REJECTED,
  UPDATE_USER_GROUP_PENDING,
  UPDATE_USER_GROUP_FULFILLED,
  UPDATE_USER_GROUP_REJECTED,
  GET_USER_GROUP_BY_ID_PENDING,
  GET_USER_GROUP_BY_ID_FULFILLED,
  GET_USER_GROUP_BY_ID_REJECTED,
  DELETE_USER_GROUP_PENDING,
  DELETE_USER_GROUP_FULFILLED,
  DELETE_USER_GROUP_REJECTED,
  GET_MODULES_LIST_PENDING,
  GET_MODULES_LIST_FULFILLED,
  GET_MODULES_LIST_REJECTED,
} from './UserGroupActionTypes';
const initialState = {
  userGroupData: [],
  userGroupDropdown: [],
  modulesData: [],
  userGroupIdData: {},
  processing: false,
  userDataProcessing: false,
  userDropdownProcessing: false,
  updateDataProcessing: false,
  modulesListProcessing: false,
};
const getUserGroupDataPending = (state) => ({
  ...state,
  userDataProcessing: true,
});

const getUserGroupDataSuccess = (state, action) => {
  return {
    ...state,
    userGroupData: action.payload.data.data,
    userDataProcessing: false,
  };
};

const getUserGroupDataRejected = (state) => ({
  ...state,
  userDataProcessing: false,
});

const getUserGroupDropdownPending = (state) => ({
  ...state,
  userDropdownProcessing: true,
});

const getUserGroupDropdownSuccess = (state, action) => {
  return {
    ...state,
    userGroupDropdown: action.payload.data.data,
    userDropdownProcessing: false,
  };
};

const getUserGroupDropdownRejected = (state) => ({
  ...state,
  userDropdownProcessing: false,
});

const getModulesListDataPending = (state) => ({
  ...state,
  modulesListProcessing: true,
});

const getModulesListDataSuccess = (state, action) => {
  return {
    ...state,
    modulesData: action.payload.data.data,
    modulesListProcessing: false,
  };
};

const getModulesListDataRejected = (state) => ({
  ...state,
  modulesListProcessing: false,
});

const addUserGroupDataSuccess = (state) => {
  return {
    ...state,
    processing: false,
  };
};

const getUserGroupInfoByIdSuccess = (state, action) => {
  return {
    ...state,
    userGroupIdData: action.payload.data.data,
    processing: false,
  };
};

const deleteUserGroupSuccess = (state) => ({
  ...state,
  processing: false,
});

const updateUserGroupDataPending = (state) => ({
  ...state,
  updateDataProcessing: true,
});

const updateUserGroupDataSuccess = (state) => ({
  ...state,
  updateDataProcessing: false,
});

const updateUserGroupDataRejected = (state) => ({
  ...state,
  updateDataProcessing: false,
});

const setProcessingPending = (state) => ({
  ...state,
  processing: true,
});

const setProcessingRejected = (state) => ({
  ...state,
  processing: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_GROUPS_PENDING:
      return getUserGroupDataPending(state, action);

    case GET_USER_GROUPS_FULFILLED:
      return getUserGroupDataSuccess(state, action);

    case GET_USER_GROUPS_REJECTED:
      return getUserGroupDataRejected(state, action);

    case GET_USER_GROUPS_DROPDOWN_PENDING:
      return getUserGroupDropdownPending(state, action);

    case GET_USER_GROUPS_DROPDOWN_FULFILLED:
      return getUserGroupDropdownSuccess(state, action);

    case GET_USER_GROUPS_DROPDOWN_REJECTED:
      return getUserGroupDropdownRejected(state, action);

    case GET_USER_GROUP_BY_ID_PENDING:
      return setProcessingPending(state, action);

    case GET_USER_GROUP_BY_ID_FULFILLED:
      return getUserGroupInfoByIdSuccess(state, action);

    case GET_USER_GROUP_BY_ID_REJECTED:
      return setProcessingRejected(state, action);

    case ADD_USER_GROUP_PENDING:
      return setProcessingPending(state, action);

    case ADD_USER_GROUP_FULFILLED:
      return addUserGroupDataSuccess(state, action);

    case ADD_USER_GROUP_REJECTED:
      return setProcessingRejected(state, action);

    case UPDATE_USER_GROUP_PENDING:
      return updateUserGroupDataPending(state, action);

    case UPDATE_USER_GROUP_FULFILLED:
      return updateUserGroupDataSuccess(state, action);

    case UPDATE_USER_GROUP_REJECTED:
      return updateUserGroupDataRejected(state, action);

    case DELETE_USER_GROUP_PENDING:
      return setProcessingPending(state, action);

    case DELETE_USER_GROUP_FULFILLED:
      return deleteUserGroupSuccess(state, action);

    case DELETE_USER_GROUP_REJECTED:
      return setProcessingRejected(state, action);

    case GET_MODULES_LIST_PENDING:
      return getModulesListDataPending(state, action);

    case GET_MODULES_LIST_FULFILLED:
      return getModulesListDataSuccess(state, action);

    case GET_MODULES_LIST_REJECTED:
      return getModulesListDataRejected(state, action);

    default:
      return {
        ...state,
      };
  }
}
