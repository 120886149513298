import {
  BUCKET_NAME,
  AWS_REGION,
  IDENTITY_POOL_ID,
  POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
  TACO_BUCKET_NAME,
  TACO_LOG_BUCKET_NAME,
} from '../utils/envVariables';

export const awsConfigure = (isTaco, isLog, clientId) => ({
  Auth: {
    cognito_identity_pool_id: IDENTITY_POOL_ID,
    cognito_region: AWS_REGION,
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: POOL_ID,
    userPoolWebClientId: clientId || USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: isLog
        ? TACO_LOG_BUCKET_NAME
        : isTaco
        ? TACO_BUCKET_NAME
        : BUCKET_NAME,
      region: AWS_REGION,
      identityPoolId: IDENTITY_POOL_ID,
    },
  },
});

export const storageConfigure = (isTaco, isLog) => ({
  bucket: isLog
    ? TACO_LOG_BUCKET_NAME
    : isTaco
    ? TACO_BUCKET_NAME
    : BUCKET_NAME,
  region: AWS_REGION,
  level: 'public',
  identityPoolId: IDENTITY_POOL_ID,
});
