export const GET_SENSOR_INVENTORY_PENDING = 'GET_SENSOR_INVENTORY_PENDING';
export const GET_SENSOR_INVENTORY_FULFILLED = 'GET_SENSOR_INVENTORY_FULFILLED';
export const GET_SENSOR_INVENTORY_REJECTED = 'GET_SENSOR_INVENTORY_REJECTED';
export const ADD_SENSOR_INVENTORY_PENDING = 'ADD_SENSOR_INVENTORY_PENDING';
export const ADD_SENSOR_INVENTORY_FULFILLED = 'ADD_SENSOR_INVENTORY_FULFILLED';
export const ADD_SENSOR_INVENTORY_REJECTED = 'ADD_SENSOR_INVENTORY_REJECTED';
export const UPDATED_SENSOR_INVENTORY_PENDING =
  'UPDATED_SENSOR_INVENTORY_PENDING';
export const UPDATED_SENSOR_INVENTORY_FULFILLED =
  'UPDATED_SENSOR_INVENTORY_FULFILLED';
export const UPDATED_SENSOR_INVENTORY_REJECTED =
  'UPDATED_SENSOR_INVENTORY_REJECTED';
export const DELETE_SENSOR_INVENTORY_PENDING =
  'DELETE_SENSOR_INVENTORY_PENDING';
export const DELETE_SENSOR_INVENTORY_FULFILLED =
  'DELETE_SENSOR_INVENTORY_FULFILLED';
export const DELETE_SENSOR_INVENTORY_REJECTED =
  'DELETE_SENSOR_INVENTORY_REJECTED';
export const GET_SENSOR_INVENTORY_BY_ID_PENDING =
  'GET_SENSOR_INVENTORY_BY_ID_PENDING';
export const GET_SENSOR_INVENTORY_BY_ID_FULFILLED =
  'GET_SENSOR_INVENTORY_BY_ID_FULFILLED';
export const GET_SENSOR_INVENTORY_BY_ID_REJECTED =
  'GET_SENSOR_INVENTORY_BY_ID_REJECTED';
export const GET_SENSOR_INVENTORY = 'GET_SENSOR_INVENTORY';
export const DELETE_SENSOR_INVENTORY = 'DELETE_SENSOR_INVENTORY';
export const ADD_SENSOR_INVENTORY = 'ADD_SENSOR_INVENTORY';
export const UPDATED_SENSOR_INVENTORY = 'UPDATED_SENSOR_INVENTORY';
export const GET_SENSOR_INVENTORY_BY_ID = 'GET_SENSOR_INVENTORY_BY_ID';
