import { SET_NOTE_DATA } from './NoteActionTypes';

const initialState = {
  noteDataById: [],
};

export default function noteReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTE_DATA:
      return {
        ...state,
        noteDataById: action?.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
