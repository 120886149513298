export const CURRENT_YEAR = new Date().getFullYear();

const localeDate = new Date().toLocaleDateString();
const localeTime = new Date().toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

export const buildData = {
  buildTime: `${localeDate} ${localeTime}`,
  buildVersion: 'V1.0',
};

export const getServerVersionData = () => {
  return new Promise((resolve) => {
    fetch('/version.json')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch(() => {
        resolve(buildData);
      });
  });
};
