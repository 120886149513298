import React, { Component, useEffect } from 'react';
import { HashRouter, Route, Switch, useLocation } from 'react-router-dom';
import './scss/style.scss';
import { getClient } from './utils/getClient';
import { Helmet } from 'react-helmet';
import Amplify, { Storage } from 'aws-amplify';
import { awsConfigure, storageConfigure } from './services/aws';
import { connect } from 'react-redux';
import { TACO_USER } from './constants';
import { getToken } from './utils/share';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const SignUp = React.lazy(() => import('./views/pages/register/signUp/Signup'));
const SignIn = React.lazy(() => import('./views/pages/login/signIn/SignIn'));

const ForgotPassword = React.lazy(() =>
  import('./views/pages/forgotpassword/ForgotPassword'),
);

const ChangePassword = React.lazy(() =>
  import('./views/pages/changepassword/changePassword'),
);
const SSO = React.lazy(() => import('./views/pages/login/LoginSso'));
const SsoSuccess = React.lazy(() => import('./views/pages/sso/SsoSuccess'));
const SsoFailure = React.lazy(() => import('./views/pages/sso/SsoFailure'));
const ConfirmPassword = React.lazy(() =>
  import('./views/pages/confirmPassword/ConfirmPassword'),
);

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const MessagePage = React.lazy(() =>
  import('./views/pages/page404/messagePage'),
);
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const VerifyUser = React.lazy(() =>
  import('./views/pages/verifyuser/VerifyUser'),
);
const PrivacyPolicy = React.lazy(() =>
  import('./views/pages/privacyPolicy/PrivacyPolicy'),
);
const DeviceTracker = React.lazy(() =>
  import('./views/deviceTracker/DeviceTracker'),
);

const ContactUs = React.lazy(() => import('./views/pages/contactus/ContactUs'));
const TermCondition = React.lazy(() =>
  import('./views/pages/term&condition/TermCondition'),
);

const ConfirmationScreen = React.lazy(() =>
  import('./views/pages/verifyuser/confirmationScreen/ConfirmationScreen'),
);

const ChangePasswordComponent = (props) => {
  const currentClient = getClient();

  return (
    <ChangePassword {...props} {...currentClient[0]} {...currentClient[1]} />
  );
};

const ForgotPasswordComponent = (props) => {
  const currentClient = getClient();
  return (
    <ForgotPassword {...props} {...currentClient[0]} {...currentClient[1]} />
  );
};

const ConfirmPasswordComponent = (props) => {
  const currentClient = getClient();
  return (
    <ConfirmPassword {...props} {...currentClient[0]} {...currentClient[1]} />
  );
};

const LoginComponent = (props) => {
  const currentClient = getClient();

  return <Login {...props} {...currentClient[0]} {...currentClient[1]} />;
};
const SsoComponent = (props) => {
  const currentClient = getClient();
  return <SSO {...props} {...currentClient[0]} {...currentClient[1]} />;
};
const SsoSucsessComponent = (props) => {
  const currentClient = getClient();
  return <SsoSuccess {...props} {...currentClient[0]} {...currentClient[1]} />;
};
const SsoFailureComponent = (props) => {
  const currentClient = getClient();
  return <SsoFailure {...props} {...currentClient[0]} {...currentClient[1]} />;
};
const SignUpComponent = (props) => {
  const currentClient = getClient();

  return <SignUp {...props} {...currentClient[0]} {...currentClient[1]} />;
};

const SignInComponent = (props) => {
  const currentClient = getClient();

  return <SignIn {...props} {...currentClient[0]} {...currentClient[1]} />;
};

const ContactUsComponent = (props) => {
  const currentClient = getClient();
  return <ContactUs {...props} {...currentClient[0]} {...currentClient[1]} />;
};
const TermConditionComponent = (props) => {
  const currentClient = getClient();

  return (
    <TermCondition {...props} {...currentClient[0]} {...currentClient[1]} />
  );
};

const EmailConfirmationComponent = (props) => {
  const currentClient = getClient();
  return (
    <ConfirmationScreen
      {...props}
      {...currentClient[0]}
      {...currentClient[1]}
    />
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

class App extends Component {
  constructor(props) {
    const { isCC } = getToken();
    super(props);
    const isTaco = this.props.client === TACO_USER;
    const isLog = window.location.hash === '#/diagnosis';

    if (!isCC) Amplify.configure(awsConfigure(isTaco, isLog));
    if (!isCC) Storage.configure(storageConfigure(isTaco, isLog));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      const { isCC } = getToken();

      const isTaco = this.props.client === TACO_USER;
      const isLog = window.location.hash === '#/diagnosis';
      if (!isCC) Amplify.configure(awsConfigure(isTaco, isLog));
      if (!isCC) Storage.configure(storageConfigure(isTaco, isLog));
    }
  }

  render() {
    const currentClient = getClient(this.props.client);
    const dynamicProps = { ...currentClient[0] };
    const currentMetaData = { ...currentClient[1] };
    const icon = currentMetaData?.icon;
    const title = currentMetaData?.title;

    return (
      <div>
        <Helmet
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              href: `${icon}`,
            },
          ]}
          title={title}
        />
        <HashRouter>
          <React.Suspense fallback={loading}>
            <ScrollToTop />
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) =>
                  ['isS8CGP'].includes(Object.keys(dynamicProps)[0]) ? (
                    <SignInComponent {...props} />
                  ) : (
                    <LoginComponent {...props} />
                  )
                }
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) =>
                  ['isS8CGP'].includes(Object.keys(dynamicProps)[0]) ? (
                    <SignUpComponent {...props} />
                  ) : (
                    <Register {...props} />
                  )
                }
              />
              <Route
                exact
                path="/terms&condition"
                name="Term and Condition Page"
                render={(props) => <TermConditionComponent {...props} />}
              />
              <Route
                exact
                path="/changepassword"
                name="Change Password Page"
                render={(props) => <ChangePasswordComponent {...props} />}
              />
              <Route
                exact
                path="/forgotpassword"
                name="Forgot Password Page"
                render={(props) => <ForgotPasswordComponent {...props} />}
              />
              <Route
                exact
                path="/sso"
                name="SSO Page"
                render={(props) => <SsoComponent {...props} />}
              />
              <Route
                exact
                path="/sso/success"
                name="SSO Page"
                render={(props) => <SsoSucsessComponent {...props} />}
              />
              <Route
                exact
                path="/sso/failure"
                name="SSO Page"
                render={(props) => <SsoFailureComponent {...props} />}
              />
              <Route
                exact
                path="/confirmpassword"
                name="Confirm Password Page"
                render={(props) => <ConfirmPasswordComponent {...props} />}
              />
              <Route
                path="/email-verification"
                name="Email verification Page"
                render={(props) => <EmailConfirmationComponent {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/not-found"
                name="Message"
                render={(props) => <MessagePage {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/verifyuser"
                name="Verify User Page"
                render={(props) => <VerifyUser {...props} />}
              />
              <Route
                path="/link-accounts"
                name="Verify User Page"
                render={(props) => <VerifyUser {...props} fromLink={true} />}
              />
              <Route
                path="/privacypolicy"
                name="Privacy Policy"
                render={(props) => {
                  const currentClient = getClient();
                  return (
                    <PrivacyPolicy
                      {...props}
                      {...currentClient[0]}
                      {...currentClient[1]}
                    />
                  );
                }}
              />

              {/* For OxTech and ZakTrack only */}

              {!['isDartDisplay', 'isWatercop'].includes(
                Object.keys(dynamicProps)[0],
              ) && (
                <Route
                  path="/devicetracker/:id"
                  name="Device Tracker"
                  render={(props) => (
                    <DeviceTracker {...props} {...dynamicProps} />
                  )}
                />
              )}
              {['isAATA'].includes(Object.keys(dynamicProps)[0]) && (
                <Route
                  path="/contactus"
                  name="Contact Us Page"
                  render={(props) => <ContactUsComponent {...props} />}
                />
              )}
              <Route path="/" name="Home" component={TheLayout} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state?.login?.userProfileData?.data?.Client?.projectName,
});
export default connect(mapStateToProps)(App);
