import {
  GET_GATEWAY_PENDING,
  GET_GATEWAY_FULFILLED,
  GET_GATEWAY_REJECTED,
} from './GatewayActionTypes';

const initialState = {
  gatewayData: [],
  isLoading: false,
  processing: false,
};

const getGatewayPending = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const getGatewaySuccess = (state, action) => {
  return {
    ...state,
    gatewayData: action.payload.data.data,
    isLoading: false,
  };
};

const getGatewayFail = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GATEWAY_PENDING:
      return getGatewayPending(state, action);

    case GET_GATEWAY_FULFILLED:
      return getGatewaySuccess(state, action);

    case GET_GATEWAY_REJECTED:
      return getGatewayFail(state, action);

    default:
      return {
        ...state,
      };
  }
}
