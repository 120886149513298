import {
  GET_ORDERS_FULFILLED,
  GET_ORDERS_PENDING,
  GET_ORDERS_REJECTED,
} from './OrderActionTypes';

const initialState = {
  orderData: [],
  isLoading: false,
  orderIdData: {},
  processingData: false,
};

const getOrderSuccess = (state, action) => {
  return {
    ...state,
    orderData: action.payload.data.data,
    isLoading: false,
  };
};

const setProcessingPending = (state) => ({
  ...state,
  isLoading: true,
});

const setProcessingRejected = (state) => ({
  ...state,
  isLoading: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS_PENDING:
      return setProcessingPending(state, action);

    case GET_ORDERS_FULFILLED:
      return getOrderSuccess(state, action);

    case GET_ORDERS_REJECTED:
      return setProcessingRejected(state, action);

    default:
      return {
        ...state,
      };
  }
}
