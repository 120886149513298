import { isEmpty } from 'lodash';
import { CUSTOM_LOGOUT_EVENT, NOTIFICATION, ROUTE_MAP } from '../constants';
import { getClientConfig, getClientUserGroupConfig } from './getClient';
import { pushNotification } from './notification';
import moment from 'moment';
import 'moment-timezone';
import { timeZoneMapping } from './timeZone';
import { Auth } from 'aws-amplify';
import { emitCustomEvent } from 'react-custom-events';

export const getUserDetails = () => {
  let user = localStorage.getItem('redux');
  user = user ? JSON.parse(user) : '';
  return user;
};
export const isLoggedIn = () => {
  let isCC = localStorage.getItem('isCC') ?? undefined;
  let user = localStorage.getItem('redux');
  user = user ? JSON.parse(user) || '' : '';
  if (isCC) {
    return true;
  } else if (!isCC && user && Object.keys(user.login.userData).length !== 0) {
    return true;
  } else {
    return false;
  }
};
export const isAllowed = (path) => {
  let user = getUserDetails();
  let tabList = user?.login?.userProfileData?.data?.dashboardTabsVisible || [];
  if (tabList.length && tabList.includes(path)) {
    return true;
  } else {
    return false;
  }
};
export const getFirstTab = () => {
  let user = getUserDetails();
  let tabList = user?.login?.userProfileData?.data?.dashboardTabsVisible || [];
  if (tabList.length && tabList.includes('dashboard')) {
    return '/dashboard';
  } else if (tabList.length) {
    return ROUTE_MAP.find((tab) => tab.name === tabList[0]).redirectTo;
  } else {
    emitCustomEvent(CUSTOM_LOGOUT_EVENT);
    Auth.signOut();
    localStorage.removeItem('redux');
    return '/login';
  }
};

export const commonLogoutFunc = (history) => {
  localStorage.removeItem('redux');
  resetLocalStorage();
  Auth.signOut();
  history.push('/login');
  emitCustomEvent(CUSTOM_LOGOUT_EVENT);
};

export const resetLocalStorage = () => {
  const temp = ['userColumnsConfig'];
  Object.keys(localStorage).forEach(function (key) {
    const item = temp.find((item) => key.includes(item));
    if (!item) {
      localStorage.removeItem(key);
    }
  });
};

export const stringToColor = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 3) - hash);
  }
  var color = Math.abs(hash).toString(16).substring(0, 6);

  return '#' + '000000'.substring(0, 6 - color.length) + color;
};
export const reduceColorOpacity = (hex, alpha) => {
  var r = parseInt(hex?.slice(1, 3), 16),
    g = parseInt(hex?.slice(3, 5), 16),
    b = parseInt(hex?.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const searchAndRemoveEntry = (arrayInfo, removeData) => {
  let newArrayObj = [...arrayInfo];
  newArrayObj = newArrayObj.filter((i) => i !== removeData);
  return newArrayObj;
};

export const getUserType = () => {
  let userType = localStorage.getItem('redux');
  userType = userType ? JSON.parse(userType) : '';
  userType =
    userType.login.userProfileData && userType.login.userProfileData.data
      ? userType.login.userProfileData.data.type
      : null;
  return userType;
};

export const getToken = () => {
  let ssoToken = localStorage.getItem('jwtToken') ?? undefined;
  ssoToken = ssoToken && ssoToken !== '' ? ssoToken : undefined;
  let isCC = localStorage.getItem('isCC') ?? undefined;
  isCC = isCC && isCC !== '' ? isCC : undefined;
  return { ssoToken, isCC };
};

export const getClientId = () => {
  let clientId = localStorage.getItem('redux');
  clientId = clientId ? JSON.parse(clientId) : '';
  clientId =
    clientId.login.userProfileData && clientId.login.userProfileData.data
      ? clientId.login.userProfileData.data.clientId
      : null;
  return clientId;
};
export const getTrimmedTimezone = (timeZone) => {
  if (timeZone && timeZone.length === 3) {
    return timeZone.charAt(0) + timeZone.charAt(2);
  } else return timeZone;
};
export const getTimeStamps = (date, timeZone, showSeconds, showTimeZone) => {
  if (!date) {
    return null;
  }
  var utcCutoff = moment.utc(date);
  let newTimeZone = timeZoneMapping.find((x) => x.code === timeZone);
  if (newTimeZone) {
    var displayCutoff = utcCutoff
      .clone()
      .tz(newTimeZone.zone)
      .format(
        showSeconds
          ? `MM/DD/YYYY hh:mm:ss A${showTimeZone ? ' z' : ''}`
          : `MM/DD/YYYY`,
      );
    return displayCutoff;
  } else
    return showSeconds
      ? utcCutoff.format('MM/DD/YYYY hh:mm:ss A')
      : utcCutoff.format('MM/DD/YYYY');
};

export const convertUTCToLocalTime = (date) => {
  return moment.utc(date).local().format('MM/DD/YYYY hh:mm:ss A');
};
export const convertUTCToLocalByTimeZone = (date) => {
  const timeZone = getCurrentTimeZone();
  return moment.utc(date).tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
};

export const getDateWithTimeZone = (date) => {
  const timeZone = getCurrentTimeZone();
  let newDate = new Date(date).toLocaleString('en-US', {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
    timeZoneName: 'short',
  });
  const formattedDate = newDate.split('+')[0];
  return formattedDate;
};

export const getDateByNumberOfDays = (days = 1) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - days);
  return yesterday;
};

export const getDateByMonthRange = () => {
  const todayDate = new Date();
  const previousMonthDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth() - 1,
    1,
  );
  const currentMonthDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    1,
  );
  return { previousMonthDate, currentMonthDate };
};

export const getCapitalize = (string) => {
  if (typeof string !== 'string') {
    return '';
  } else {
    return string
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }
};
export const getCapitalizeAndSeparate = (string) => {
  if (typeof string !== 'string') {
    return '';
  } else {
    return string
      .split(',')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(', ');
  }
};

export const getAddress = (addressArray) => {
  let street_number = '';
  let route = '';
  let address1 = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (
      'route' === addressArray[i].types[0] ||
      'street_number' === addressArray[i].types[0]
    ) {
      street_number = addressArray[i].long_name;
      route = addressArray[i + 1].long_name;
      return street_number + ' ' + route;
    } else if (
      'sublocality_level_1' === addressArray[i].types[0] ||
      'sublocality_level_2' === addressArray[i].types[0] ||
      'locality' === addressArray[i].types[0] ||
      'premise' === addressArray[i].types[0] ||
      'neighborhood' === addressArray[i].types[0] ||
      'political' === addressArray[i].types[0]
    ) {
      address1 = addressArray[i].long_name;
      return address1;
    }
  }
};

export const getArea = (addressArray) => {
  let area = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (
          'sublocality_level_1' === addressArray[i].types[j] ||
          'locality' === addressArray[i].types[j] ||
          'neighborhood' === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  }
};

export const getZipCode = (addressArray) => {
  let zipCode = '';
  for (let i = 0; i < addressArray.length; i++) {
    if ('postal_code' === addressArray[i].types[0]) {
      zipCode = addressArray[i].long_name;
      return zipCode;
    }
  }
};

export const getCity = (addressArray) => {
  let city = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (
      (addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]) ||
      'administrative_area_level_3' === addressArray[i].types[0] ||
      'locality' === addressArray[i].types[0]
    ) {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

export const getState = (addressArray) => {
  let stateLongName = '';
  let stateShortName = '';

  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_1' === addressArray[i].types[0]
      ) {
        stateLongName = addressArray[i].long_name;
        stateShortName = addressArray[i].short_name;
        return {
          stateShortName,
          stateLongName,
        };
      }
    }
  }
};

export const getCountry = (addressArray) => {
  let countryShortName = '';
  let countryLongName = '';
  for (let i = 0; i < addressArray.length; i++) {
    if ('country' === addressArray[i].types[0]) {
      countryShortName = addressArray[i].short_name;
      countryLongName = addressArray[i].long_name;
      return {
        countryShortName,
        countryLongName,
      };
    }
  }
};

export const isSortableColumn = (photos) => {
  const isAtleastOneImageAnnotated = photos.some((photo) => photo.annotationId);
  const isAtleastOneImageNonAnnotated = photos.some(
    (photo) => !photo.annotationId,
  );
  if (isAtleastOneImageNonAnnotated && isAtleastOneImageAnnotated) return true;
  else return false;
};

export const navigateToLabel = (rowInfo, imagesFilters, history) => {
  const urlSearchParams = new URLSearchParams();
  const keys = ['page', 'count', 'startDate', 'endDate'];
  keys.forEach((key) => {
    if (imagesFilters[key]) {
      urlSearchParams.set(key, imagesFilters[key]);
    }
  });
  urlSearchParams.set('index', rowInfo?.index);

  history.push(
    `/project/${rowInfo?.projectId}/name/${rowInfo?.projectName}/location/${
      rowInfo?.propertyName
    }/${rowInfo?.propertyId}?${urlSearchParams.toString()}`,
  );
};

export const getPhoneNumberDetail = (number, type) => {
  let numList, code, phoneNumber;
  if (number && number.includes('-')) {
    numList = number.split('-');
    code = numList[0];
    phoneNumber = numList[1];
  } else {
    code = '+1';
    phoneNumber = number;
  }
  if (type === 'code') {
    return code;
  } else {
    return phoneNumber;
  }
};

export const getPhoneNumberAndCountryCodeDetail = (number, code) => {
  if (code) {
    return `${code} ${number}`;
  }
  return number;
};

export const isEmptyOrNotNumber = (number) => {
  const numbersRegEx = /^[0-9]+$/;
  return isEmpty(number) || !number?.match(numbersRegEx);
};

export const getUrlParams = (url) => {
  let paramString = url.split('?')[1] || '';
  let params_arr = paramString.split('&') || [];
  let params = {};
  for (let i = 0; i < params_arr.length; i++) {
    let pair = params_arr[i].split('=');
    params[pair[0]] = pair[1];
  }
  return params;
};

export const isTrackerArmed = (status) => {
  return ['3', '4', '5'].includes(status?.toString());
};
export const isTrackerArmedZflo = (status) => {
  if (status === 'Armed') {
    return true;
  } else {
    return false;
  }
};

export const isTrackerStatusIsFullPanic = (status) =>
  status === '5' || status === 5;

export const isValidDefaultBatteryLevel = (defaultBatteryLevel) =>
  ['0', '1', '2'].includes(defaultBatteryLevel?.toString());

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
export const getDate = (timeStamp) => {
  let splitDate = timeStamp?.split('T')[0];
  splitDate = splitDate.split('-');
  const formatedDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  return formatedDate;
};
export const addDaysToDate = (origDate, days) => {
  const theDate = new Date(origDate);
  return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
};

export const currentDateInRange = (date, fromDate, toDate) => {
  let currentDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0);
  if (
    Number(currentDate) >= Number(fromDate) &&
    Number(currentDate) <= Number(toDate)
  ) {
    return true;
  } else return false;
};

export function dateFormat(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}
export function getTrimmedString(str, maxWords) {
  const trimmedStringArray = str.split(' ');
  if (trimmedStringArray.length > maxWords) {
    return `${trimmedStringArray.splice(0, maxWords).join(' ')} ...`;
  }
  return str;
}

export const customEncodedComponent = (item) => {
  return encodeURIComponent(item.replace(/\\/g, '\\\\'));
};

export const getPropertyMenuDetail = ({
  isBA,
  isDart,
  isTaco,
  userGroupId,
  isCC,
}) => {
  let menuDetail = {
    displayName: 'Properties',
    icon: 'propertiesfullIcon',
  };

  if (isDart || isTaco) {
    menuDetail.displayName = 'Locations';
  }
  if (isBA) {
    if (userGroupId === getClientConfig().USER_GROUPS.BA_OWNER) {
      menuDetail.displayName = 'Bars';
    } else if (userGroupId === getClientConfig().USER_GROUPS.BA_DISTRIBUTER) {
      menuDetail.displayName = 'Brewery';
    } else {
      menuDetail.displayName = 'Bars & Breweries';
    }

    menuDetail.icon = 'barBreweriesIcon';
  }
  if (isCC) {
    menuDetail.displayName = 'Sites';
  }
  return menuDetail;
};

export const addHoursToDate = (date, hours) => {
  return new Date(new Date(date).setHours(date.getHours() + hours));
};

export const addDays = (currentDate, days) => {
  return currentDate.setDate(currentDate.getDate() + days);
};

export const isEqual = (string1, string2) => {
  return string1.toUpperCase() === string2.toUpperCase();
};
export const downloadBlob = (imageUrl, filename, message) => {
  const url = imageUrl;
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  pushNotification(
    `${message} downloaded successfully`,
    NOTIFICATION.SUCCESS,
    'TOP_RIGHT',
    3000,
  );
  return a;
};

export const getFullName = (firstName, lastName) => {
  let fullName = '';
  if (firstName) {
    fullName = firstName;
  }
  if (lastName) {
    if (firstName) fullName = fullName + ' ' + lastName;
    else fullName = fullName + lastName;
  }
  return fullName;
};
export const tryParseInt = (str, defaultValue) => {
  var retValue = defaultValue;
  if (str !== null && !isNaN(str)) {
    retValue = parseInt(str);
  }
  return retValue;
};

export const getStringCapitalize = (str) => {
  var retValue = '';
  if (str) {
    retValue = str.toUpperCase();
  }
  return retValue;
};
export const getCurrentTimeZone = () =>
  Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Asia/Colombo';

export const convertCelsiusToFahrenheit = (value) => {
  if (value) {
    let fahrenheit = (value * 9) / 5 + 32;
    return Math.floor(fahrenheit * 100) / 100;
  }
  return '';
};
//It compares  arrays without considering order of items
export const compareArray = (arr1, arr2) => {
  for (const v of new Set([...arr1, ...arr2]))
    if (
      arr1.filter((e) => e === v).length !== arr2.filter((e) => e === v).length
    )
      return false;
  return true;
};
//Prevent signs with Number and leading 0
export const preventSignNumber = (e, preLeadingZero) => {
  const invalidChars = ['-', '+', 'e'];
  if (preLeadingZero && e?.target?.value?.length === 0 && '0' === e.key) {
    e.preventDefault();
  } else if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};
export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() < selectedDate.getTime();
};
export const getConfidenceScore = (confidence) => {
  if (2008 <= confidence && confidence <= 2088) {
    confidence = 0;
  } else if (
    (1928 <= confidence && confidence < 2008) ||
    (2088 < confidence && confidence <= 2168)
  ) {
    confidence = 1;
  } else if (
    (1548 <= confidence && confidence < 1928) ||
    (2168 < confidence && confidence <= 2548)
  ) {
    confidence = 2;
  } else if (
    (0 <= confidence && confidence < 1548) ||
    (2548 < confidence && confidence <= 4096)
  ) {
    confidence = 3;
  } else {
    confidence = 0;
  }
  return confidence;
};
export const bolToString = (value) => {
  return (value = typeof value === 'boolean' ? value.toString() : value);
};
export const roundNumber2Deci = (v) => {
  let num = Number(v);
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
export const timestampFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date).format(format);
};
export const addMinutes = (minutes, date) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + minutes);
  return newDate;
};
export const minusHoursToDate = (date = new Date(), hours) => {
  const newDate = new Date(date);
  return newDate.setHours(newDate.getHours() - hours);
};
export const getDateDifference = (toDate, fromDate) => {
  const date2 = new Date(toDate);
  const date1 = new Date(fromDate);
  let secondsDif = (date2.getTime() - date1.getTime()) / 1000;
  const minuteDif = Math.floor(secondsDif / 60);
  return minuteDif;
};
export const getLocalDateTime = (value, timeProps) => {
  return (
    new Date(value).toLocaleDateString() +
    ' ' +
    new Date(value).toLocaleTimeString([], {
      ...(timeProps
        ? {
            ...timeProps,
          }
        : { timeStyle: 'short' }),
    })
  );
};
export const getLeakMessage = (x) => {
  var statusMessage = 'No Leak';
  if (x.leakSensor || x.ropeLeakSensor) {
    let y = x.leakSensor || x.ropeLeakSensor;
    if (y.value == 1 && y.voltage > 0) {
      statusMessage = 'Leak Detected';
    } else if (y.value == 1 && y.voltage == 0) {
      statusMessage = 'Shorted on Metal';
    } else if (y.value == 2) {
      statusMessage = 'Sensor Disconnected';
    }
  }
  return statusMessage;
};

export const getUserGroupId = (id) => {
  let userGroupId = [];
  switch (id) {
    case getClientUserGroupConfig().ZFLO_ADMIN:
      userGroupId.push(
        getClientUserGroupConfig().ZFLO_ADMIN,
        getClientUserGroupConfig().ZFLO_SUPPORT,
        getClientUserGroupConfig().ZFLO_LOGISTICS,
      );
      break;
    case getClientUserGroupConfig().ZFLO_COMPANY_ADMIN:
      userGroupId.push(
        getClientUserGroupConfig().ZFLO_COMPANY_SUPPORT,
        getClientUserGroupConfig().ZFLO_COMPANY_OWNER,
        getClientUserGroupConfig().ZFLO_COMPANY_ADMIN,
        getClientUserGroupConfig().ZFLO_COMPANY_LOGISTICS,
      );
      break;
    case getClientUserGroupConfig().ZFLO_END_CUSTOMER:
      userGroupId.push(id);
      break;
    default:
      userGroupId.push(id);
  }
  return userGroupId.toString();
};
export const getUserTimeStamp = (date, format) => {
  if (date)
    return moment(date)
      .local()
      .format(format ? format : 'MM/DD/YYYY hh:mma');
  return 'NA';
};
export const getUserTimeStampUtc = (date, format) => {
  if (date)
    return moment(date)
      .utc()
      .format(format ? format : 'MM/DD/YYYY hh:mma');
  return 'NA';
};

export const converHoursToSeconds = (hour) => {
  if (hour) return hour * 3600;
  return '';
};
export const converMinutesToSeconds = (minute) => {
  if (minute) return minute * 60;
  return '';
};
export const removeDashesFromString = (str) => {
  if (str) return str.replaceAll('-', '');
  return '';
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  let byteCharacters = atob(b64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const getUrl = () => {
  let url = window.location.href;
  let newUrl = url.substring(url.indexOf('/') + 2, url.lastIndexOf('/#/'));
  return newUrl.includes('localhost') ? 'carbon-dev.oxtech.com' : newUrl;
};

export const getJsonDiff = (obj1, obj2) => {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = getJsonDiff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

export const removeEmptyObjects = (object) => {
  for (var key in object) {
    if (!object[key] || typeof object[key] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }
    // The property is an object
    removeEmptyObjects(object[key]); // <-- Make a recursive call on the nested object
    if (Object.keys(object[key]).length === 0) {
      delete object[key]; // The object had no properties, so delete that property
    }
  }
  return object;
};
