export const notificationSettingsIcon = [
  '24 24',
  `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
>
  <path
    fill="#3CA5ED"
    d="M23.765 18.396l-.883-.79a.878.878 0 010-1.309l.883-.79a.703.703 0 00.14-.876l-1.389-2.408a.703.703 0 00-.829-.317l-1.125.37a.879.879 0 01-1.133-.657l-.243-1.16a.703.703 0 00-.689-.56h-2.78c-.095.001-.189.02-.277.059A7.047 7.047 0 009.628 3.92a2.135 2.135 0 10-2.326 0 7.031 7.031 0 00-5.867 6.938l-.005 4.487L.149 17.23a.703.703 0 00-.122.395v2.843a.703.703 0 00.703.704h4.922v.013a2.815 2.815 0 005.63 0v-.013h.125l.293.508a.702.702 0 00.828.316l1.125-.37a.877.877 0 011.133.656l.243 1.16a.703.703 0 00.687.558h2.78a.703.703 0 00.688-.559l.243-1.16a.877.877 0 011.134-.657l1.125.37a.704.704 0 00.828-.316l1.392-2.406a.703.703 0 00-.14-.875zm-15.3-16.99a.729.729 0 110 1.458.729.729 0 010-1.458zM1.434 17.841l1.28-1.883a.703.703 0 00.121-.396l.005-4.704a5.625 5.625 0 0111.25 0v1.447a.867.867 0 01-.438-.029l-1.125-.37a.702.702 0 00-.829.317l-1.39 2.408a.703.703 0 00.141.875l.884.79a.879.879 0 010 1.31l-.884.79a.703.703 0 00-.14.876l.284.493h-9.16v-1.924zm8.437 3.344a1.408 1.408 0 11-2.817 0v-.013H9.87c.003.004.003.009.003.015l-.002-.002zm12.07-2.531l.459.41-.817 1.42L21 20.29a2.284 2.284 0 00-2.948 1.702l-.127.602h-1.638l-.125-.602a2.284 2.284 0 00-2.949-1.702l-.584.192-.817-1.418.458-.41a2.284 2.284 0 000-3.405l-.458-.41.819-1.418.583.192a2.29 2.29 0 001.971-.265l.005-.003c.5-.332.85-.847.973-1.434l.126-.602h1.638l.126.602A2.285 2.285 0 0021 13.613l.584-.192.819 1.419-.458.41a2.284 2.284 0 000 3.403h-.003z"
  ></path>
</svg>`,
];
