import { USER_TYPE_OXIT } from '../../../constants';
import {
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGIN_PENDING,
  LOGOUT_REJECTED,
  LOGOUT_PENDING,
  LOGOUT_FULFILLED,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_FULFILLED,
  CHANGE_PASSWORD_REJECTED,
  UPDATE_USER,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_USER_PROFILE_PENDING,
  GET_USER_PROFILE_FULFILLED,
  GET_LOGGED_IN_CLIENT_FULFILLED,
  GET_USER_PROFILE_REJECTED,
  GET_LOGGED_IN_CLIENT_REJECTED,
  GET_LOGGED_IN_CLIENT_PENDING,
  STOP_LOADER,
  SET_USER_TYPE_OIP,
  SET_USER_TYPE_DART,
  SET_CLIENT_PREVIEW_THEME,
  SET_THEME_CONFIGURATION,
} from './LoginActionTypes';

const initialState = {
  userData: {},
  userProfileData: {},
  clientData: {},
  errorMessage: '',
  loginSuccess: false,
  loading: false,
  changePasswordSuccess: false,
  loginData: [],
  isDartDisplayUser: false,
  themeConfiguration: [],
  clientPreviewTheme: {},
};

const loginPending = (state) => {
  return {
    ...state,
    isLoading: true,
    loginData: [],
    error: false,
    loginSuccess: false,
    errorMessage: '',
  };
};

const loginFullfilled = (state, action) => {
  if (action.error) {
    return {
      ...state,
      isLoading: false,
      loginData: [],
      loginSuccess: false,
      error: true,
      errorMessage: {},
    };
  }
  return {
    ...state,
    isLoading: false,
    loginSuccess: true,
    loginData: action.payload.data,
    error: false,
    errorMessage: '',
  };
};

const loginRejected = (state) => {
  return {
    ...state,
    isLoading: false,
    loginSuccess: false,
    loginData: [],
    error: true,
    errorMessage: {},
  };
};

const logoutPending = (state) => {
  return {
    ...state,
    isLoading: true,
    logoutData: [],
    error: false,
    logoutSuccess: false,
    errorMessage: '',
  };
};

const logoutFullfilled = (state, action) => {
  return {
    ...state,
    isLoading: false,
    logoutSuccess: true,
    logoutData: action.payload.data,
    error: false,
    errorMessage: '',
  };
};

const logoutRejected = (state) => {
  return {
    ...state,
    isLoading: false,
    logoutSuccess: false,
    logoutData: [],
    error: true,
    errorMessage: {},
  };
};

const changePasswordPending = (state) => {
  return {
    ...state,
    isLoading: true,
    error: false,
    changePasswordSuccess: false,
    errorMessage: '',
  };
};

const changePasswordFullfilled = (state) => {
  return {
    ...state,
    isLoading: false,
    changePasswordSuccess: true,
    error: false,
    errorMessage: '',
  };
};

const changePasswordRejeted = (state) => {
  return {
    ...state,
    isLoading: false,
    changePasswordSuccess: false,
    error: true,
    errorMessage: {},
  };
};

const updateUserInfo = (state, action) => {
  return {
    ...state,
    userData: action.payload,
  };
};

const loginSuccessInfo = (state, action) => {
  let data = { ...action.payload };
  delete data.signInUserSession;
  delete data.pool;
  delete data.client;
  delete data.storage;
  return {
    ...state,
    userData: data,
    loading: false,
  };
};

const getUserProfileSuccess = (state, action) => {
  let data = action.payload?.data;
  let defaultTheme = data?.data?.Client?.clientSettings?.themeSettings;
  if (data?.data?.type === USER_TYPE_OXIT && data?.data?.Client) {
    data.data.Client = {
      companyName: USER_TYPE_OXIT,
      projectName: USER_TYPE_OXIT,
    };
  }
  return {
    ...state,
    userProfileData: data,
    loading: false,
    themeConfiguration: defaultTheme,
  };
};

const getClientSuccess = (state, action) => {
  return {
    ...state,
    clientData: action.payload.data,
    loading: false,
  };
};

const setLoadingStart = (state) => ({
  ...state,
  loading: true,
});

const setLoadingCompleted = (state) => ({
  ...state,
  loading: false,
});
const setUserDartDisplay = (state) => ({
  ...state,
  isDartDisplayUser: true,
});
const setUserOIP = (state) => ({
  ...state,
  isOIPUser: true,
});

const setClientPreviewTheme = (state, action) => ({
  ...state,
  clientPreviewTheme: action.payload,
});
const setThemeConfiguration = (state, action) => ({
  ...state,
  themeConfiguration: action.payload,
});
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_PENDING:
      return loginPending(state, action);

    case LOGIN_FULFILLED:
      return loginFullfilled(state, action);

    case LOGIN_REJECTED:
      return loginRejected(state, action);

    case LOGOUT_PENDING:
      return logoutPending(state, action);

    case LOGOUT_FULFILLED:
      return logoutFullfilled(state, action);

    case LOGOUT_REJECTED:
      return logoutRejected(state, action);

    case CHANGE_PASSWORD_PENDING:
      return changePasswordPending(state, action);

    case CHANGE_PASSWORD_FULFILLED:
      return changePasswordFullfilled(state, action);

    case CHANGE_PASSWORD_REJECTED:
      return changePasswordRejeted(state, action);

    case UPDATE_USER:
      return updateUserInfo(state, action);

    case LOGIN_START:
      return setLoadingStart(state, action);

    case LOGIN_SUCCESS:
      return loginSuccessInfo(state, action);

    case LOGIN_FAIL:
      return setLoadingCompleted(state, action);

    case GET_USER_PROFILE_PENDING:
      return setLoadingStart(state, action);

    case GET_USER_PROFILE_FULFILLED:
      return getUserProfileSuccess(state, action);

    case GET_USER_PROFILE_REJECTED:
      return setLoadingCompleted(state, action);

    case GET_LOGGED_IN_CLIENT_PENDING:
      return setLoadingStart(state, action);

    case GET_LOGGED_IN_CLIENT_FULFILLED:
      return getClientSuccess(state, action);

    case GET_LOGGED_IN_CLIENT_REJECTED:
      return setLoadingCompleted(state, action);

    case STOP_LOADER:
      return setLoadingCompleted(state, action);
    case SET_USER_TYPE_DART:
      return setUserDartDisplay(state, action);
    case SET_USER_TYPE_OIP:
      return setUserOIP(state, action);
    case SET_CLIENT_PREVIEW_THEME:
      return setClientPreviewTheme(state, action);
    case SET_THEME_CONFIGURATION:
      return setThemeConfiguration(state, action);
    default:
      return {
        ...state,
      };
  }
}
