export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_LOGGED_IN_CLIENT = 'GET_LOGGED_IN_CLIENT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_USER_PROFILE_PENDING = 'GET_USER_PROFILE_PENDING';
export const GET_USER_PROFILE_FULFILLED = 'GET_USER_PROFILE_FULFILLED';
export const GET_USER_PROFILE_REJECTED = 'GET_USER_PROFILE_REJECTED';
export const GET_LOGGED_IN_CLIENT_PENDING = 'GET_LOGGED_IN_CLIENT_PENDING';
export const GET_LOGGED_IN_CLIENT_FULFILLED = 'GET_LOGGED_IN_CLIENT_FULFILLED';
export const GET_LOGGED_IN_CLIENT_REJECTED = 'GET_LOGGED_IN_CLIENT_REJECTED';
export const STOP_LOADER = 'STOP_LOADER';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USER_TYPE_OIP = 'SET_USER_TYPE_OIP';
export const SET_USER_TYPE_DART = 'SET_USER_TYPE_DART';

export const SET_CLIENT_PREVIEW_THEME = 'SET_CLIENT_PREVIEW_THEME';
export const SET_THEME_CONFIGURATION = 'SET_THEME_CONFIGURATION';
