export const GET_USER_GROUPS_PENDING = 'GET_USER_GROUPS_PENDING';
export const GET_USER_GROUPS_FULFILLED = 'GET_USER_GROUPS_FULFILLED';
export const GET_USER_GROUPS_REJECTED = 'GET_USER_GROUPS_REJECTED';
export const GET_USER_GROUPS_DROPDOWN_PENDING =
  'GET_USER_GROUPS_DROPDOWN_PENDING';
export const GET_USER_GROUPS_DROPDOWN_FULFILLED =
  'GET_USER_GROUPS_DROPDOWN_FULFILLED';
export const GET_USER_GROUPS_DROPDOWN_REJECTED =
  'GET_USER_GROUPS_DROPDOWN_REJECTED';
export const ADD_USER_GROUP_PENDING = 'ADD_USER_GROUP_PENDING';
export const ADD_USER_GROUP_FULFILLED = 'ADD_USER_GROUP_FULFILLED';
export const ADD_USER_GROUP_REJECTED = 'ADD_USER_GROUP_REJECTED';
export const UPDATE_USER_GROUP_PENDING = 'UPDATE_USER_GROUP_PENDING';
export const UPDATE_USER_GROUP_FULFILLED = 'UPDATE_USER_GROUP_FULFILLED';
export const UPDATE_USER_GROUP_REJECTED = 'UPDATE_USER_GROUP_REJECTED';
export const GET_USER_GROUP_BY_ID_PENDING = 'GET_USER_GROUP_BY_ID_PENDING';
export const GET_USER_GROUP_BY_ID_FULFILLED = 'GET_USER_GROUP_BY_ID_FULFILLED';
export const GET_USER_GROUP_BY_ID_REJECTED = 'GET_USER_GROUP_BY_ID_REJECTED';
export const DELETE_USER_GROUP_PENDING = 'DELETE_USER_GROUP_PENDING';
export const DELETE_USER_GROUP_FULFILLED = 'DELETE_USER_GROUP_FULFILLED';
export const DELETE_USER_GROUP_REJECTED = 'DELETE_USER_GROUP_REJECTED';
export const GET_MODULES_LIST_PENDING = 'GET_MODULES_LIST_PENDING';
export const GET_MODULES_LIST_FULFILLED = 'GET_MODULES_LIST_FULFILLED';
export const GET_MODULES_LIST_REJECTED = 'GET_MODULES_LIST_REJECTED';

export const GET_USER_GROUPS = 'GET_USER_GROUPS';
export const GET_USER_GROUPS_DROPDOWN = 'GET_USER_GROUPS_DROPDOWN';
export const ADD_USER_GROUP = 'ADD_USER_GROUP';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const GET_USER_GROUP_BY_ID = 'GET_USER_GROUP_BY_ID';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const GET_MODULES_LIST = 'GET_MODULES_LIST';
