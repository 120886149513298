import {
  SAVE_CONFIGURATION_FORM,
  SAVE_CONFIGURATION_FORM_ERROR,
} from './AlertConfigurationActionTypes';
const initialState = {
  alertConfigurationData: {},
  validationErrorData: {},
};

const saveConfigurationForm = (state, action) => {
  return {
    ...state,
    alertConfigurationData: {
      ...state.alertConfigurationData,
      ...action.payload,
    },
  };
};
const saveConfigurationFormError = (state, action) => {
  return {
    ...state,
    validationErrorData: {
      ...state.validationErrorData,
      ...action.payload,
    },
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CONFIGURATION_FORM:
      return saveConfigurationForm(state, action);
    case SAVE_CONFIGURATION_FORM_ERROR:
      return saveConfigurationFormError(state, action);
    default:
      return {
        ...state,
      };
  }
}
