export const userPlaceholder = [
  '60 60',
  `
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="8" fill="#CF99EE" fill-opacity="0.15"/>
<g clip-path="url(#clip0)">
<path d="M40.0963 33.0308C39.3008 32.3412 38.3362 31.7454 37.229 31.26C36.7557 31.0526 36.2018 31.2629 35.9916 31.7297C35.7814 32.1965 35.9945 32.743 36.4677 32.9506C37.4013 33.3599 38.2059 33.8544 38.8586 34.4203C39.6634 35.118 40.125 36.1282 40.125 37.1924V38.9036C40.125 39.4136 39.7044 39.8285 39.1875 39.8285H20.8125C20.2956 39.8285 19.875 39.4136 19.875 38.9036V37.1924C19.875 36.1282 20.3366 35.118 21.1414 34.4203C22.0886 33.5991 24.8483 31.6891 30 31.6891C33.8253 31.6891 36.9375 28.6185 36.9375 24.8445C36.9375 21.0705 33.8253 18 30 18C26.1747 18 23.0625 21.0705 23.0625 24.8445C23.0625 27.0508 24.1263 29.0163 25.7743 30.2688C22.7589 30.9227 20.8832 32.1815 19.9037 33.0308C18.694 34.0795 18 35.5962 18 37.1924V38.9036C18 40.4337 19.2616 41.6784 20.8125 41.6784H39.1875C40.7384 41.6784 42 40.4337 42 38.9036V37.1924C42 35.5962 41.306 34.0795 40.0963 33.0308ZM24.9375 24.8445C24.9375 22.0905 27.2086 19.8499 30 19.8499C32.7914 19.8499 35.0625 22.0905 35.0625 24.8445C35.0625 27.5986 32.7914 29.8392 30 29.8392C27.2086 29.8392 24.9375 27.5986 24.9375 24.8445Z" fill="#CF99EE"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="23.6784" fill="white" transform="translate(18 18)"/>
</clipPath>
</defs>
</svg>

  
`,
];
