export const GET_DEVICE_PENDING = 'GET_DEVICE_PENDING';
export const GET_DEVICE_FULFILLED = 'GET_DEVICE_FULFILLED';
export const GET_DEVICE_REJECTED = 'GET_DEVICE_REJECTED';
export const GET_END_DEVICE_TYPE_PENDING = 'GET_END_DEVICE_TYPE_PENDING';
export const GET_END_DEVICE_TYPE_FULFILLED = 'GET_END_DEVICE_TYPE_FULFILLED';
export const GET_END_DEVICE_TYPE_REJECTED = 'GET_END_DEVICE_TYPE_REJECTED';
export const GET_PROGRAMMER_END_DEVICE_TYPE_PENDING =
  'GET_PROGRAMMER_END_DEVICE_TYPE_PENDING';
export const GET_PROGRAMMER_END_DEVICE_TYPE_FULFILLED =
  'GET_PROGRAMMER_END_DEVICE_TYPE_FULFILLED';
export const GET_PROGRAMMER_END_DEVICE_TYPE_REJECTED =
  'GET_PROGRAMMER_END_DEVICE_TYPE_REJECTED';
export const GET_DEVICE_BY_ID_PENDING = 'GET_DEVICE_BY_ID_PENDING';
export const GET_DEVICE_BY_ID_FULFILLED = 'GET_DEVICE_BY_ID_FULFILLED';
export const GET_DEVICE_BY_ID_REJECTED = 'GET_DEVICE_BY_ID_REJECTED';
export const DELETE_DEVICE_PENDING = 'DELETE_DEVICE_PENDING';
export const DELETE_DEVICE_FULFILLED = 'DELETE_DEVICE_FULFILLED';
export const DELETE_DEVICE_REJECTED = 'DELETE_DEVICE_REJECTED';
export const UPDATED_DEVICE_PENDING = 'UPDATED_DEVICE_PENDING';
export const UPDATED_DEVICE_FULFILLED = 'UPDATED_DEVICE_FULFILLED';
export const UPDATED_DEVICE_REJECTED = 'UPDATED_DEVICE_REJECTED';
export const ADD_END_DEVICE_PENDING = 'ADD_END_DEVICE_PENDING';
export const ADD_END_DEVICE_FULFILLED = 'ADD_END_DEVICE_FULFILLED';
export const ADD_END_DEVICE_REJECTED = 'ADD_END_DEVICE_REJECTED';
export const GET_END_DEVICE_TYPE = 'GET_END_DEVICE_TYPE';
export const GET_PROGRAMMER_END_DEVICE_TYPE = 'GET_PROGRAMMER_END_DEVICE_TYPE';
export const GET_DEVICE_BY_ID = 'GET_DEVICE_BY_ID';
export const GET_ASSOCIATED_PROPERTIES_BY_DEVICE_ID =
  'GET_ASSOCIATED_PROPERTIES_BY_DEVICE_ID';
export const UPDATED_DEVICE = 'UPDATED_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const ADD_END_DEVICE = 'ADD_END_DEVICE';
export const UPDATE_DEVICE_SCHEDULE = 'UPDATE_DEVICE_SCHEDULE';
export const UPDATE_DEVICE_SCHEDULE_PENDING = 'UPDATE_DEVICE_SCHEDULE_PENDING';
export const UPDATE_DEVICE_SCHEDULE_FULFILLED =
  'UPDATE_DEVICE_SCHEDULE_FULFILLED';
export const UPDATE_DEVICE_SCHEDULE_REJECTED =
  'UPDATE_DEVICE_SCHEDULE_REJECTED';
export const UPLOAD_PROPERTIES_HISTORY = 'UPLOAD_PROPERTIES_HISTORY';
export const GET_DEVICE_TRACKER_BY_ID = 'GET_DEVICE_TRACKER_BY_ID';
export const CHANGE_TRACKER_STATUS = 'CHANGE_TRACKER_STATUS';
export const CHANGE_VALVE_STATUS = 'CHANGE_VALVE_STATUS';
export const GET_TREE_END_DEVICE_TYPE = 'GET_TREE_END_DEVICE_TYPE';
export const GET_TREE_END_DEVICE_TYPE_PENDING =
  'GET_TREE_END_DEVICE_TYPE_PENDING';
export const GET_TREE_END_DEVICE_TYPE_FULFILLED =
  'GET_TREE_END_DEVICE_TYPE_FULFILLED';
export const GET_TREE_END_DEVICE_TYPE_REJECTED =
  'GET_TREE_END_DEVICE_TYPE_REJECTED';
export const GET_END_DEVICES = 'GET_END_DEVICES';
export const GET_END_DEVICES_PENDING = 'GET_END_DEVICES_PENDING';
export const GET_END_DEVICES_FULFILLED = 'GET_END_DEVICES_FULFILLED';
export const GET_END_DEVICES_REJECTED = 'GET_END_DEVICES_REJECTED ';
export const GET_ALERT_THRESHOLDS_DATA = 'GET_ALERT_THRESHOLDS_DATA';
export const GET_ALERT_THRESHOLDS_DATA_FULFILLED =
  'GET_ALERT_THRESHOLDS_DATA_FULFILLED';
export const FORCE_RESET = 'FORCE_RESET';
export const GET_DEVICE_TYPE = 'GET_DEVICE_TYPE';
export const GET_DEVICE_TYPE_PENDING = 'GET_DEVICE_TYPE_PENDING';
export const GET_DEVICE_TYPE_FULFILLED = 'GET_DEVICE_TYPE_FULFILLED';
export const GET_DEVICE_TYPE_REJECTED = 'GET_DEVICE_TYPE_REJECTED';
export const ADD_DEVICE_TYPE = 'ADD_DEVICE_TYPE';
export const ADD_DEVICE_TYPE_PENDING = 'ADD_DEVICE_TYPE_PENDING';
export const ADD_DEVICE_TYPE_FULFILLED = 'ADD_DEVICE_TYPE_FULFILLED';
export const ADD_DEVICE_TYPE_REJECTED = 'ADD_DEVICE_TYPE_REJECTED';
export const UPDATE_DEVICE_TYPE = 'UPDATE_DEVICE_TYPE';
export const UPDATE_DEVICE_TYPE_PENDING = 'UPDATE_DEVICE_TYPE_PENDING';
export const UPDATE_DEVICE_TYPE_FULFILLED = 'UPDATE_DEVICE_TYPE_FULFILLED';
export const UPDATE_DEVICE_TYPE_REJECTED = 'UPDATE_DEVICE_TYPE_REJECTED';
export const ACTIVATE_RMD_DEVICE = 'ACTIVATE_RMD_DEVICE';
export const DEACTIVATE_RMD_DEVICE = 'DEACTIVATE_RMD_DEVICE';
export const RETRY_ACTIVATE_RMD_DEVICE = 'RETRY_ACTIVATE_RMD_DEVICE';
