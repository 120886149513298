import dartFavIcon from '../../assets/icons/DARTfavicon.ico';
import waterCopFavIcon from '../../assets/icons/WaterCopfavicon.png';
import zTrackerFavIcon from '../../assets/icons/Zflo-Logo.png';
import oipLogoImg from '../../assets/icons/Optical_Inventory_Logo.png';
import waterCopLogo from '../../assets/images/watercopLogo.png';
import dartLogo from '../../assets/images/dartLogo.png';
import omniIcon from '../../assets/images/omni-id-icon.png';
import omniLogo from '../../assets/images/omni-id-logo.jpeg';
import zTrackerLogo from '../../assets/icons/Zflo-Logo.png';
import aataLogo from '../../assets/images/aataLogo.png';
import oxtechFavIcon from '../../assets/icons/oxtechicon.png';
import oxitLogo from '../../assets/images/oxit_white_logo.png';
import baLogo from '../../assets/images/BA.png';
import baFavIcon from '../../assets/icons/BAfavicon.png';
import crownCastleIcon from '../../assets/icons/crownCastleIcon.svg';
import crownCastleLogo from '../../assets/icons/crownCastleLogo.svg';
import ndsLogo from '../../assets/images/nds-logo.png';
import ndsFavIcon from '../../assets/images/NDS-Favicon.png';
import s8cgpLogo from '../../assets/images/s8cgp-logo.png';
import s8cgpFavIcon from '../../assets/images/s8cgp-favIcon.png';
import ohaLogo from '../../assets/images/oha-logo.png';
import ohaFavIcon from '../../assets/images/OHA-Favicon.png';
import ncLogo from '../../assets/images/NC-logo.png';
import ncFavIcon from '../../assets/images/NC-Favicon.png';
import {
  DART_DISPLAY_URL,
  WATER_COP_URL,
  Z_TRACKER_URL,
  OIP_URL,
  AATA_URL,
  BA_URL,
  OMNI_ID_URL,
  NDS_URL,
  S8CGP_URL,
  OHA_URL,
  CC_URL,
  NC_URL,
} from '../../utils/envVariables';
import { AATA_USER, BA_USER, WATERCOP_USER } from '..';
import { CURRENT_YEAR } from '../footerConfig/footerConfig';

export const clients = {
  [DART_DISPLAY_URL]: 'isDartDisplay',
  [WATER_COP_URL]: 'isWatercop',
  [Z_TRACKER_URL]: 'isZTracker',
  [OIP_URL]: 'isOIP',
  [AATA_URL]: 'isAATA',
  [BA_URL]: 'isBA',
  [OMNI_ID_URL]: 'isOmni',
  [NDS_URL]: 'isNDS',
  [S8CGP_URL]: 'isS8CGP',
  [OHA_URL]: 'isOHA',
  [NC_URL]: 'isNC',
  [CC_URL]: 'isCrownCastle',

  metaData: {
    isDartDisplay: {
      title: 'Portal | DART Displays',
      icon: dartFavIcon,
      logo: dartLogo,
      copyRight: `${CURRENT_YEAR} DART Innovation`,
      type: 'DART Displays',
    },
    isWatercop: {
      title: 'Portal | Watercop',
      icon: waterCopFavIcon,
      logo: waterCopLogo,
      copyRight: `${CURRENT_YEAR} DynaQuip`,
      type: 'Watercop',
    },
    isZTracker: {
      title: 'Portal | ZakTrak',
      icon: zTrackerFavIcon,
      logo: zTrackerLogo,
      copyRight: `${CURRENT_YEAR} ZFLO`,
      type: 'ZakTrak',
    },
    isOIP: {
      title: 'Portal | Optical Inventory',
      icon: oipLogoImg,
      logo: oipLogoImg,
      copyRight: `${CURRENT_YEAR} Optical Inventory Solutions`,
      type: 'Optical Inventory Solutions',
    },
    isAATA: {
      title: 'Portal | Arbor Alert Tree Alarm',
      icon: aataLogo,
      logo: aataLogo,
      copyRight: `${CURRENT_YEAR} Arbor Alert Tree Alarm`,
      type: 'AATA',
    },
    isBA: {
      title: 'Portal | Beverage Analytics',
      icon: baFavIcon,
      logo: baLogo,
      copyRight: `${CURRENT_YEAR} Beverage Analytics`,
      type: 'Keg Monitoring Platform',
    },
    isOxit: {
      title: 'OxTech IoT Platform',
      icon: oxtechFavIcon,
      logo: oxitLogo,
      copyRight: `${CURRENT_YEAR}  OxTech`,
      type: 'oxit',
    },
    isCrownCastle: {
      title: 'Portal | Crown Castle',
      icon: crownCastleIcon,
      logo: crownCastleLogo,
      copyRight: `${CURRENT_YEAR} Crown Castle`,
      type: 'CCLTM',
    },
    isOmni: {
      title: 'Portal | Omni Id',
      icon: omniIcon,
      logo: omniLogo,
      copyRight: `${CURRENT_YEAR} Omni Id`,
      type: 'OISAP',
    },
    isNDS: {
      title: 'Portal | Next Degree Safety',
      icon: ndsFavIcon,
      logo: ndsLogo,
      copyRight: `${CURRENT_YEAR} Next Degree Safety`,
      type: 'NDS',
    },
    isS8CGP: {
      title: 'Portal|OxTech 8-Channel',
      icon: s8cgpFavIcon,
      logo: s8cgpLogo,
      type: '8 Channel Gateway',
      copyRight: `${CURRENT_YEAR} OxTech`,
    },
    isOHA: {
      title: 'Portal| OxTech Helium App',
      icon: ohaFavIcon,
      logo: ohaLogo,
      type: 'OxTech Helium',
      copyRight: `${CURRENT_YEAR} OxTech Helium App`,
    },
    isNC: {
      title: 'DeNova Detect Dashboard',
      icon: ncFavIcon,
      logo: ncLogo,
      type: 'New Cosmos',
      copyRight: `${CURRENT_YEAR} New Cosmos USA, Inc.`,
    },
  },
};

export const userGroupFields = {
  [BA_USER]: {
    userTypeAndRole: true,
  },
  [WATERCOP_USER]: {
    userTypeAndRole: true,
    allowUserGroup: true,
  },
  [AATA_USER]: {
    userTypeAndRole: true,
    allowUserGroup: true,
  },
};
