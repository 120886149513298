export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://api-dev.oxtech.com';
export const AUTHORIZATION_KEY = process.env.REACT_APP_AUTHORIZATION_KEY;
export const POOL_ID = process.env.REACT_APP_POOL_ID;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
export const USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
export const DART_DISPLAY_URL = process.env.REACT_APP_DART_DISPLAY_URL;
export const WATER_COP_URL = process.env.REACT_APP_WATER_COP_URL;
export const Z_TRACKER_URL = process.env.REACT_APP_ZAKTRAK_URL;
export const OIP_URL = process.env.REACT_APP_OIP_URL;
export const AATA_URL = process.env.REACT_APP_AATA_URL;
export const BA_URL = process.env.REACT_APP_BA_URL;
export const S8CGP_URL = process.env.REACT_APP_S8CGP_URL;
export const NC_URL = process.env.REACT_APP_NC_URL;
export const OMNI_ID_URL = process.env.REACT_APP_OMNI_ID_URL;
export const NDS_URL = process.env.REACT_APP_NDS_URL;
export const OHA_URL = process.env.REACT_APP_OHA_URL;
export const MQTT_URL = process.env.REACT_APP_MQTT_URL;
export const CC_URL = process.env.REACT_APP_CC_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'dev';
export const TACO_LOG_BUCKET_NAME = process.env.REACT_APP_TACO_LOG_BUCKET_NAME;
export const TACO_BUCKET_NAME = process.env.REACT_APP_TACO_BUCKET_NAME;
export const ENV_NAME = process.env.REACT_APP_PROJECT_ENV || 'dev';
export const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
