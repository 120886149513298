export const assignedUsersIcon = [
  '61 59',
  `
<svg width="61" height="59" viewBox="0 0 61 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.7461 30.6827H52.894C51.9615 30.6827 51.0586 30.8101 50.1993 31.0445C48.5043 27.7123 45.0436 25.4229 41.056 25.4229H28.944C24.9564 25.4229 21.4957 27.7123 19.8007 31.0445C18.9414 30.8101 18.0385 30.6827 17.106 30.6827H10.2539C4.5999 30.6827 0 35.2826 0 40.9366V51.8902C0 55.2826 2.75994 58.0426 6.15234 58.0426H63.8477C67.2401 58.0426 70 55.2826 70 51.8902V40.9366C70 35.2826 65.4001 30.6827 59.7461 30.6827ZM18.6901 35.677V53.9411H6.15234C5.02154 53.9411 4.10156 53.0212 4.10156 51.8904V40.9367C4.10156 37.5443 6.8615 34.7844 10.2539 34.7844H17.106C17.6627 34.7844 18.2018 34.86 18.715 34.9993C18.7003 35.2237 18.6901 35.4493 18.6901 35.677ZM47.2083 53.9411H22.7917V35.6769C22.7917 32.2845 25.5516 29.5246 28.944 29.5246H41.056C44.4484 29.5246 47.2083 32.2845 47.2083 35.6769V53.9411ZM65.8984 51.8904C65.8984 53.0212 64.9785 53.9411 63.8477 53.9411H51.3099V35.6769C51.3099 35.449 51.2997 35.2234 51.285 34.9992C51.7982 34.86 52.3373 34.7843 52.894 34.7843H59.7461C63.1385 34.7843 65.8984 37.5442 65.8984 40.9366V51.8904Z" fill="#B8BFFF"/>
<path d="M13.684 11.3169C8.65975 11.3169 4.57227 15.4044 4.57227 20.4287C4.57213 25.4529 8.65975 29.5404 13.684 29.5404C18.7082 29.5404 22.7958 25.4529 22.7958 20.4287C22.7958 15.4044 18.7083 11.3169 13.684 11.3169ZM13.6839 25.4388C10.9212 25.4388 8.67369 23.1913 8.67369 20.4287C8.67369 17.666 10.9212 15.4185 13.6839 15.4185C16.4466 15.4185 18.6941 17.666 18.6941 20.4287C18.6941 23.1913 16.4466 25.4388 13.6839 25.4388Z" fill="#B8BFFF"/>
<path d="M34.9974 0C28.285 0 22.8242 5.46082 22.8242 12.1732C22.8242 18.8855 28.285 24.3463 34.9974 24.3463C41.7097 24.3463 47.1705 18.8855 47.1705 12.1732C47.1705 5.46096 41.7097 0 34.9974 0ZM34.9974 20.2448C30.5466 20.2448 26.9258 16.6239 26.9258 12.1732C26.9258 7.72256 30.5466 4.10156 34.9974 4.10156C39.4481 4.10156 43.069 7.72242 43.069 12.1732C43.069 16.6239 39.4481 20.2448 34.9974 20.2448Z" fill="#B8BFFF"/>
<path d="M56.3207 11.3169C51.2966 11.3169 47.209 15.4044 47.209 20.4287C47.2091 25.4529 51.2966 29.5404 56.3207 29.5404C61.345 29.5404 65.4325 25.4529 65.4325 20.4287C65.4325 15.4044 61.345 11.3169 56.3207 11.3169ZM56.3207 25.4388C53.5582 25.4388 51.3105 23.1913 51.3105 20.4287C51.3107 17.666 53.5582 15.4185 56.3207 15.4185C59.0834 15.4185 61.3309 17.666 61.3309 20.4287C61.3309 23.1913 59.0834 25.4388 56.3207 25.4388Z" fill="#B8BFFF"/>
</svg>
`,
];
