import { NOTIFICATION } from '../../constants';
import { pushNotification } from '../../utils/notification';
import {
  GET_USER_PENDING,
  GET_USER_FULFILLED,
  GET_USER_REJECTED,
  ADD_USER_PENDING,
  ADD_USER_FULFILLED,
  ADD_USER_REJECTED,
  GET_USER_BY_ID_PENDING,
  GET_USER_BY_ID_FULFILLED,
  GET_USER_BY_ID_REJECTED,
  UPDATED_USER_PENDING,
  UPDATED_USER_FULFILLED,
  UPDATED_USER_REJECTED,
  DELETE_USER_PENDING,
  DELETE_USER_FULFILLED,
  DELETE_USER_REJECTED,
  GET_ASSIGNED_PROPERTIES_PENDING,
  GET_ASSIGNED_PROPERTIES_FULFILLED,
  GET_ASSIGNED_PROPERTIES_REJECTED,
  UPDATE_USER_PASSWORD_PENDING,
  UPDATE_USER_PASSWORD_FULFILLED,
  UPDATE_USER_PASSWORD_REJECTED,
} from './UserActionTypes';
const initialState = {
  userData: [],
  addUser: [],
  userIdData: [],
  processing: false,
  processingEditData: false,
  processingAddData: false,
  processingDelete: false,
  updateUserData: [],
  assignedPropertyData: [],
  filteredRoles: null,
  filteredType: null,
  filteredClient: null,
};

const getUserSuccess = (state, action) => {
  return {
    ...state,
    userData: action.payload.data.data,
    processing: false,
    processingEditData: false,
    processingAddData: false,
    processingDelete: false,
    updateUserData: [],
    assignedPropertyData: [],
  };
};

const addRole = (state, action) => {
  return {
    ...state,
    filteredRoles: action.payload,
  };
};

const addType = (state, action) => {
  return {
    ...state,
    filteredType: action.payload,
  };
};

const addClient = (state, action) => {
  return {
    ...state,
    filteredClient: action.payload,
  };
};

const addUserPending = (state) => {
  return {
    ...state,
    processingAddData: true,
  };
};

const addUserSuccess = (state, action) => {
  return {
    ...state,
    addUser: action.data,
    processingAddData: false,
  };
};

const addUserFail = (state, action) => {
  if (action.payload.response.status === 409) {
    pushNotification('Email already exist ', 'error', 'TOP_RIGHT', 3000);
  }
  return {
    ...state,
    processingAddData: false,
  };
};

const getUserByIdPending = (state) => {
  return {
    ...state,
  };
};

const getUserByIdSuccess = (state, action) => {
  return {
    ...state,
    userIdData: action,
  };
};

const getUserByIdFail = (state) => {
  return {
    ...state,
  };
};

const updateUserPending = (state) => {
  return {
    ...state,
    processingEditData: true,
  };
};

const updateUserSuccess = (state, action) => {
  return {
    ...state,
    updateUserData: action.data,
    processingEditData: false,
  };
};

const updateUserFail = (state, action) => {
  if (action.payload.response.status === 403) {
    pushNotification('Permission Denied', 'error', 'TOP_RIGHT', 3000);
  }
  return {
    ...state,
    processingEditData: false,
  };
};
const updateUserPasswordPending = (state) => {
  return {
    ...state,
    processingUpdatePassword: true,
  };
};
const updateUserPasswordSuccess = (state, action) => {
  pushNotification(action?.payload?.data?.message, NOTIFICATION.SUCCESS);
  return {
    ...state,
    processingUpdatePassword: false,
    updatePasswordStatus: true,
  };
};
const updateUserPasswordFail = (state, action) => {
  pushNotification(
    `${action?.payload?.response?.data?.message}`,
    'error',
    'TOP_RIGHT',
    3000,
  );
  return {
    ...state,
    processingUpdatePassword: false,
  };
};
const deleteUserPending = (state) => {
  return {
    ...state,
    processingDelete: true,
  };
};

const deleteUserSuccess = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const deleteUserFail = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const getAssignedPropertiesSuccess = (state, action) => {
  return {
    ...state,
    assignedPropertyData: action.payload.data,
    processing: false,
  };
};

const setProcessingStart = (state) => ({
  ...state,
  processing: true,
});

const setProcessingCompleted = (state) => ({
  ...state,
  processing: false,
  userData: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_PENDING:
      return setProcessingStart(state, action);

    case GET_USER_FULFILLED:
      return getUserSuccess(state, action);

    case GET_USER_REJECTED:
      return setProcessingCompleted(state, action);

    case ADD_USER_PENDING:
      return addUserPending(state, action);

    case ADD_USER_FULFILLED:
      return addUserSuccess(state, action);

    case ADD_USER_REJECTED:
      return addUserFail(state, action);

    case GET_USER_BY_ID_PENDING:
      return getUserByIdPending(state, action);

    case GET_USER_BY_ID_FULFILLED:
      return getUserByIdSuccess(state, action);

    case GET_USER_BY_ID_REJECTED:
      return getUserByIdFail(state, action);

    case UPDATED_USER_PENDING:
      return updateUserPending(state, action);

    case UPDATED_USER_FULFILLED:
      return updateUserSuccess(state, action);

    case UPDATED_USER_REJECTED:
      return updateUserFail(state, action);

    case UPDATE_USER_PASSWORD_PENDING:
      return updateUserPasswordPending(state);
    case UPDATE_USER_PASSWORD_FULFILLED:
      return updateUserPasswordSuccess(state, action);
    case UPDATE_USER_PASSWORD_REJECTED:
      return updateUserPasswordFail(state, action);
    case DELETE_USER_PENDING:
      return deleteUserPending(state, action);

    case DELETE_USER_FULFILLED:
      return deleteUserSuccess(state, action);

    case DELETE_USER_REJECTED:
      return deleteUserFail(state, action);

    case GET_ASSIGNED_PROPERTIES_PENDING:
      return setProcessingStart(state, action);

    case GET_ASSIGNED_PROPERTIES_FULFILLED:
      return getAssignedPropertiesSuccess(state, action);

    case GET_ASSIGNED_PROPERTIES_REJECTED:
      return setProcessingCompleted(state, action);

    case 'ADD_ROLE':
      return addRole(state, action);

    case 'ADD_TYPE':
      return addType(state, action);

    case 'ADD_CLIENT':
      return addClient(state, action);

    default:
      return {
        ...state,
      };
  }
}
