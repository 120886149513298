import {
  GET_ZONES_PENDING,
  GET_ZONES_FULFILLED,
  GET_ZONES_REJECTED,
  GET_ZONES_BY_ID_PENDING,
  GET_ZONES_BY_ID_FULFILLED,
  GET_ZONES_BY_ID_REJECTED,
  ADD_ZONE_PENDING,
  ADD_ZONE_FULFILLED,
  ADD_ZONE_REJECTED,
  DELETE_ZONE_PENDING,
  DELETE_ZONE_FULFILLED,
  DELETE_ZONE_REJECTED,
  UPDATED_ZONE_PENDING,
  UPDATED_ZONE_FULFILLED,
  UPDATED_ZONE_REJECTED,
  ASSIGNED_ZONE_TO_USER_PENDING,
  ASSIGNED_ZONE_TO_USER_FULFILLED,
  ASSIGNED_ZONE_TO_USER_REJECTED,
  UNASSIGNED_ZONE_FROM_USER_PENDING,
  UNASSIGNED_ZONE_FROM_USER_FULFILLED,
  UNASSIGNED_ZONE_FROM_USER_REJECTED,
} from './ZoneActionTypes';
const initialState = {
  zoneData: [],
  processing: false,
  zoneIdData: [],
  processingAddZone: false,
  addZone: [],
  processingDelete: false,
  processingEditData: false,
  updateZoneData: [],
  assignedZoneGatewayData: [],
  assignedZoneDeviceData: [],
  assignedZoneUserData: [],
  processingGateway: false,
  processingDevices: false,
  processingAssignedUser: false,
  processingAssignedZoneToUser: false,
  processingUnAssigned: false,
  propertyFilterData: [],
  projectFilterData: [],
};

const getZoneSuccess = (state, action) => {
  return {
    ...state,
    zoneData: action.payload.data.data,
    processing: false,
  };
};

const getZoneByIdSuccess = (state, action) => {
  return {
    ...state,
    zoneIdData: action.payload.data,
    processing: false,
  };
};

const addZonePending = (state) => {
  return {
    ...state,
    processingAddZone: true,
  };
};

const addZoneSuccess = (state, action) => {
  return {
    ...state,
    addZone: action.data,
    processingAddZone: false,
  };
};

const addZoneFail = (state) => {
  return {
    ...state,
    processingAddZone: false,
  };
};

const deleteZonePending = (state) => {
  return {
    ...state,
    processingDelete: true,
  };
};

const deleteZoneSuccess = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const deleteZoneFail = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const updateZonePending = (state) => {
  return {
    ...state,
    processingEditData: true,
  };
};

const updateZoneSuccess = (state, action) => {
  return {
    ...state,
    updateZoneData: action.data,
    processingEditData: false,
  };
};

const updateZoneFail = (state) => {
  return {
    ...state,
    processingEditData: false,
  };
};

const assignedZoneToUserPending = (state) => {
  return {
    ...state,
    processingAssignedZoneToUser: true,
  };
};

const assignedZoneToUserSuccess = (state) => {
  return {
    ...state,
    processingAssignedZoneToUser: false,
  };
};

const assignedZoneToUserFail = (state) => {
  return {
    ...state,
    processingAssignedZoneToUser: false,
  };
};

const unAssignedZoneFromUserPending = (state) => {
  return {
    ...state,
    processingUnAssigned: true,
  };
};

const unAssignedZoneFromUserSuccess = (state) => {
  return {
    ...state,
    processingUnAssigned: false,
  };
};

const unAssignedZoneFromUserFail = (state) => {
  return {
    ...state,
    processingUnAssigned: false,
  };
};

const setProcessingStart = (state) => ({
  ...state,
  processing: true,
});

const setProcessingCompleted = (state) => ({
  ...state,
  processing: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ZONES_PENDING:
      return setProcessingStart(state, action);

    case GET_ZONES_FULFILLED:
      return getZoneSuccess(state, action);

    case GET_ZONES_REJECTED:
      return setProcessingCompleted(state, action);

    case GET_ZONES_BY_ID_PENDING:
      return setProcessingStart(state, action);

    case GET_ZONES_BY_ID_FULFILLED:
      return getZoneByIdSuccess(state, action);

    case GET_ZONES_BY_ID_REJECTED:
      return setProcessingCompleted(state, action);

    case ADD_ZONE_PENDING:
      return addZonePending(state, action);

    case ADD_ZONE_FULFILLED:
      return addZoneSuccess(state, action);

    case ADD_ZONE_REJECTED:
      return addZoneFail(state, action);

    case DELETE_ZONE_PENDING:
      return deleteZonePending(state, action);

    case DELETE_ZONE_FULFILLED:
      return deleteZoneSuccess(state, action);

    case DELETE_ZONE_REJECTED:
      return deleteZoneFail(state, action);

    case UPDATED_ZONE_PENDING:
      return updateZonePending(state, action);

    case UPDATED_ZONE_FULFILLED:
      return updateZoneSuccess(state, action);

    case UPDATED_ZONE_REJECTED:
      return updateZoneFail(state, action);

    case ASSIGNED_ZONE_TO_USER_PENDING:
      return assignedZoneToUserPending(state, action);

    case ASSIGNED_ZONE_TO_USER_FULFILLED:
      return assignedZoneToUserSuccess(state, action);

    case ASSIGNED_ZONE_TO_USER_REJECTED:
      return assignedZoneToUserFail(state, action);

    case UNASSIGNED_ZONE_FROM_USER_PENDING:
      return unAssignedZoneFromUserPending(state, action);

    case UNASSIGNED_ZONE_FROM_USER_FULFILLED:
      return unAssignedZoneFromUserSuccess(state, action);

    case UNASSIGNED_ZONE_FROM_USER_REJECTED:
      return unAssignedZoneFromUserFail(state, action);

    default:
      return {
        ...state,
      };
  }
}
