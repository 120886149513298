import {
  GET_TREE_TILT_PENDING,
  GET_TREE_TILT_FULFILLED,
  GET_TREE_TILT_REJECTED,
  GET_PACKET_PENDING,
  GET_PACKET_FULFILLED,
  GET_PACKET_REJECTED,
  GET_TREE_TILT_Z_PENDING,
  GET_TREE_TILT_Z_FULFILLED,
  GET_TREE_TILT_Z_REJECTED,
} from './GraphActionTypes';
import { pushNotification } from '../../../utils/notification';
import moment from 'moment';

const initialState = {
  treeTilt: [],
  treeTiltZ: [],
  processingTreeTilt: false,
  processingTreeTiltZ: false,
  packet: [],
  processingPacket: false,
};

const getTreeTiltPending = (state) => {
  return {
    ...state,
    processingTreeTilt: true,
  };
};
const getTreeTiltZPending = (state) => {
  return {
    ...state,
    processingTreeTiltZ: true,
  };
};
const getTreeTiltFulfilled = (state, action) => {
  let records = [];
  const data = action?.payload?.data?.data;
  if (data?.length > 0) {
    data.map((item) => {
      records.push({
        timestamp: moment(item.timestamp)
          .local()
          .format('DD/MM,hh:mma')
          .slice(0, -1),
        y: item.realY,
        z: item.realZ,
      });
    });
  }
  return {
    ...state,
    treeTilt: records,
    processingTreeTilt: false,
  };
};
const getTreeTiltZFulfilled = (state, action) => {
  let records = [];
  let data = action?.payload?.data?.data;
  if (data?.length > 0) {
    data.map((item) => {
      records.push({
        timestamp: moment(item.timestamp)
          .local()
          .format('DD/MM,hh:mma')
          .slice(0, -1),
        y: item.realY,
        z: item.realZ,
      });
    });
  }
  return {
    ...state,
    treeTiltZ: records,
    processingTreeTiltZ: false,
  };
};
const getTreeTiltRejected = (state, action) => {
  pushNotification(
    `${action?.payload?.response?.data?.message}`,
    'error',
    'TOP_RIGHT',
    3000,
  );
  return {
    ...state,
    processingTreeTilt: false,
  };
};
const getTreeTiltZRejected = (state, action) => {
  pushNotification(
    `${action?.payload?.response?.data?.message}`,
    'error',
    'TOP_RIGHT',
    3000,
  );
  return {
    ...state,
    processingTreeTiltZ: false,
  };
};
const getPacketPending = (state) => {
  return {
    ...state,
    processingPacket: true,
  };
};
const getPacketFulfilled = (state, action) => {
  let records = [];
  const data = action?.payload?.data?.data?.rows;
  if (data?.length > 0) {
    data
      .slice(0)
      .reverse()
      .map((item) => {
        records.push({
          timestamp: moment(item.formatedTimeStamp).local().format('hh:mm'),
          packets: item.count,
          date: item.formatedTimeStamp,
        });
      });
  }
  return {
    ...state,
    packet: records,
    processingPacket: false,
  };
};

const getPacketRejected = (state, action) => {
  pushNotification(
    `${action?.payload?.response?.data?.message}`,
    'error',
    'TOP_RIGHT',
    3000,
  );
  return {
    ...state,
    processingPacket: false,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TREE_TILT_PENDING:
      return getTreeTiltPending(state, action);
    case GET_TREE_TILT_FULFILLED:
      return getTreeTiltFulfilled(state, action);
    case GET_TREE_TILT_REJECTED:
      return getTreeTiltRejected(state, action);
    case GET_TREE_TILT_Z_PENDING:
      return getTreeTiltZPending(state, action);
    case GET_TREE_TILT_Z_FULFILLED:
      return getTreeTiltZFulfilled(state, action);
    case GET_TREE_TILT_Z_REJECTED:
      return getTreeTiltZRejected(state, action);
    case GET_PACKET_PENDING:
      return getPacketPending(state, action);
    case GET_PACKET_FULFILLED:
      return getPacketFulfilled(state, action);
    case GET_PACKET_REJECTED:
      return getPacketRejected(state, action);
    default:
      return {
        ...state,
      };
  }
}
