export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_TABS = 'GET_DASHBOARD_TABS';
export const GET_DASHBOARD_PENDING = 'GET_DASHBOARD_PENDING';
export const GET_DASHBOARD_FULFILLED = 'GET_DASHBOARD_FULFILLED';
export const GET_DASHBOARD_REJECTED = 'GET_DASHBOARD_REJECTED';
export const GET_DASHBOARD_TABS_PENDING = 'GET_DASHBOARD_TABS_PENDING';
export const GET_DASHBOARD_TABS_FULFILLED = 'GET_DASHBOARD_TABS_FULFILLED';
export const GET_DASHBOARD_TABS_REJECTED = 'GET_DASHBOARD_TABS_REJECTED';
export const IS_PHOTO_TABLE = 'IS_PHOTO_TABLE';
export const SET_ANNOTATION_TYPE = 'SET_ANNOTATION_TYPE';
export const SET_DEVICE_STATE = 'SET_DEVICE_STATE';
export const SET_DASHBOARD_DATE_FILTER = 'SET_DASHBOARD_DATE_FILTER';
export const SET_ACTIVE_TILE = 'SET_ACTIVE_TILE';
export const SET_PROGRAMMER_STATUS = 'SET_PROGRAMMER_STATUS';
