export const customersfullIcon = [
  '24 20',
  `

<path d="M20.4844 10.5197H18.1351C17.8154 10.5197 17.5058 10.5634 17.2112 10.6437C16.63 9.50128 15.4435 8.71631 14.0763 8.71631H9.92367C8.55647 8.71631 7.36997 9.50128 6.78881 10.6437C6.4942 10.5634 6.18464 10.5197 5.86491 10.5197H3.51562C1.57711 10.5197 0 12.0968 0 14.0353V17.7908C0 18.9539 0.946266 19.9002 2.10938 19.9002H21.8906C23.0537 19.9002 24 18.9539 24 17.7908V14.0353C24 12.0968 22.4229 10.5197 20.4844 10.5197ZM6.40805 12.232V18.494H2.10938C1.72167 18.494 1.40625 18.1786 1.40625 17.7909V14.0354C1.40625 12.8722 2.35252 11.926 3.51562 11.926H5.86491C6.05578 11.926 6.24061 11.9519 6.41658 11.9997C6.41152 12.0766 6.40805 12.1539 6.40805 12.232ZM16.1857 18.494H7.8143V12.232C7.8143 11.0689 8.76056 10.1226 9.92367 10.1226H14.0763C15.2394 10.1226 16.1857 11.0689 16.1857 12.232V18.494ZM22.5938 17.7909C22.5938 18.1786 22.2783 18.494 21.8906 18.494H17.592V12.232C17.592 12.1538 17.5885 12.0765 17.5834 11.9996C17.7594 11.9519 17.9442 11.9259 18.1351 11.9259H20.4844C21.6475 11.9259 22.5938 12.8722 22.5938 14.0353V17.7909Z" fill="#24A5ED"/>
<path d="M4.69044 3.87988C2.96783 3.87988 1.56641 5.2813 1.56641 7.00391C1.56636 8.72652 2.96783 10.1279 4.69044 10.1279C6.413 10.1279 7.81447 8.72652 7.81447 7.00391C7.81447 5.2813 6.41305 3.87988 4.69044 3.87988ZM4.69039 8.7217C3.74319 8.7217 2.97261 7.95112 2.97261 7.00391C2.97261 6.05671 3.74319 5.28613 4.69039 5.28613C5.63759 5.28613 6.40817 6.05671 6.40817 7.00391C6.40817 7.95112 5.63759 8.7217 4.69039 8.7217Z" fill="#24A5ED"/>
<path d="M12.0018 0C9.70041 0 7.82812 1.87228 7.82812 4.17366C7.82812 6.47503 9.70041 8.34731 12.0018 8.34731C14.3032 8.34731 16.1754 6.47503 16.1754 4.17366C16.1754 1.87233 14.3032 0 12.0018 0ZM12.0018 6.94106C10.4758 6.94106 9.23438 5.69963 9.23438 4.17366C9.23438 2.64773 10.4758 1.40625 12.0018 1.40625C13.5278 1.40625 14.7692 2.64769 14.7692 4.17366C14.7692 5.69963 13.5278 6.94106 12.0018 6.94106Z" fill="#24A5ED"/>
<path d="M19.3135 3.87988C17.5909 3.87988 16.1895 5.2813 16.1895 7.00391C16.1895 8.72652 17.5909 10.1279 19.3135 10.1279C21.0361 10.1279 22.4375 8.72652 22.4375 7.00391C22.4375 5.2813 21.0361 3.87988 19.3135 3.87988ZM19.3135 8.7217C18.3663 8.7217 17.5957 7.95112 17.5957 7.00391C17.5957 6.05671 18.3663 5.28613 19.3135 5.28613C20.2607 5.28613 21.0313 6.05671 21.0313 7.00391C21.0313 7.95112 20.2607 8.7217 19.3135 8.7217Z" fill="#24A5ED"/>

`,
];
