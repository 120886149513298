export const GET_USER_PENDING = ' GET_USER_PENDING';
export const GET_USER_FULFILLED = ' GET_USER_FULFILLED';
export const GET_USER_REJECTED = ' GET_USER_REJECTED';
export const ADD_USER_PENDING = ' ADD_USER_PENDING';
export const ADD_USER_FULFILLED = ' ADD_USER_FULFILLED';
export const ADD_USER_REJECTED = ' ADD_USER_REJECTED';
export const GET_USER_BY_ID_PENDING = ' GET_USER_BY_ID_PENDING';
export const GET_USER_BY_ID_FULFILLED = ' GET_USER_BY_ID_FULFILLED';
export const GET_USER_BY_ID_REJECTED = ' GET_USER_BY_ID_REJECTED';
export const UPDATED_USER_PENDING = ' UPDATED_USER_PENDING';
export const UPDATED_USER_FULFILLED = ' UPDATED_USER_FULFILLED';
export const UPDATED_USER_REJECTED = ' UPDATED_USER_REJECTED';
export const UPDATE_USER_PASSWORD_PENDING = 'UPDATE_USER_PASSWORD_PENDING';
export const UPDATE_USER_PASSWORD_FULFILLED = 'UPDATE_USER_PASSWORD_FULFILLED';
export const UPDATE_USER_PASSWORD_REJECTED = 'UPDATE_USER_PASSWORD_REJECTED';
export const DELETE_USER_PENDING = ' DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = ' DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = ' DELETE_USER_REJECTED';
export const GET_ASSIGNED_PROPERTIES_PENDING =
  ' GET_ASSIGNED_PROPERTIES_PENDING';
export const GET_ASSIGNED_PROPERTIES_FULFILLED =
  ' GET_ASSIGNED_PROPERTIES_FULFILLED';
export const GET_ASSIGNED_PROPERTIES_REJECTED =
  ' GET_ASSIGNED_PROPERTIES_REJECTED';
export const GET_USER_BY_ID = ' GET_USER_BY_ID';
export const ADD_USER = ' ADD_USER';
export const GET_USER = ' GET_USER';
export const DELETE_USER = ' DELETE_USER';
export const UPDATED_USER = ' UPDATED_USER';
export const GET_ASSIGNED_PROPERTIES = ' GET_ASSIGNED_PROPERTIES';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
