import {
  GET_END_DEVICE_PENDING,
  GET_END_DEVICE_FULFILLED,
  GET_END_DEVICE_REJECTED,
  ADD_END_DEVICE_PENDING,
  ADD_END_DEVICE_FULFILLED,
  ADD_END_DEVICE_REJECTED,
  DELETE_END_DEVICE_PENDING,
  DELETE_END_DEVICE_FULFILLED,
  DELETE_END_DEVICE_REJECTED,
  UPDATED_END_DEVICE_PENDING,
  UPDATED_END_DEVICE_FULFILLED,
  UPDATED_END_DEVICE_REJECTED,
} from './EndDeviceActionTypes';
const initialState = {
  endDeviceData: [],
  updateEndDeviceData: [],
  addEndDeviceData: [],
  processing: false,
  processingAddData: false,
  processingDeleteData: false,
  processingEditData: false,
};

const getEndDeviceSuccess = (state, action) => {
  return {
    ...state,
    endDeviceData: action.payload.data.data,
    processing: false,
  };
};

const setProcessingStart = (state) => ({
  ...state,
  processing: true,
});

const setProcessingCompleted = (state) => ({
  ...state,
  processing: false,
});

const addEndDevicePending = (state) => {
  return {
    ...state,
    processingAddData: true,
  };
};

const addEndDeviceSuccess = (state, action) => {
  return {
    ...state,
    addEndDeviceData: action.data,
    processingAddData: false,
  };
};

const addEndDeviceFail = (state) => {
  return {
    ...state,
    processingAddData: false,
  };
};
const deleteEndDevicePending = (state) => {
  return {
    ...state,
    processingDeleteData: true,
  };
};

const deleteEndDeviceSuccess = (state) => {
  return {
    ...state,
    processingDeleteData: false,
  };
};

const deleteEndDeviceFail = (state) => {
  return {
    ...state,
    processingDeleteData: false,
  };
};

const updateEndDevicePending = (state) => {
  return {
    ...state,
    processingEditData: true,
  };
};

const updateEndDeviceSuccess = (state, action) => {
  return {
    ...state,
    updateEndDeviceData: action.data,
    processingEditData: false,
  };
};

const updateEndDeviceFail = (state) => {
  return {
    ...state,
    processingEditData: false,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_END_DEVICE_PENDING:
      return setProcessingStart(state, action);

    case GET_END_DEVICE_FULFILLED:
      return getEndDeviceSuccess(state, action);

    case GET_END_DEVICE_REJECTED:
      return setProcessingCompleted(state, action);

    case ADD_END_DEVICE_PENDING:
      return addEndDevicePending(state, action);

    case ADD_END_DEVICE_FULFILLED:
      return addEndDeviceSuccess(state, action);

    case ADD_END_DEVICE_REJECTED:
      return addEndDeviceFail(state, action);

    case DELETE_END_DEVICE_PENDING:
      return deleteEndDevicePending(state, action);

    case DELETE_END_DEVICE_FULFILLED:
      return deleteEndDeviceSuccess(state, action);

    case DELETE_END_DEVICE_REJECTED:
      return deleteEndDeviceFail(state, action);

    case UPDATED_END_DEVICE_PENDING:
      return updateEndDevicePending(state, action);

    case UPDATED_END_DEVICE_FULFILLED:
      return updateEndDeviceSuccess(state, action);

    case UPDATED_END_DEVICE_REJECTED:
      return updateEndDeviceFail(state, action);
    default:
      return {
        ...state,
      };
  }
}
