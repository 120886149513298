export const gatewayIcon = [
  '52 47',
  `
<svg width="52" height="47" viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.8477 26.6258H59.0625V4.36949C59.0625 3.25193 58.1445 2.34619 57.0117 2.34619C55.879 2.34619 54.9609 3.25193 54.9609 4.36949V26.6258H15.0391V4.36949C15.0391 3.25193 14.121 2.34619 12.9883 2.34619C11.8555 2.34619 10.9375 3.25193 10.9375 4.36949V26.6258H6.15234C2.76001 26.6258 0 29.3483 0 32.6957V44.8355C0 45.9525 0.918045 46.8588 2.05078 46.8588H67.9492C69.082 46.8588 70 45.9525 70 44.8355V32.6957C70 29.3483 67.24 26.6258 63.8477 26.6258ZM65.8984 42.8122H4.10156V32.6957C4.10156 31.5797 5.02174 30.6724 6.15234 30.6724H63.8477C64.9783 30.6724 65.8984 31.5797 65.8984 32.6957V42.8122Z" fill="#89CAFF"/>
<path d="M26.2978 7.92518C31.0957 3.19203 38.9015 3.19203 43.6995 7.92518C44.0995 8.32036 44.6245 8.51795 45.1495 8.51795C45.6739 8.51795 46.1989 8.32036 46.5994 7.92518C47.4005 7.13536 47.4005 5.85394 46.5994 5.06358C40.2025 -1.24711 29.7942 -1.24711 23.3973 5.06358C22.5967 5.85394 22.5967 7.13483 23.3973 7.92518C24.1984 8.71501 25.4972 8.71501 26.2978 7.92518Z" fill="#89CAFF"/>
<path d="M39.3493 14.2404C39.8738 14.2404 40.3987 14.0428 40.7993 13.6476C41.5998 12.8578 41.5998 11.5764 40.7993 10.7865C39.25 9.25748 37.1901 8.41602 34.9989 8.41602C32.8076 8.41602 30.7478 9.25748 29.1985 10.7865C28.3974 11.5764 28.3974 12.8573 29.1985 13.6476C29.999 14.438 31.2979 14.4374 32.0984 13.6476C32.8733 12.8831 33.903 12.4626 34.9989 12.4626C36.0942 12.4626 37.1244 12.8831 37.8988 13.6476C38.2993 14.0428 38.8243 14.2404 39.3493 14.2404Z" fill="#89CAFF"/>
<path d="M28.8477 36.742C28.8477 37.8591 27.9296 38.7653 26.7969 38.7653C25.6641 38.7653 24.7461 37.8591 24.7461 36.742C24.7461 35.6245 25.6641 34.7188 26.7969 34.7188C27.9296 34.7188 28.8477 35.6245 28.8477 36.742Z" fill="#89CAFF"/>
<path d="M37.0508 36.742C37.0508 37.8591 36.1327 38.7653 35 38.7653C33.8673 38.7653 32.9492 37.8591 32.9492 36.742C32.9492 35.6245 33.8673 34.7188 35 34.7188C36.1327 34.7188 37.0508 35.6245 37.0508 36.742Z" fill="#89CAFF"/>
<path d="M45.2539 36.742C45.2539 37.8591 44.3359 38.7653 43.2031 38.7653C42.0704 38.7653 41.1523 37.8591 41.1523 36.742C41.1523 35.6245 42.0704 34.7188 43.2031 34.7188C44.3359 34.7188 45.2539 35.6245 45.2539 36.742Z" fill="#89CAFF"/>
<path d="M37.0508 18.5326C37.0508 19.6496 36.1327 20.5559 35 20.5559C33.8673 20.5559 32.9492 19.6496 32.9492 18.5326C32.9492 17.415 33.8673 16.5093 35 16.5093C36.1327 16.5093 37.0508 17.415 37.0508 18.5326Z" fill="#89CAFF"/>
</svg>
  
`,
];
