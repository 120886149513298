import {
  GET_PROPERTY_PENDING,
  GET_PROPERTY_FULFILLED,
  GET_PROPERTY_REJECTED,
  ADD_PROPERTY_PENDING,
  ADD_PROPERTY_FULFILLED,
  ADD_PROPERTY_REJECTED,
  UPDATED_PROPERTY_PENDING,
  UPDATED_PROPERTY_FULFILLED,
  UPDATED_PROPERTY_REJECTED,
  GET_AUTH_TOKEN_FULFILLED,
  DELETE_PROPERTY_PENDING,
  DELETE_PROPERTY_FULFILLED,
  DELETE_PROPERTY_REJECTED,
  GET_PROPERTY_BY_ID_FULFILLED,
  GET_ASSIGNED_USER_PENDING,
  GET_ASSIGNED_USER_FULFILLED,
  GET_ASSIGNED_USER_REJECTED,
  ADD_PROPERTY_ASSIGNED_USER_PENDING,
  ADD_PROPERTY_ASSIGNED_USER_FULFILLED,
  ADD_PROPERTY_ASSIGNED_USER_REJECTED,
  ADD_PROPERTY_UNASSIGNED_USER_PENDING,
  ADD_PROPERTY_UNASSIGNED_USER_FULFILLED,
  ADD_PROPERTY_UNASSIGNED_USER_REJECTED,
  GET_OWNER_LIST_FULFILLED,
} from './PropertyActionTypes';
const initialState = {
  processing: false,
  processingForAddUser: false,
  processingAssignUser: false,
  propertyData: [],
  propertyIdData: [],
  authToken: {},
  addPropertyData: {},
  updatePropertyData: {},
  errorMessage: '',
  propertyAssignedUser: [],
  propertyCustomer: [],
  assignedUser: [],
  ownerList: [],
  addPropertyAssignedUser: [],
  addPropertyUnAssignedUser: [],
  projectFilterData: [],
  filteredProperty: null,
};

const getAuthTokenSuccess = (state, action) => {
  const authToken = action.payload.data.auth_token;
  localStorage.setItem('AUTH-TOKEN', authToken);
  return {
    ...state,
    authToken: action.payload.data,
  };
};

const getPropertySuccess = (state, action) => {
  return {
    ...state,
    propertyData: action.payload.data.data,
    processing: false,
  };
};

const addPropertySuccess = (state, action) => {
  return {
    ...state,
    addProperty: action.data,
    processing: false,
  };
};

const updatePropertySuccess = (state, action) => {
  return {
    ...state,
    updatePropertyData: action.data,
    processing: false,
  };
};

const getOwnerList = (state, action) => {
  return {
    ...state,
    ownerList: action.payload.data.data.records,
    processing: false,
  };
};

const getPropertyByIdSuccess = (state, action) => {
  return {
    ...state,
    propertyIdData: action.payload.data,
  };
};

const getAssignedUser = (state, action) => {
  return {
    ...state,
    assignedUser: action.payload.data.data || [],
    processing: false,
  };
};

const addPropertyAssignedUserPending = (state) => {
  return {
    ...state,
    processingForAddUser: true,
  };
};

const addPropertyAssignedUserSuccess = (state, action) => {
  return {
    ...state,
    addPropertyAssignedUser: action.data,
    processingForAddUser: false,
  };
};

const addPropertyAssignedUserFail = (state) => {
  return {
    ...state,
    processingForAddUser: false,
  };
};

const addPropertyUnAssignedUserSuccess = (state, action) => {
  return {
    ...state,
    addPropertyUnAssignedUser: action.data,
    processing: false,
  };
};

const setProcessingStart = (state) => ({
  ...state,
  processing: true,
});

const setProcessingCompleted = (state) => ({
  ...state,
  processing: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTY_PENDING:
      return setProcessingStart(state, action);

    case GET_PROPERTY_FULFILLED:
      return getPropertySuccess(state, action);

    case GET_PROPERTY_REJECTED:
      return setProcessingCompleted(state, action);

    case ADD_PROPERTY_PENDING:
      return setProcessingStart(state, action);

    case ADD_PROPERTY_FULFILLED:
      return addPropertySuccess(state, action);

    case ADD_PROPERTY_REJECTED:
      return setProcessingCompleted(state, action);

    case UPDATED_PROPERTY_PENDING:
      return setProcessingStart(state, action);

    case UPDATED_PROPERTY_FULFILLED:
      return updatePropertySuccess(state, action);

    case UPDATED_PROPERTY_REJECTED:
      return setProcessingCompleted(state, action);

    case GET_AUTH_TOKEN_FULFILLED:
      return getAuthTokenSuccess(state, action);

    case DELETE_PROPERTY_PENDING:
      return setProcessingStart(state, action);

    case DELETE_PROPERTY_FULFILLED:
      return setProcessingCompleted(state, action);

    case DELETE_PROPERTY_REJECTED:
      return setProcessingCompleted(state, action);

    case GET_PROPERTY_BY_ID_FULFILLED:
      return getPropertyByIdSuccess(state, action);

    case GET_OWNER_LIST_FULFILLED:
      return getOwnerList(state, action);

    case GET_ASSIGNED_USER_PENDING:
      return setProcessingStart(state, action);

    case GET_ASSIGNED_USER_FULFILLED:
      return getAssignedUser(state, action);

    case GET_ASSIGNED_USER_REJECTED:
      return setProcessingCompleted(state, action);

    case ADD_PROPERTY_ASSIGNED_USER_PENDING:
      return addPropertyAssignedUserPending(state, action);

    case ADD_PROPERTY_ASSIGNED_USER_FULFILLED:
      return addPropertyAssignedUserSuccess(state, action);

    case ADD_PROPERTY_ASSIGNED_USER_REJECTED:
      return addPropertyAssignedUserFail(state, action);

    case ADD_PROPERTY_UNASSIGNED_USER_PENDING:
      return setProcessingStart(state, action);

    case ADD_PROPERTY_UNASSIGNED_USER_FULFILLED:
      return addPropertyUnAssignedUserSuccess(state, action);

    case ADD_PROPERTY_UNASSIGNED_USER_REJECTED:
      return setProcessingCompleted(state, action);

    default:
      return {
        ...state,
      };
  }
}
