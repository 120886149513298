import {
  AATA_USER,
  BA_USER,
  WATERCOP_USER,
  DART_DISPLAY_USER,
  NDS_USER,
  S8CGP_7_USER,
} from '..';

const DEV_CONFIG = {
  USER_GROUPS: {
    BA_ADMIN: 125,
    BA_OWNER: 142,
    BA_DISTRIBUTER: 143,
    AATA_ADMIN: 119,
    AATA_CUSTOMER: 146,
    AATA_MOBILE: 148,
    CROWN_CASTLE_USER: 147,
    OMNI_ID_ADMIN: 174,
    OMNI_ID_CUSTOMER: 162,
    NDS_ADMIN: 167,
    NDS_CUSTOMER_ADMIN: 168,
    NDS_CUSTOMER_USER: 169,
    WATERCOP_ADMIN: 23,
    OXIT_ADMIN: 12,
    S8CGP_Client_ADMIN: 173,
    S8CGP_CUSTOMER_OWNER: 205,
    S8CGP_CUSTOMER_ADMIN: 206,
    ZFLO_ADMIN: 61,
    ZFLO_COMPANY_ADMIN: 236,
    ZFLO_END_CUSTOMER: 237,
    ZFLO_SUPPORT: 81,
    ZFLO_LOGISTICS: 232,
    ZFLO_COMPANY_OWNER: 233,
    ZFLO_COMPANY_SUPPORT: 234,
    ZFLO_COMPANY_LOGISTICS: 235,
  },
  CLIENT_IDS: {
    WATERCOP: { id: 3, name: WATERCOP_USER },
    AATA: { id: 59, name: AATA_USER },
    BA: { id: 49, name: BA_USER },
    DART: { id: 2, name: DART_DISPLAY_USER },
    NDS: { id: 96, name: NDS_USER },
    S8CGP: { id: 98, name: S8CGP_7_USER },
  },
  FOOTERS: {
    ENV: 'DEV',
  },
};

const QA_CONFIG = {
  USER_GROUPS: {
    BA_ADMIN: 51,
    BA_OWNER: 52,
    BA_DISTRIBUTER: 53,
    AATA_ADMIN: 32,
    AATA_CUSTOMER: 48,
    AATA_MOBILE: 50,
    WATERCOP_ADMIN: 7,
    NDS_ADMIN: 57,
    NDS_CUSTOMER_ADMIN: 58,
    NDS_CUSTOMER_USER: 59,
    OXIT_ADMIN: 35,
    OMNI_ID_CUSTOMER: 56,
    OMNI_ID_ADMIN: 55,
    S8CGP_Client_ADMIN: 60,
    S8CGP_CUSTOMER_OWNER: 63,
    S8CGP_CUSTOMER_ADMIN: 64,
    ZFLO_ADMIN: 15,
    ZFLO_COMPANY_ADMIN: 78,
    ZFLO_END_CUSTOMER: 75,
    ZFLO_SUPPORT: 77,
    ZFLO_LOGISTICS: 79,
    ZFLO_COMPANY_OWNER: 74,
    ZFLO_COMPANY_SUPPORT: 73,
    ZFLO_COMPANY_LOGISTICS: 76,
  },
  CLIENT_IDS: {
    WATERCOP: { id: 3, name: WATERCOP_USER },
    AATA: { id: 58, name: AATA_USER },
    BA: { id: 12, name: BA_USER },
    DART: { id: 2, name: DART_DISPLAY_USER },
    NDS: { id: 72, name: NDS_USER },
    S8CGP: { id: 73, name: S8CGP_7_USER },
  },
  FOOTERS: {
    ENV: 'QA',
  },
};

const UAT_CONFIG = {
  USER_GROUPS: {
    BA_ADMIN: 26,
    BA_OWNER: 25,
    BA_DISTRIBUTER: 24,
    AATA_ADMIN: 20,
    AATA_CUSTOMER: 21,
    AATA_MOBILE: 23,
    WATERCOP_ADMIN: 6,
    OXIT_ADMIN: 27,
  },
  CLIENT_IDS: {
    WATERCOP: { id: 291, name: WATERCOP_USER },
    AATA: { id: 503, name: AATA_USER },
    BA: { id: 505, name: BA_USER },
    DART: { id: 264, name: DART_DISPLAY_USER },
  },
  FOOTERS: {
    ENV: 'UAT',
  },
};

const PROD_CONFIG = {
  USER_GROUPS: {
    BA_ADMIN: 26,
    BA_OWNER: 25,
    BA_DISTRIBUTER: 24,
    AATA_ADMIN: 20,
    AATA_CUSTOMER: 21,
    AATA_MOBILE: 23,
    WATERCOP_ADMIN: 6,
    NDS_ADMIN: 35,
    NDS_CUSTOMER_ADMIN: 36,
    NDS_CUSTOMER_USER: 37,
    OMNI_ID_ADMIN: 28,
    OXIT_ADMIN: 10,
    OMNI_ID_CUSTOMER: 29,
    S8CGP_Client_ADMIN: 46,
    S8CGP_CUSTOMER_OWNER: 47,
    S8CGP_CUSTOMER_ADMIN: 45,
    ZFLO_ADMIN: 18,
    ZFLO_COMPANY_ADMIN: 54,
    ZFLO_END_CUSTOMER: 51,
    ZFLO_SUPPORT: 53,
    ZFLO_LOGISTICS: 55,
    ZFLO_COMPANY_OWNER: 50,
    ZFLO_COMPANY_SUPPORT: 49,
    ZFLO_COMPANY_LOGISTICS: 52,
  },
  CLIENT_IDS: {
    WATERCOP: { id: 291, name: WATERCOP_USER },
    AATA: { id: 503, name: AATA_USER },
    BA: { id: 505, name: BA_USER },
    DART: { id: 264, name: DART_DISPLAY_USER },
    NDS: { id: 509, name: NDS_USER },
    S8CGP: { id: 516, name: S8CGP_7_USER },
  },
  FOOTERS: {
    ENV: '',
  },
};

const config = {
  dev: DEV_CONFIG,
  qa: QA_CONFIG,
  uat: UAT_CONFIG,
  prod: PROD_CONFIG,
};

export default config;
