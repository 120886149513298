export const addDeviceIcon = [
  '32 32',
  `
<path d="M19.9747 5.44547H19.6137V4.17746C19.6199 3.9717 19.5442 3.77188 19.4032 3.62189C19.2622 3.4719 19.0675 3.38397 18.8617 3.37744H18.4807C18.2749 3.38397 18.0802 3.4719 17.9392 3.62189C17.7982 3.77188 17.7225 3.9717 17.7287 4.17746V5.44547H14.6177V4.17746C14.6239 3.9717 14.5482 3.77188 14.4072 3.62189C14.2662 3.4719 14.0714 3.38397 13.8657 3.37744H13.4807C13.2749 3.38397 13.0802 3.4719 12.9392 3.62189C12.7982 3.77188 12.7225 3.9717 12.7287 4.17746V5.44547H12.3677C11.3042 5.48021 10.2978 5.93497 9.56888 6.71017C8.83997 7.48538 8.448 8.51783 8.47873 9.58145V25.1035C8.448 26.1671 8.83997 27.1995 9.56888 27.9747C10.2978 28.7499 11.3042 29.2047 12.3677 29.2394H19.9787C21.0421 29.2044 22.0482 28.7496 22.7769 27.9744C23.5056 27.1992 23.8975 26.1669 23.8667 25.1035V9.58044C23.8972 8.51649 23.5046 7.48384 22.7751 6.70877C22.0456 5.9337 21.0386 5.47943 19.9747 5.44547ZM21.9747 25.1045C21.9903 25.6514 21.7887 26.1823 21.4138 26.5809C21.039 26.9795 20.5216 27.2134 19.9747 27.2314H12.3637C11.8168 27.2134 11.2994 26.9795 10.9245 26.5809C10.5497 26.1823 10.348 25.6514 10.3637 25.1045V9.58044C10.348 9.0335 10.5497 8.50266 10.9245 8.10403C11.2994 7.70541 11.8168 7.47149 12.3637 7.45346H19.9747C20.5216 7.47149 21.039 7.70541 21.4138 8.10403C21.7887 8.50266 21.9903 9.0335 21.9747 9.58044V25.1045Z" fill="#24A5ED"/>
<path d="M25.3684 14.9874C25.2505 15.0946 25.1679 15.2351 25.1313 15.3901C25.0947 15.5452 25.1059 15.7077 25.1633 15.8564C25.5296 16.8228 25.5296 17.8899 25.1633 18.8564C25.0867 19.0506 25.0904 19.2674 25.1735 19.459C25.2567 19.6505 25.4126 19.8012 25.6068 19.8779C25.8011 19.9545 26.0179 19.9509 26.2094 19.8677C26.401 19.7845 26.5517 19.6286 26.6284 19.4344C27.1409 18.095 27.1409 16.6137 26.6284 15.2744C26.581 15.1565 26.5058 15.0518 26.4092 14.9694C26.3126 14.8869 26.1974 14.8291 26.0735 14.8009C25.9496 14.7727 25.8208 14.7749 25.698 14.8074C25.5752 14.8399 25.4621 14.9016 25.3684 14.9874Z" fill="#24A5ED"/>
<path d="M29.3239 12.7444C29.2659 12.6414 29.1854 12.5527 29.0885 12.4849C28.9915 12.4171 28.8807 12.372 28.7639 12.3528C28.6472 12.3336 28.5277 12.3408 28.4141 12.374C28.3006 12.4072 28.1959 12.4654 28.1079 12.5444C27.9751 12.6636 27.8871 12.8245 27.8584 13.0006C27.8296 13.1767 27.8619 13.3573 27.95 13.5124C28.6278 14.6914 28.9846 16.0275 28.9846 17.3874C28.9846 18.7474 28.6278 20.0835 27.95 21.2624C27.8619 21.4176 27.8296 21.5982 27.8584 21.7743C27.8871 21.9503 27.9751 22.1113 28.1079 22.2304C28.1959 22.3095 28.3006 22.3677 28.4141 22.4009C28.5277 22.4341 28.6472 22.4413 28.7639 22.4221C28.8807 22.4029 28.9915 22.3577 29.0885 22.29C29.1854 22.2222 29.2659 22.1335 29.3239 22.0304C30.1329 20.6171 30.5586 19.0169 30.5586 17.3884C30.5586 15.76 30.1329 14.1597 29.3239 12.7464V12.7444Z" fill="#24A5ED"/>
<path d="M6.47439 14.9878C6.3804 14.9021 6.26703 14.8405 6.14401 14.8083C6.02099 14.7761 5.892 14.7743 5.76809 14.8029C5.64419 14.8315 5.52909 14.8898 5.43265 14.9726C5.33621 15.0555 5.26129 15.1606 5.21438 15.2788C4.70198 16.6181 4.70198 18.0994 5.21438 19.4388C5.29625 19.6255 5.44732 19.7732 5.63583 19.8509C5.82434 19.9286 6.03564 19.9302 6.22531 19.8554C6.41497 19.7806 6.56824 19.6351 6.65292 19.4497C6.7376 19.2642 6.7471 19.0531 6.67941 18.8608C6.31308 17.8943 6.31308 16.8272 6.67941 15.8608C6.73776 15.7116 6.74941 15.5483 6.7128 15.3924C6.67619 15.2365 6.59302 15.0954 6.47439 14.9878Z" fill="#24A5ED"/>
<path d="M3.73596 12.542C3.64795 12.463 3.54334 12.4047 3.42981 12.3716C3.31627 12.3384 3.19673 12.3311 3.08002 12.3503C2.9633 12.3695 2.85236 12.4147 2.75543 12.4825C2.6585 12.5503 2.57803 12.639 2.51996 12.742C1.71114 14.1554 1.28564 15.7556 1.28564 17.384C1.28564 19.0124 1.71114 20.6126 2.51996 22.026C2.57803 22.1291 2.6585 22.2177 2.75543 22.2855C2.85236 22.3533 2.9633 22.3985 3.08002 22.4177C3.19673 22.4369 3.31627 22.4296 3.42981 22.3964C3.54334 22.3633 3.64795 22.305 3.73596 22.226C3.86877 22.1069 3.95686 21.9459 3.9856 21.7698C4.01433 21.5938 3.98201 21.4132 3.89398 21.258C3.21602 20.0791 2.85919 18.7429 2.85919 17.383C2.85919 16.0231 3.21602 14.6869 3.89398 13.508C3.9815 13.353 4.01353 13.1729 3.9848 12.9972C3.95608 12.8216 3.86829 12.661 3.73596 12.542Z" fill="#24A5ED"/>
<rect x="13" y="10" width="5" height="2" fill="#24A5ED"/>
<rect x="13" y="14" width="7" height="2" fill="#24A5ED"/>
<rect x="13" y="18" width="7" height="2" fill="#24A5ED"/>
<rect x="13" y="22" width="3" height="2" fill="#24A5ED"/>  
`,
];
