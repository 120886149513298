export const GET_PRODUCTS_PENDING = 'GET_PRODUCTS_PENDING';
export const GET_PRODUCTS_FULFILLED = 'GET_PRODUCTS_FULFILLED';
export const GET_PRODUCTS_REJECTED = 'GET_PRODUCTS_REJECTED';
export const GET_PRODUCT_BY_ID_PENDING = 'GET_PRODUCT_BY_ID_PENDING';
export const GET_PRODUCT_BY_ID_FULFILLED = 'GET_PRODUCT_BY_ID_FULFILLED';
export const GET_PRODUCT_BY_ID_REJECTED = 'GET_PRODUCT_BY_ID_REJECTED';
export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING';
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED';
export const DELETE_PRODUCT_REJECTED = 'DELETE_PRODUCT_REJECTED';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_HISTORY = 'GET_PRODUCTS_HISTORY';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
