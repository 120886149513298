import {
  GET_ALERT_TYPES_PENDING,
  GET_ALERT_TYPES_FULFILLED,
  GET_ALERT_TYPES_REJECTED,
} from './AlertActionTypes';
const initialState = {
  alertTypes: [],
};

const getAlertTypesPending = (state) => {
  return {
    ...state,
  };
};

const getAlertTypesSuccess = (state, action) => {
  return {
    ...state,
    alertTypes: action.payload.data.data,
  };
};

const getAlertTypesFail = (state) => {
  return {
    ...state,
    alertTypes: [],
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALERT_TYPES_PENDING:
      return getAlertTypesPending(state, action);

    case GET_ALERT_TYPES_FULFILLED:
      return getAlertTypesSuccess(state, action);

    case GET_ALERT_TYPES_REJECTED:
      return getAlertTypesFail(state, action);

    default:
      return {
        ...state,
      };
  }
}
