import {
  GET_COUNTRY_FULFILLED,
  GET_STATE_FULFILLED,
  GET_CITY_FULFILLED,
  SET_TAB_DETAILS,
  GET_TIMEZONE_FULFILLED,
  SET_SIDEBAR,
  SET_OPEN_SIDE_MENUS,
  GET_STATE_PENDING,
  GET_STATE_REJECTED,
} from './Actiontypes';
const initialState = {
  processing: false,
  stateProcessing: false,
  countryData: [],
  stateData: [],
  cityData: [],
  tabDetail: {},
  timezoneData: [],
  sidebarShow: true,
  openSideMenus: [],
};

const getCountrySuccess = (state, action) => {
  return {
    ...state,
    countryData: action.payload.data.data.records,
  };
};

const getStatePending = (state) => {
  return {
    ...state,
    stateProcessing: true,
    stateData: [],
  };
};

const getStateFail = (state) => {
  return {
    ...state,
    stateProcessing: false,
  };
};

const getStateSuccess = (state, action) => {
  return {
    ...state,
    stateData: action.payload.data.data.records,
    stateProcessing: false,
  };
};

const getCitySuccess = (state, action) => {
  return {
    ...state,
    cityData: action.payload.data,
  };
};

const getTimezoneSuccess = (state, action) => {
  return {
    ...state,
    timezoneData: action.payload.data.data,
  };
};

const setTabDetails = (state, action) => {
  return {
    ...state,
    tabDetail: action.payload,
  };
};

const setSideBar = (state, action) => {
  return {
    ...state,
    sidebarShow: action.payload,
  };
};
const setOpenSideMenus = (state, action) => {
  return {
    ...state,
    openSideMenus: action.payload,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRY_FULFILLED:
      return getCountrySuccess(state, action);

    case GET_STATE_PENDING:
      return getStatePending(state);

    case GET_STATE_REJECTED:
      return getStateFail(state);

    case GET_STATE_FULFILLED:
      return getStateSuccess(state, action);

    case GET_CITY_FULFILLED:
      return getCitySuccess(state, action);

    case SET_TAB_DETAILS:
      return setTabDetails(state, action);

    case GET_TIMEZONE_FULFILLED:
      return getTimezoneSuccess(state, action);

    case SET_SIDEBAR:
      return setSideBar(state, action);

    case SET_OPEN_SIDE_MENUS:
      return setOpenSideMenus(state, action);

    default:
      return {
        ...state,
      };
  }
}
