import { combineReducers } from 'redux';
import loginReducer from '../views/pages/login/LoginReducer';
import UserReducer from '../views/user/UserReducer';
import clientReducer from '../views/client/ClientReducer';
import gatewayReducer from '../views/gateway/GatewayReducer';
import deviceReducer from '../views/device/DeviceReducer';
import propertyReducer from '../views/property/PropertyReducer';
import zoneReducer from '../views/zone/ZoneReducer';
import alertReducer from '../views/alert/AlertReducer';
import alertConfigurationReducer from '../views/alertConfiguration/AlertConfigurationReducer';
import dashboardReducer from '../views/dashboard/DashboardReducer';
import projectReducer from '../views/project/ProjectReducer';
import productReducer from '../views/product/ProductReducer';
import userGroupReducer from '../views/userGroup/UserGroupReducer';
import commonReducer from '../common/reducer';
import annotationreducer from '../views/annotations/annotationReducer';
import OtaUpdateReducer from '../views/otaUpdate/OtaUpdateReducer';
import NoteReducer from '../views/note/NoteReducer';
import { RESET_REDUX, EMPTY_STORE_STATE } from '../common/Actiontypes';
import ModelReducer from '../views/cameraModel/ModelReducer';
import EndDeviceReducer from '../views/endDevices/EndDeviceReducer';
import GraphReducer from '../views/dashboard/graphs/GraphReducer';
import FuotaReducer from '../views/fuota/FuotaReducer';
import SensorInventoryReducer from '../views/sensorInventory/SensorInventoryReducer';
import OrderReducer from '../views/orders/OrderReducer';

const appReducer = combineReducers({
  login: loginReducer,
  user: UserReducer,
  client: clientReducer,
  gateway: gatewayReducer,
  device: deviceReducer,
  property: propertyReducer,
  commonData: commonReducer,
  zone: zoneReducer,
  alert: alertReducer,
  dashboard: dashboardReducer,
  annotation: annotationreducer,
  otaUpdate: OtaUpdateReducer,
  project: projectReducer,
  product: productReducer,
  userGroup: userGroupReducer,
  model: ModelReducer,
  endDevices: EndDeviceReducer,
  note: NoteReducer,
  graph: GraphReducer,
  fuotaJob: FuotaReducer,
  alertConfiguration: alertConfigurationReducer,
  sensor: SensorInventoryReducer,
  order: OrderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === EMPTY_STORE_STATE) {
    state = {};
  }
  if (action.type === RESET_REDUX) {
    if (action.payload.reducerKey && action.payload.key) {
      const { reducerKey, key, value } = action.payload;
      state = {
        ...state,
        [reducerKey]: { ...state[reducerKey], [key]: value },
      };
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
