export const propertiesIcon = [
  '52 70',
  `
<svg width="52" height="70" viewBox="0 0 52 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M62.3438 65.3363V32.915L66.4993 37.0418L69.3992 34.1614L35 0L0.600815 34.1614L3.50075 37.0418L7.65625 32.915V65.3363H0V69.4095H70V65.3363H62.3438ZM11.7578 28.8418L35 5.7603L58.2422 28.8418V65.3363H11.7578V28.8418Z" fill="#72D9D0"/>
<path d="M46.6048 26.3889C43.5062 23.3117 39.3864 21.6172 35.004 21.6172C30.6215 21.6172 26.5018 23.3117 23.4032 26.3889C17.0062 32.7416 17.0062 43.0779 23.4032 49.4306L35.004 60.9512L46.6048 49.4306C53.0017 43.0779 53.0017 32.7416 46.6048 26.3889ZM43.7048 46.5502L35.004 55.1909L26.3031 46.5502C21.5057 41.7859 21.5057 34.0336 26.3031 29.2693C28.6273 26.9612 31.7174 25.6904 35.004 25.6904C38.2906 25.6904 41.3806 26.9612 43.7048 29.2693C48.5023 34.0336 48.5023 41.7859 43.7048 46.5502Z" fill="#72D9D0"/>
<path d="M35 30.4424C30.8536 30.4424 27.4805 33.7922 27.4805 37.9099C27.4805 42.0272 30.8536 45.3775 35 45.3775C39.1464 45.3775 42.5195 42.0272 42.5195 37.9099C42.5195 33.7922 39.1464 30.4424 35 30.4424ZM35 41.3043C33.1153 41.3043 31.582 39.7816 31.582 37.9099C31.582 36.0383 33.1153 34.5156 35 34.5156C36.8847 34.5156 38.418 36.0383 38.418 37.9099C38.418 39.7816 36.8847 41.3043 35 41.3043Z" fill="#72D9D0"/>
</svg>
  
`,
];
