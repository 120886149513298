export const NEXT_TAB = 'NEXT_TAB';
export const PREVIOUS_TAB = 'PREVIOUS_TAB';
export const RESET_FORM = 'RESET_FORM';
export const SAVE_FORM = 'SAVE_FORM';
export const ADD_JOB = 'ADD_JOB';
export const ADD_JOB_REJECTED = 'ADD_JOB_REJECTED';
export const ADD_JOB_FULFILLED = 'ADD_JOB_FULFILLED';
export const ADD_JOB_PENDING = 'ADD_JOB_PENDING';
export const GET_FUOTA_JOB_BY_ID_PENDING = 'GET_FUOTA_JOB_BY_ID_PENDING';
export const GET_FUOTA_JOB_BY_ID_FULFILLED = 'GET_FUOTA_JOB_BY_ID_FULFILLED';
export const GET_FUOTA_JOB_BY_ID_REJECTED = 'GET_FUOTA_JOB_BY_ID_REJECTED';
export const GET_FUOTA_JOB_BY_ID = 'GET_FUOTA_JOB_BY_ID';
