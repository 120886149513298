import { ENV_NAME } from '../utils/envVariables';
import { getClientId } from '../utils/getClient';
import { userGroupFields } from './clientConfig/clients-config';
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&*()_+{}<>?\|\'./`\*])/i; // eslint-disable-line
export const upperCaseRegex = /^(?=.*[A-Z])/;
export const lowerCaseRegex = /^(?=.*[a-z])/;
export const numberRegex = /^(?=.*[0-9])/;
export const onlyDigitRegex = /^[0-9]+$/;
export const specialCharacterRegex = /^(?=.*[!@#\$%\^&*()_+{}<>?\|\'./`\*])/; // eslint-disable-line
export const passwordLengthRegex = /^(?=.{6,})/i;
export const whiteSpaceRegex = /(\s)/g;
export const phoneNumberRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]+$/; // eslint-disable-line
export const zipCodeRegex = /^[0-9][0-9]{4,5}$/;
export const firmwareVersionRegex = /^\d+(\.\d+)+$/;
export const apiKeyRegex = /^\S*$/;
export const appEUIRegex = /^([0-9a-fA-F]){16}$/;
export const appKeyRegex = /^([0-9a-fA-F]){32}$/;
export const spaceRegex = /\s/;
export const alphaNumericRegex = /^[a-z\d\-_\s]+$/i;
export const imageRegex = /\.(jpg|jpeg|png|JPG|PNG|JPEG)$/;
export const ipRegex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
export const checkSpaceAndDotRegex = new RegExp(
  new RegExp(/\./g).source + '|' + new RegExp(/\s/).source,
);
export const COLLOQUIAL_AREA = 'colloquial_area';
export const NEIGHBORHOOD = 'neighborhood';
export const SUBLOCALITY_LEVEL_1 = 'sublocality_level_1';
export const SUBLOCALITY_LEVEL_2 = 'sublocality_level_2';
export const LOCALITY = 'locality';
export const MAX_FILES = 10;
export const MAX_FUOTA_JOB = 1;
export const SAMPLE_CSV_URL =
  'https://nps-resources.s3.amazonaws.com/public/client/OISAP/sample/sample_upload.csv';
export const SAMPLE_ZAK_CSV_URL =
  'https://oxtech-resources-dev.s3.us-east-1.amazonaws.com/public/zflo/zflo-inventory-csv.csv';
//end device types
export const DEVICE_TYPE = {
  CAMERA: 'camera',
  TRACKER: 'shopper_traffic_counter',
  MULTISENSE_HUB: 'multisense_hub',
  VALVE_CONTROLLER: 'valve_controller',
  GATE_SENSOR: 'gate_sensor',
  TEMPERATURE_SENSOR: 'temperature_sensor',
  KEG: 'keg',
  SMART_ROOM_SENSOR: 'smart_room_sensor',
  Residential_Methane_Detector: 'residential_methane_detector',
};

export const WATERCOP_DEVICE_TYPE = {
  PUCK: 'puck',
};
export const AATA_DEVICE_TYPE = {
  treeTiltSensor: 'tree_tilt_sensor',
  alarmSiren: 'alarm_siren',
};
export const CC_DEVICE_TYPE = {
  gateSensor: 'gate_sensor',
  temperatureSensor: 'temperature_sensor',
};
export const BA_PROPERTY_TYPE = {
  bar: 'bar',
  brewery: 'brewery',
};
export const S8CGP_LNS_TYPE = {
  aws: 'AWS',
  tti: 'TTI',
  senat: 'SENET',
};
//job status
export const JOB_STATUS = {
  SCHEDULED: 'Scheduled',
  INPROGRESS: 'In Progress',
  FAILED: 'Failed',
  SUCCESS: 'Success',
  CANCELLED: 'Cancelled',
  UNKNOWN: 'Unknown',
  TIMEOUT: 'Timeout',
};
export const OTA_JOB_STATUS = {
  SCHEDULED: 'Scheduled',
  INPROGRESS: 'In Progress',
  PENDING: 'pending',
  FAILED: 'Failed',
  SUCCESS: 'Success',
  REJECTED: 'rejected',
  CANCELLED: 'Cancelled',
  UNKNOWN: 'Unknown',
  TIMEOUT: 'Timeout',
  COMPLETED: 'Completed',
  ADDED: 'Added',
  CANCELLATION_INPROGRESS: 'Cancellation_in_progress',
  DELETION_INPROGRESS: 'deletion_in_progress',
  DELETED: 'deleted',
  SETUP_FAIL: 'Setup Failed',
  REMOVED: 'removed',
};

//Roles & Types
export const USER_ROLE_READONLY = 'read_only';
export const USER_TYPE_OXIT = 'oxit';
export const USER_TYPE_CLIENT = 'client';
export const OXTECH_USER = 'OxTech';
export const WATERCOP_USER = 'Watercop';
export const DART_DISPLAY_USER = 'DART Displays';
export const OIP_USER = 'Optical Inventory Solutions';
export const TACO_USER = 'Taco';
export const Z_TRACKER_USER = 'ZakTrak';
export const AATA_USER = 'AATA';
export const BA_USER = 'Keg Monitoring Platform';
export const NDS_USER = 'NDS';
export const S8CGP_7_USER = '8 Channel Gateway';
export const OHA_USER = 'OxTech Helium';
export const NC_USER = 'New Cosmos';
export const USER_TYPE_CUSTOMER = 'customer';
export const DEFAULT_PLACEID = 'ChIJ_cT5YESaVogRyfglvYd9Z8E';
export const GOOGLE_MAPS_BASE_URL = 'https://www.google.com/maps/embed/v1';
export const DEV_BASE_URL = 'https://api-dev.oxtech.com';
export const AWS_REGION = 'us-east-1';
export const REACT_GOOGLE_MAPS_BASE_URL =
  'https://maps.googleapis.com/maps/api/js';
export const ASSIGNED_USERS = 'Assigned Users';
export const CROWN_CASTLE_USER = 'CCLTM';
export const OMNI_ID_USER = 'OISAP';
export const OMNI_ADMIN_USER = 'Omni ID Admin';
export const OMNI_CUSTOM_USER = 'Omni ID Customer';
export const NDS_ADMIN_USER = 'NDS Client Admin';
export const isNDSCustomerAdmin = 'NDS Customer Admin';

//UserGroupManagement
// client id to which userGroupManagement is available
export const getUserGroupManagement = (id) => {
  const { WATERCOP, AATA, BA } = getClientId();
  const USER_GROUP_MANAGEMENT_CLIENT = [WATERCOP, AATA, BA];
  const userGroupId = USER_GROUP_MANAGEMENT_CLIENT.filter((v) => v.id === id);
  if (userGroupId.length > 0) {
    return {
      userGroupStatus: userGroupFields[userGroupId?.[0]?.name],
      Id: userGroupId?.[0]?.id,
    };
  }
  return {
    userGroupStatus: null,
    Id: null,
  };
};
export const getSampleCSV = () => {
  switch (ENV_NAME) {
    case 'dev':
      return 'https://oxtech-resources-dev.s3.amazonaws.com/sensor_details/sample.csv';
    case 'qa':
      return 'https://oxtech-resources-qa.s3.amazonaws.com/sensor_details/sample.csv';
    case 'prod':
      return 'https://nps-resources.s3.amazonaws.com/sensor_details/sample.csv';
  }
};
//Property types for BA
export const BAR_OR_BREWERY_TYPE = [
  {
    label: 'Bar',
    value: 'bar',
  },
  {
    label: 'Brewery',
    value: 'brewery',
  },
];

//Sidebar

//TABNAME
export const ROUTE_NAME = {
  DASHBOARD: 'dashboard',
  CLIENT: 'client',
  CUSTOMER: 'Customer',
  PROPERTY: 'property',
  ZONE: 'zone',
  GATEWAY: 'gateway',
  DEVICE: 'device',
  TRACKER: 'tracker',
  ALERT: 'alert',
  ANNOTATION: 'annotation',
  ACTIVITY: 'activity',
  LABEL: 'label',
  SETTING: 'setting',
  PROJECT: 'project',
  USER: 'user',
  USER_GROUP: 'user_group',
  FUOTA_IMAGE_LIBRARY: 'FuotaImageLibrary',
  ADD_FUOTA_IMAGE_LIBRARY: 'add_fuota_image_library',
  END_DEVICES: 'end-devices',
  PRODUCT: 'product',
  ANNOTATION_TYPE: 'annotation_type',
  CREDENTIAL: 'credential',
  DIAGNOSIS: 'diagnosis',
  NOTE: 'note',
  DEVICE_TYPE: 'fuota_device_type',
  LNS_CONFIGURATION: 'lns_config',
  LNS_CONFIGURATIONS: 'lns_config',
  SHIPMENT: 'shipment',
  INVENTORY: 'tracker',
};

export const ROUTE_MAP = [
  {
    name: 'dashboard',
    redirectTo: '/dashboard',
  },
  {
    name: 'client',
    redirectTo: '/clients',
  },
  {
    name: 'property',
    redirectTo: '/properties',
  },
  {
    name: 'zone',
    redirectTo: '/zones',
  },
  {
    name: 'gateway',
    redirectTo: '/gateways',
  },
  {
    name: 'device',
    redirectTo: '/devices',
  },
  {
    name: 'tracker',
    redirectTo: '/trackers',
  },
  {
    name: 'alert',
    redirectTo: '/alerts',
  },
  {
    name: 'inventory',
    redirectTo: '/inventories',
  },
  {
    name: 'annotation',
    redirectTo: '/annotations',
  },
  {
    name: 'label',
    redirectTo: '/projects-overview',
  },
  {
    name: 'project',
    redirectTo: '/projects',
  },
  {
    name: 'user',
    redirectTo: '/users',
  },
  {
    name: 'user_group',
    redirectTo: '/user-groups',
  },
  {
    name: 'product',
    redirectTo: '/products',
  },
  {
    name: 'annotation_type',
    redirectTo: '/annotation-types',
  },
  {
    name: 'credential',
    redirectTo: '/credential',
  },
  {
    name: 'diagnosis',
    redirectTo: '/diagnosis',
  },
  {
    name: 'end_device',
    redirectTo: '/end-devices',
  },
  {
    name: 'monitor_inventory',
    redirectTo: '/monitorinventory',
  },
  {
    name: 'shipment',
    redirectTo: '/shipments',
  },
];

//widgets
export const STATCARDOPTION = () => [
  {
    name: 'online_trackers',
    displayName: 'Active Trackers',
    icon: 'Sigma',
    color: 'gradientPurple',
  },
  {
    name: 'last_hour',
    displayName: 'Tracker Alerts in Last 1 hour',
    icon: 'programmingTimeIcon',
    color: 'gradientYellow',
  },
  {
    name: 'last_6_hour',
    displayName: 'Tracker Alerts in Last 6 hour',
    icon: 'programmingTimeIcon',
    color: 'gradientDarkRed',
  },
  {
    name: 'last_12_hour',
    displayName: 'Tracker Alerts in Last 12 hour',
    icon: 'programmingTimeIcon',
    color: 'gradientOrange',
  },
  {
    name: 'last_24_hour',
    displayName: 'Tracker Alerts in Last 24 hour',
    icon: 'programmingTimeIcon',
    color: 'gradientYellow',
  },
  {
    name: 'tracker_errors',
    displayName: 'Trackers Errors Today',
    icon: 'qualityIssueIcon',
    color: 'gradientGreen',
  },
  {
    name: 'offline_trackers',
    displayName: 'Trackers Offline',
    icon: 'blockedIcon',
    color: 'gradientDarkBlue',
  },
  {
    name: 'tracker_inventory',
    displayName: 'Track Inventory',
    icon: 'diagnosisIcon',
    color: 'gradientDarkGreen',
  },
];

//Notifications
export const NOTIFICATION = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default',
};

//ALERT THRESHOLDS
export const ALERT_THRESHOLDS_RANGES = {
  MIN_HOURS: 1,
  MAX_HOURS: 24,
  MIN_TIMES: 1,
  MAX_TIMES: 24,
  MIN_BATTERY: 1,
  MAX_BATTERY: 50,
};
export const AATA_THRESHOLDS_RANGES = {
  min: 1,
  max: 180,
};

export const KEG_SENSOR_THRESHOLDS_RANGES = {
  min: 1,
  max: 100,
};

//Errors
export const ERRORS = {
  NETWORK_ERROR: 'Network Error.',
  SESSION_EXPIRED_ERROR: 'Your session has expired please login again.',
  MODEL_NAME: 'Model Name is required',
  DEVICE_NAME: 'Device Name is required',
  DEVICE_TYPE: 'Device Type is required',
  GATEWAY_TYPE: 'Gateway Type is required',
  TRACKER_NAME: 'Tracker Name is required',
  ZONE_NAME: 'Zone Name is required',
  SCHEDULE_AT: 'Date is required',
  TIME_ZONE: 'Time Zone is required',
  SHIPMENT_NAME: 'Shipment Name is required',
  HEARTBEAT_MARGIN: 'Heartbeat Margin is required',
  PROJECT_NAME: 'Project Name is required',
  PROPERTY_NAME: 'Property Name is required',
  DEVICE_ID: 'Device Id is required',
  LOCATION_NAME: 'Please enter a location name.',
  GATEWAY_NAME: 'Gateway Name is required',
  DESCRIPTION: 'Description is required',
  NAME: 'Name is required',
  NAME_SPACE: 'Name should not include spaces Or dot',
  FIRSTNAME: 'First name is required',
  FIRST_NAME_SPACING: 'First name cannot include leading and trailing spaces',
  LASTNAME: 'Last name is required',
  LAST_NAME_SPACING: 'Last name cannot include leading and trailing spaces',
  END_DEVICE: 'Programmable End Device is required',
  PROGRAMMER_TYPE: 'Programmer Type is required',
  PROGRAMMER_NAME: 'Programmer Name is required',
  FIRMWARE: 'Firmware is required',
  INVALID_FIRMWARE_VERSION: 'Please use numbers and dots for firmware version.',
  PACKAGE_NAME: 'Package Name is required',
  TRACKER_ID: 'Tracker Id is required',
  USER_ROLE: 'Role is required',
  CLIENTROLE: 'Client Role is required',
  CUSTOMERROLE: 'Customer Role is required',
  CUSTOMER: 'Customer is required',
  DEVICE_ERROR: 'Device Id is invalid',
  ADD_DEVICE_ERROR: 'The Dashboard was unable to add the device.',
  EDIT_DEVICE_ERROR: 'The Dashboard was unable to edit the device.',
  PRODUCT: 'Product is required',
  LATITUDE: 'Latitude is required',
  LONGITUDE: 'Longitude is required',
  EMAIL: 'User Email is required',
  INSTALLER_EMAIL: 'Email is required',
  COMPANY_NAME: 'Company Name is required',
  REQUIRED_PHONE_NUMBER: 'Phone number is required.',
  INVALID_PHONE_NUMBER_LENGTH: 'The length of phone number is invalid ',
  INVALID_EMAIL: 'Email is not valid',
  TYPE: 'User Type is required',
  ROLE: 'User Group is required',
  ADDRESS_LINE_1: 'Address 1 is required',
  OWNER: 'Please enter owner',
  ADDRESS: ' Please enter an address',
  COUNTRY: 'Please Select a Country',
  SITE_TIME_ZONE: 'Please select a timezone',
  COMPANY: 'Company Name is required',
  CITY: 'Please Enter a City Name.',
  STATE: 'Please Select a State.',
  ZIP: 'Please Enter a ZIP or Postal Code.',
  LOCATION_NUMBER: 'Please enter store number.',
  SITE_ID: 'Site ID is required',
  CLIENT_ID: ' Please select a Project.',
  USER_NAME: 'User email is required',
  USER_PROFILE_IMAGE: 'Unsupported Format',
  KEG_NUMBER: 'Keg number is required',
  PRODUCT_ID: 'Product Name is required',
  USER_NAME_PASSWORD: 'User name and Password are required ',
  PASSWORD: 'Password is required',
  PASSWORD_UPPERCASE: 'Password should contain at least one upper case letter',
  PASSWORD_LOWERCASE: 'Password should contain at least one lower case letter',
  PASSWORD_SPECIAL_CHARACTER:
    'Password should contain at least one special character',
  PASSWORD_DIGIT: 'Password should contain at least one digit',
  PASSWORD_MIN_LENGTH: ' Password should contain minimum 6 characters',
  WIFI_PASSWORD_MIN_LENGTH: ' Password should contain minimum 8 characters',
  PASSWORD_NOT_MATCH: 'Password does not match.',
  OLD_PASSWORD: 'Password should be different from current password',
  PASSWORD_REQUIREMENT:
    'Password should contain at least one uppercase character, lowercase character, number, and symbol.',
  ANNOTATION_TYPE: 'Annotation type required',
  ANNOTATION_DESCRIPTION: 'Annotation description required',
  TRACKER: 'Tracker Number is required',
  ASSIGNED_PROJECT:
    'Project is already assigned to a property so can not assigned to other.',
  ASSIGNED_PROPERTY:
    'Property is already assigned to a project so can not assigned to other.',
  USER_GROUP_NAME: 'User Group is required',
  USER_GROUP_CLIENT: 'Client is required',
  PRODUCT_ASSIGN: 'Client Id not associated to product',
  DEVICE_SCHEDULE: 'The Dashboard was unable to update device schedule.',
  SCHEDULE_NO_TIME: 'Please select at least one time option.',
  GEO_FENCE_RADIUS: 'Geo-fence radius is required',
  INVALID_GEO_FENCE_RADIUS: 'Please enter a valid Geo-fence radius',
  MOBILE_NUMBER: 'Mobile Number is required',
  PHONE_NUMBER: 'Phone Number is required',
  PHONE_NUMBER_MAX_LENGTH:
    'The phone number cannot be longer than 15 characters.',
  PHONE_NUMBER_MAX_LENGTH_20:
    'The phone number cannot be longer than 20 characters.',
  PHONE_NUMBER_MAX_LENGTH_10:
    'The phone number cannot be longer than 12 characters.',
  INVALID_PHONE_NUMBER: 'Please enter a valid Phone Number.',
  INVALID_MOBILE_NUMBER: 'Please enter a valid Mobile Number.',
  BATTERY_EMPTY: 'Value is required',
  BATTERY_RED_INVALID: 'Value should be less than black',
  BATTERY_BLACK_INVALID: 'Value must be greater than red',
  MODEL_DESCRIPTION: 'Model description is required',
  CHOOSE_FILE: 'File is required',
  LARGE_FILE: 'File is too large',
  MODEL_ERROR: 'Model Id is invalid',
  ADD_MODEL_ERROR: 'The Dashboard was unable to add the model.',
  EDIT_MODEL_ERROR: 'The Dashboard was unable to update the model.',
  OTA_IMAGE_DEVICE: 'Device is required',
  OTA_IMAGE_TARGET: 'Target is Required',
  OTA_IMAGE_FILES: 'File(s) is mandatory',
  OTA_IMAGE_FIRMWARE: 'Firmware Version is required',
  OTA_IMAGE_DESCRIPTION: 'Firmware Description is required',
  PROGRAMMER: 'Programmer is required',
  OTA_IMAGE: 'OTA image is required',
  LATITUDE_RANGE_ERROR: 'Latitude must be between -90 and 90',
  LONGITUDE_RANGE_ERROR: 'Longitude must be between -180 and 180',
  UPDATE_TYPE: 'Update Type is required',
  FIRMWARE_USED: 'Firmware Version is already taken',
  PROPERTY: 'Please select the property',
  STATUS: 'Please select the status',
  COMMON_WENT_WRONG: 'Something went wrong.',
  GATEWAY_ID: 'Gateway Id is required',
  TREE_ID: 'Tree Id is required',
  DBH: 'Diameter at Breast Height is required',
  TREE_HEIGHT: 'Height of Tree is required',
  TREE_TYPE: 'Tree Type is required',
  SAFE_ZONE_AREA: 'Safe Zone Area is required',
  INSTALLATION_DATE: 'Installation Date is required',
  ID: 'Id is required',
  sirenName: 'Siren Name is required',
  sirenId: 'Siren Id is required',
  installationDate: 'Installation Date is required',
  TITLE: 'Title is required.',
  PROPERTY_ID_NOTE: 'Property Id is missed. Go back and try again',
  GATE_OPEN_FOR: 'Gate Open for is Required',
  GATE_OPENED_AFTER: 'Gate Opened After is Required',
  GATE_OPEN_AFTER: 'Gate Open After is Required',
  GATE_OPENED: 'Gate Opened is Required',
  GATE_OPENED_INVALID: `Value must be in  ${ALERT_THRESHOLDS_RANGES.MIN_TIMES} to ${ALERT_THRESHOLDS_RANGES.MAX_TIMES}`,
  GATE_OPEN_FOR_INVALID: `Value must be in ${ALERT_THRESHOLDS_RANGES.MIN_HOURS} to ${ALERT_THRESHOLDS_RANGES.MAX_HOURS}`,
  LOW_BATTERY: 'Battery is required',
  LOW_BATTERY_INVALID: `Value must be in ${ALERT_THRESHOLDS_RANGES.MIN_BATTERY} to ${ALERT_THRESHOLDS_RANGES.MAX_BATTERY}`,
  GATE_LOW_BATTERY: `Low Battery is Required`,
  GATE_LOW_BATTERY_INVALID: `Value should be less than ${ALERT_THRESHOLDS_RANGES.MAX_BATTERY}`,
  BEER_NAME: 'Beer Name is required',
  LOW_TEMPRATURE_THRESHOLD: 'Low temperature threshold is required',
  HIGH_TEMPRATURE_THRESHOLD: 'High temperature threshold is required',
  END_DATE: 'End date cannot bef  ore start date',
  BEER_TYPE: 'Beer Type is required',
  BREWERY_NAME: 'Brewery Name is required',
  Upper_Y: 'Threshold for y is required',
  Upper_Z: 'Threshold for z  is required',
  THRESHOLD_INVALID: `Value must be in  ${AATA_THRESHOLDS_RANGES.min}° to ${AATA_THRESHOLDS_RANGES.max}°`,
  PERCENTAGE_INVALID: `Value must be in  ${KEG_SENSOR_THRESHOLDS_RANGES.min}% to ${KEG_SENSOR_THRESHOLDS_RANGES.max}%`,
  NUMBER_TYPE: 'Must be a valid number',
  ALARM_SIREN: 'Maximum 2 Sirens are allowed',
  SITE_NAME: 'Site Name is required',
  SITE_NAME_ID: 'Site (Name and ID) is required',
  TO_DATE: 'To Date is required',
  FROM_DATE: 'From Date is required',
  LNS_KEY: 'API key is required',
  LNS_KEY_INVALID: 'Max 80 characters are allowed',
  JOB_NAME: 'Job name is required',
  JOB_TYPE: 'Job Type is required',
  LNS_ID: 'LNS configuration is required',
  JOB_NAME_INVALID: 'Max 30 characters are allowed',
  MAX_1200: 'Maximum duration should be less than 1200 seconds',
  MAX_3600: 'Maximum gap should be less than 3600 seconds',
  MAX_1209600: 'Maximum duration should be less than 1209600 seconds',
  MAX_200: 'Maximum value should be less than 200 feet',
  FUOTA_IMAGE: 'FUOTA Image is required',
  CONTRACT_ID: 'Contract ID is required',
  CONTRACT_ID_INVALID: 'Max 10 characters are allowed',
  SCHEDULE_JOB: 'Schedule Job is required',
  HOURS: 'Hours is required',
  MINUTES: 'Minutes is required',
  FRAGMENTATION: 'Fragmentation is required',
  REDUNDANCY: 'Redundancy is required',
  REGION: 'Region is required',
  TX_CHANNEL: 'TX channel is required',
  TX_DATA_RATE: 'TX data rate is required',
  FUOTA_VERSION: 'FUOTA version is required',
  MULTI_PACKAGE_SUPPORT: 'Multi package support is required',
  DEVICE_CLASS: 'Device class is required',
  DEVICE_LIST: 'Device list is required',
  TAG: 'Tag is required',
  INVALID_NAME: 'Max 30 characters are allowed',
  INVALID_DEVICE_ID: 'Max 20 characters are allowed',
  INVALID_DEVICE_ID_30: 'Max 30 characters are allowed',
  INVALID_DEVICE_ID_16: 'Max 16 characters are allowed',
  APP_EUI: 'App EUI is required',
  APP_EUI_INVALID_LENGTH: 'Max 100 characters are allowed',
  APP_EUI_INVALID: 'App EUI must be hexadecimal string',
  DESCRIPTION_INVALID: 'Descriptor must be hexadecimal string',
  LNS_KEY_INVALID_FORMAT: 'Space is not allowed',
  POSITIVE_NUMBER_INVALID: 'Contract ID must be a positive number',
  CUSTOMER_NAME: 'Customer Name is required',
  DELIVERY_SERVICE: 'Delivery Service is required',
  TRACKING_NUMBER: 'Tracking Number is required',
  CUSTOMER_EXISTS: 'Customer Name already in use',
  OWNER_EXISTS: 'Owner Name already in use',
  CUSTOMER_NAME_LIMIT: 'Character limit upto 50 character only',
  MESSAGE: 'Message is required',
  INVALID_SITE_NAME: 'Site name must be in 30 character',
  INVALID_SITE_ID: 'Site ID must be with in 8 digits',
  NO_LOCATION: 'No locations to display',
  PORTAL_URL: 'Enter Portal URL',
  APP_KEY: 'App Key is required',
  MAX_16: 'Max 16 characters are allowed',
  APP_EUI_NOT_VALID: 'App EUI is not valid',
  APP_KEY_NOT_VALID: 'App Key is not valid',
  DEVICE_ID_NOT_VALID: 'Device ID is not valid',
  INVALID_APP_KEY_32: 'Max 32 characters are allowed',
  DEVICE_EUI: 'Device EUI is required',
  DEVICE_SERIAL_NUMBER: 'Device Serial Number is required',
  DEVICE_EUI_NOT_VALID: 'Device EUI is not valid',
  ENROLMENT_NUMBER: 'Enrollment Number is required',
  ENROLMENT_NUMBER_15: 'Max 15 characters are allowed',
  INVALID_ENROLMENT_NUMBER: 'Enrollment Number is not valid',
  KITCHEN_LOCATION: 'Kitchen Location is required',
  ALPHA_NUMERIC:
    'Value should be alphanumeric cannot contain special character',
  JOIN_EUI: 'Join EUI is required',
  INVALID_JOIN_EUI: 'Join EUI is not valid',
  TOKEN: 'Token is required',
  INVALID_TOKEN: 'Token is not valid',
  MAX_6: 'Max 6 Numbers are allowed',
  CODE_TYPE: 'Only digits are allowed in verification code',
  CODE: 'Verification code is required',
  SERIAL_NUM: 'Serial Number is required',
  MAX_15: 'Max 15 characters are allowed',
  MAX_30: 'Max 30 characters are allowed',
  MAX_40: 'Max 40 characters are allowed',
  MODEL: 'Model is required',
  ISM_BAND: 'ISM Band is required',
  HEIGHT: 'Height is required',
  Radius: 'Radius is required',
  ZERO_HEAD: 'Value should not start with zero',
  GATEWAY_EUI: 'Gateway EUI is required',
  INVALID_GATEWAY_EUI: 'Invalid Gateway EUI',
  File_Name: 'Space is not allowed in file name. Rename the file and try again',
  MAX_5: 'Max 5 digits are allowed',
  INVALID_ZIP: 'Invalid Zip code',
  INVALID_FILE: 'Invalid File format',
  QUANTITY: 'Quantity is required',
  PHONE_NUMBER_MIN_LENGTH: 'The phone number cannot be  less than 10  digits',
  OWNER_REQ: 'Owner is required',
  UPLOAD_VALID_IMAGE: 'Please upload valid image',
  LNS_URL: 'LNS URL is required',
  CUPS_URL: 'CUPS URL is required',
  LNS_TRUST: 'LNS  Certificate is required',
  CUPS_TRUST: 'CUPS  Certificate is required',
  PRIVATE_KEY: 'Private Key file is required',
  PEM_FILE: 'Gateway Certificate is required',
  TTI_URL: 'TTI URL is required',
  LNS_API_KEY: 'LNS API key  is required',
  CUPS_API_KEY: 'CUPS API key  is required',
  TTI_CERT: 'TTI Certificate is required',
  SENET_CERT: 'Senet Certificate is required',
  SENET_URL: 'Senet URL is required',
  MEDIUM: 'Medium is required',
  HEARTBEAT: 'Heartbeat Interval is required',
  DIAGNOSTIC: 'Diagnostic Interval is required',
  POSITION: 'Position Interval is required',
  GATEWAY_PROTOCOL: 'Gateway Protocol is required',
  PRIMARY_NETWORK: 'Primary Network Type is required',
  SECONDARY_NETWORK: 'Secondary Network Type is required',
  THIRD_NETWORK: 'Third Network Type is required',
  INTERVAL_MIN_SEC: 'Interval value cannot be less than 30 ',
  INTERVAL_MAX_SEC: 'Interval value cannot be greater than 28800',
  INTERVAL_MIN_MINUTE: 'Interval value cannot be less than 0.5 ',
  INTERVAL_MAX_MINUTE: 'Interval value cannot be greater than 480',
  INTERVAL_MIN_HOUR: 'Interval value cannot be less than 0.0083 ',
  INTERVAL_MAX_HOUR: 'Interval value cannot be greater than 8',
  SSID: 'Wi-Fi Name is required',
  WIFI_PASSWORD: 'Wi-Fi Password is required',
  WIFI_SECURITY: 'Wi-Fi Security is required',
  DHCP: 'Ethernet IP Settings is required',
  IP: 'IP Address is required',
  GW: 'Default gateway is required',
  P_DNS: 'Primary DNS is required',
  S_DNS: 'Secondary DNS is required',
  NET_MASK: 'Subnet mask is required',
  SIM_TYPE: 'Sim Type is required',
  APN: 'APN is required',
  PIN: 'Pin is required',
  USER: 'User Name is required',
  SIM_PWD: 'Password is required',
  NETWORK_PRIORITY: 'Network Priority should be unique',
  SIM_PREFERENCE: 'Sim Preference is required',
  INVALID_IP: 'Invalid IP Address',
  INVALID_GW: 'Invalid Default Gateway',
  INVALID_SUBNET: 'Invalid Subnet Mask',
  INVALID_P_DNS: 'Invalid Primary DNS',
  INVALID_S_DNS: 'Invalid Secondary DNS',
  IP_TYPE: 'IP Type is required',
  DIAL: 'Dial is required',
  DATA_POINT_ID: 'Data point id is required',
  LOG_ITEMS: 'Log items is required',
  READ_LOG: 'Read log is required',
};

// ALERT_PRIORITY
export const ALERT_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

//PROGRAMMER_TYPES
export const PROGRAMMER_TYPES = [
  { value: 'linux', label: 'Linux' },
  { value: 'raspberryPi', label: 'Raspberry Pi' },
  { value: 'windows', label: 'Windows' },
];

//WARNINGS

export const WARNINGS = {
  DEVICE_SCHEDULE_WILL_PENDING:
    'Schedule will be pending until device accepts.',
  DEVICE_SCHEDULE_IS_PENDING: 'Last schedule is pending until device accepts.',
  VALVE_STATUS_CHANGE:
    'Changing valve status will take some time to update until device responds.',
  VALVE_STATUS_SUCCESS:
    'Command sent to device but it will take some time to update until device responds.',
  DEVICE_STATUS_PENDING:
    'Command sent to device but it will take some time to update until device responds.',
};

//END_DEVICES_TYPES
export const END_DEVICE_TYPES = [
  {
    value: 'End-device 1',
    label: 'End-device 1',
  },
  {
    value: 'End-device 2',
    label: 'End-device 2',
  },
  {
    value: 'End-device 3',
    label: 'End-device 3',
  },
  {
    value: 'End-device 4',
    label: 'End-device 4',
  },
  {
    value: 'End-device 5',
    label: 'End-device 5',
  },
];

export const DAY_LIGHT_SAVING_TIME_OFFSET = [
  -2, -3, -4, -5, -6, -7, -8, -9, -10, 1, 2, 3, 4, 6, 8, 10, 11, 12, 8.45, 9.3,
  10.3, -2.3, 12.45,
];
export const EDIT = 'Edit';
export const ADD = 'Add';
export const TABLE_LIST = [
  'alert',
  'alertDashboard',
  'annotation',
  'annotationType',
  'assignedProperty',
  'beerConsumption',
  'client',
  'clientAssignedUser',
  'clientCustomer',
  'clientDevice',
  'clientGateway',
  'clientProperty',
  'clientZone',
  'credential',
  'dashboard',
  'device',
  'deviceDetailAlertThreshold',
  'deviceDetailZone',
  'deviceHistory',
  'deviceUserTable',
  'end_device',
  'event',
  'fuotaImagelibrary',
  'gateHistory',
  'gateway',
  'gatewayAlert',
  'gatewayDevice',
  'jobDetailDevice',
  'lnsConfiguration',
  'model',
  'omniDashboard',
  'otaImageLibrary',
  'otaUpdate',
  'photo',
  'photos',
  'product',
  'productBarServingBeerTable',
  'programmingTime',
  'project',
  'projectLocation',
  'projectOverview',
  'projectProduct',
  'projectStoreLabel',
  'projectStoreLocation',
  'property',
  'propertyAlertTable',
  'propertyDeviceTable',
  'propertyGatewayTable',
  'propertyHistory',
  'propertyNoteTable',
  'propertyUserTable',
  'propertyZoneTable',
  'snooze',
  'temperatureHistory',
  'tracker',
  'zakTrackerDashboardTable',
  'user',
  'userGroup',
  'zone',
  'zoneAssignedUser',
  'zoneDevice',
  'zoneGateway',
  'sensorInventory',
  'order',
  'Inventory',
  'company',
  'deviceAlertTable',
  'deviceMethaneHistoryTable',
  'customer',
];
//Gateway Options
export const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
export const modelOptions = [
  {
    value: 'TEKTELIC_KONA_MICRO_INDOOR',
    label: 'Tektelic KONA Micro Indoor',
  },
];
export const ismBandOptions = [
  {
    label: 'US902-928',
    value: 'US902-928:Default',
  },
];

export const LNS_OPTIONS = [
  {
    label: 'AWS',
    value: 'AWS',
  },
  {
    label: 'TTI',
    value: 'TTI',
  },
  {
    label: 'Senet',
    value: 'SENET',
  },
];
export const MEDIUM_OPTIONS = [
  {
    label: 'MQTT',
    value: 'MQTT',
  },
  {
    label: 'HTTP',
    value: 'HTTP',
  },
  {
    label: 'Both',
    value: 'BOTH',
  },
];
export const NETWORK_TYPE_OPTIONS = [
  {
    label: 'Wi-Fi',
    value: 'WIFI',
  },
  {
    label: 'Cellular',
    value: 'CELLULAR',
  },
  {
    label: 'Ethernet',
    value: 'ETHERNET',
  },
];
export const NETWORK_MODE_OPTIONS = [
  {
    label: 'Basics Station',
    value: '1',
  },
  {
    label: 'Packet Forwarder',
    value: '0',
  },
];
export const DHCP_OPTIONS = [
  {
    label: 'DHCP',
    value: '0',
  },
  {
    label: 'STATIC',
    value: '1',
  },
];
export const SIM_OPTIONS = [
  {
    label: 'E-Sim',
    value: '1',
  },
  {
    label: 'Card Sim',
    value: '0',
  },
];
export const WIFI_SECURITY_OPTIONS = [
  {
    label: 'Open',
    value: '0',
  },
  {
    label: 'WEP',
    value: '1',
  },
  {
    label: 'WPA2',
    value: '3',
  },
  {
    label: 'WPA2_ENT',
    value: '4',
  },
  {
    label: 'WPA3',
    value: '5',
  },
];
export const IP_TYPE_OPTIONS = [
  {
    label: 'IPv4',
    value: 'IPv4',
  },
  {
    label: 'IPV6',
    value: 'IPV6',
  },
  {
    label: 'IPV4V6',
    value: 'IPV4V6',
  },
  {
    label: 'PPP',
    value: 'PPP',
  },
];
export const GATEWAY_COMMAND_TYPE = {
  heartbeat: 'HEARTBEAT_INTERVAL',
  diagnostic: 'DIAGNOSTIC_INTERVAL',
  position: 'POSITION_INTERVAL',
  networkPriority: 'NETWORK_PRIORITY',
  networkMode: 'NETWORK_MODE',
  wifiSetting: 'WIFI_CONFIGURATION',
  ethernetSetting: 'ETHERNET_CONFIGURATION',
  cellularSetting: 'CELLULAR_CONFIGURATION',
  simPrefrence: 'SIM_PREFERENCES',
  bleON: 'BLE_ON',
};

export const defaultCenter = {
  lat: 35.1317927,
  lng: -80.9579396,
};

export const CIDS = {
  RESET: 160,
  NETWORK_PRIORITY: 161,
  NETWORK_MODE: 162,
  HEARTBEAT_INTERVAL: 163,
  DIAGNOSTIC_INTERVAL: 164,
  POSITION_INTERVAL: 165,
  GNSS: 167,
  SIM_PREFERENCES: 168,
  ETHERNET_CONFIGURATION: 192,
  WIFI_CONFIGURATION: 193,
  CELLULAR_CONFIGURATION: 194,
  CREDETIALS_COMMAND_SENT: 96,
  CREDETIALS_COMMAND_SAVE: 97,
};

export const dataPointIdOptions = [
  {
    label: 'Flash Logs',
    value: 250,
  },
  {
    label: 'RAM Logs',
    value: 251,
  },
];

export const logTypeOptions = [
  { label: 'NO_OF_REBOOT', value: 0 },
  { label: 'LAST_RESTART_REASON', value: 1 },
  { label: 'TIME_SYNCED', value: 2 },
  { label: 'CURRENT_SIDEWALK_LINK_TYPE', value: 3 },
  { label: 'PREVIOUS_SIDEWALK_LINK_TYPE', value: 4 },
  { label: 'LAST_SIGNAL_STRENGHT', value: 5 },
  { label: 'LAST_SIGNAL_QUALITY', value: 6 },
  { label: 'FIRST_BOOT_TIMESTAMP', value: 9 },
  { label: 'LAST_SIDEWALK_JOIN_TIMESTAMP', value: 10 },
  { label: 'LAST_REBOOT_TIMESTAMP', value: 11 },
  { label: 'LAST_UPLINK_FAIL_TIMESTAMP', value: 12 },
  { label: 'REGISTRATION_FAIL_TIMESTAMP', value: 13 },
  { label: 'REGISTRATION_SUCCESS_TIMESTAMP', value: 14 },
  { label: 'DEREGISTRATION_SUCCESS_TIMESTAMP', value: 15 },
  { label: 'LOG_TIMESTAMP', value: 28 },
  { label: 'SW_APP_VERSION', value: 29 },
];

export const ramLogTypeValues = [3, 5, 6];

export const readLogs = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

export const CUSTOM_LOGOUT_EVENT = 'LOGOUT_FROM_THE_APP';
