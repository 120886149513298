import { sygnet } from './sygnet';
import { logo } from './logo';
import { leftarrow } from './left-arrow';
import { userPlaceholder } from './userplaceholder';
import { assignedUsersIcon } from './assignedUsersIcon';
import { customersIcon } from './customersIcon';
import { Shield } from './shield';
import { devicesIcon } from './devicesIcon';
import { gatewayIcon } from './gatewayIcon';
import { propertiesIcon } from './propertiesIcon';
import { assignedUsersfullIcon } from './assignedUsersfullIcon';
import { customersfullIcon } from './customersfullIcon';
import { devicesfullIcon } from './devicesfullIcon';
import { gatewayfullIcon } from './gatewayfullIcon';
import { propertiesfullIcon } from './propertiesfullIcon';
import { projectfullIcon } from './projectsfullIcon';
import { singleUserIcon } from './singleUserIcon';
import { Sigma } from './sigmaIcon';
import { NounDeliver } from './nounDeliver';
import { NounInTransit } from './nounInTransit';
import { rightSmallArrow } from './rightSmallArrow';
import { addDeviceIcon } from './addDeviceIcon';
import { dashboardIcon } from './dashboardIcon';
import { selectIcon } from './selectIcon';
import { selectArrowUpIcon } from './selectArrowUpIcon';
import { alertBell } from './alertBell';
import { chartBarIcon } from './chartBar';
import { clientBuilding } from './clientBuilding';
import { workImage } from './workImage';
import { logoNegative } from './logo-negative';
import { clientIcon } from './client';
import { firmwareIcon } from './firmwareIcon';
import { userIcon } from './userIcon';
import { searchIcon } from './searchIcon';
import { zoneIcon } from './zoneIcon';
import { annotationIcon } from './annotationIcon';
import { cameraIcon } from './cameraIcon';
import { settingIcon } from './settingIcon';
import { projectSettingIcon } from './projectSettingIcon';
import { annotationTypesIcon } from './annotationTypesIcon';
import { labelIcon } from './labelIcon.js';
import { productIcon } from './productIcon.js';
import { ViewUserGroupIcon } from './viewUserGroup.js';
import { ModifyUserGroupIcon } from './editUserGroup.js';
import { TrashIcon } from './trashIcon.js';
import { trackingIcon } from './trackingIcon.js';
import { alarmIcon } from './alarmIcon.js';
import { movedIcon } from './moved';
import { stickerIcon } from './stickerIcon';
import { remodelIcon } from './remodelIcon';
import { qualityIssueIcon } from './qualityIssueIcon';
import { blockedIcon } from './blockedIcon';
import { needsAnnotationIcon } from './needAnnotationIcon';
import { otaUpdatesIcon } from './otaUpdatesIcon';
import { programmingTimeIcon } from './programmingTimeIcon';
import { otaImageLibraryIcon } from './otaImageLibraryIcon';
import { credentialsIcon } from './credentialsIcon';
import { locationIcon } from './locationIcon';
import { tacoZonesIcon } from './tacoZonesIcon';
import { programmersIcon } from './programmersIcon';
import { diagnosisIcon } from './diagnosisIcon';
import { batteryIcon } from './batteryIcon';
import { barBreweriesIcon } from './barBreweriesIcon';
import { monitorInventoryIcon } from './monitorInventoryIcon';
import { notificationSettingsIcon } from './notificationSettings';
import { shipmentIcon } from './shipmentIcon';
import { alertThresholdsIcon } from './alertThresholdsIcon';
import { beerIcon } from './beerIcon';
import { activeJobIcon } from './activeJobIcon';
import { otherJobsIcon } from './otherJobsIcon';
import { completedJobsIcon } from './completedJobIcon';
import { deviceTypeIcon } from './deviceTypeIcon';
import { lnsConfigIcon } from './lnsConfigIcon';
import { notesIcon } from './noteIcon';
import { alertTypeIcon } from './alertTypeIcon';
import { userGroupIcon } from './userGroup';
import { multiUserIcon } from './multiUser';
import { orderIcon } from './orderIcon';
import { rebootDeviceIcon } from './rebootDeviceIcon';
import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
} from '@coreui/icons';
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons';
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilRouter,
  cilSpeaker,
  cilViewQuilt,
  cilDevices,
  cilMinus,
  cilPlus,
} from '@coreui/icons';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    leftarrow,
    userPlaceholder,
    assignedUsersIcon,
    barBreweriesIcon,
    customersIcon,
    devicesIcon,
    gatewayIcon,
    zoneIcon,
    tacoZonesIcon,
    programmersIcon,
    locationIcon,
    otaUpdatesIcon,
    programmingTimeIcon,
    otaImageLibraryIcon,
    diagnosisIcon,
    credentialsIcon,
    annotationIcon,
    cameraIcon,
    annotationTypesIcon,
    trackingIcon,
    chartBarIcon,
    NounDeliver,
    Sigma,
    Shield,
    NounInTransit,
    alarmIcon,
    labelIcon,
    productIcon,
    settingIcon,
    projectSettingIcon,
    propertiesIcon,
    assignedUsersfullIcon,
    customersfullIcon,
    devicesfullIcon,
    gatewayfullIcon,
    propertiesfullIcon,
    projectfullIcon,
    singleUserIcon,
    rightSmallArrow,
    alertBell,
    clientBuilding,
    clientIcon,
    addDeviceIcon,
    dashboardIcon,
    selectIcon,
    selectArrowUpIcon,
    workImage,
    logoNegative,
    firmwareIcon,
    userIcon,
    searchIcon,
    ViewUserGroupIcon,
    ModifyUserGroupIcon,
    TrashIcon,
    movedIcon,
    remodelIcon,
    stickerIcon,
    blockedIcon,
    needsAnnotationIcon,
    qualityIssueIcon,
    batteryIcon,
    monitorInventoryIcon,
    notificationSettingsIcon,
    alertThresholdsIcon,
    beerIcon,
    activeJobIcon,
    completedJobsIcon,
    otherJobsIcon,
    deviceTypeIcon,
    lnsConfigIcon,
    notesIcon,
    alertTypeIcon,
    userGroupIcon,

    shipmentIcon,
    rebootDeviceIcon,
    multiUserIcon,
    orderIcon,
  },
  {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowRight,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilGraph,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilScrubber,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning,
    cilRouter,
    cilSpeaker,
    cilViewQuilt,
    cilDevices,
    cilMinus,
    cilPlus,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
  },
);
