export const GET_COUNTRY_FULFILLED = 'GET_COUNTRY_FULFILLED';
export const GET_STATE_FULFILLED = 'GET_STATE_FULFILLED';
export const GET_STATE_PENDING = 'GET_STATE_PENDING';
export const GET_STATE_REJECTED = 'GET_STATE_REJECTED';
export const GET_CITY_FULFILLED = 'GET_CITY_FULFILLED';
export const GET_TIMEZONE_FULFILLED = 'GET_TIMEZONE_FULFILLED';
export const SET_TAB_DETAILS = 'SET_TAB_DETAILS';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_STATE = 'GET_STATE';
export const GET_CITY = 'GET_CITY';
export const RESET_REDUX = 'RESET_REDUX';
export const EMPTY_STORE_STATE = 'EMPTY_STORE_STATE';
export const GET_TIMEZONE = 'GET_TIMEZONE';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_OPEN_SIDE_MENUS = 'SET_OPEN_SIDE_MENUS';
