import { toast } from 'react-toastify';
import { NOTIFICATION } from '../constants/index';
export function pushNotification(msg, type, position, duration) {
  switch (type) {
    case NOTIFICATION.INFO:
      return toast.info(msg, {
        position: position
          ? toast.POSITION[position]
          : toast.POSITION.TOP_RIGHT,
        autoClose: duration ? duration : 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        newestOnTop: true,
      });
    case NOTIFICATION.SUCCESS:
      return toast.success(msg, {
        position: position
          ? toast.POSITION[position]
          : toast.POSITION.TOP_RIGHT,
        autoClose: duration ? duration : 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        newestOnTop: true,
      });

    case NOTIFICATION.WARNING:
      return toast.warn(msg, {
        position: position
          ? toast.POSITION[position]
          : toast.POSITION.TOP_CENTER,
        autoClose: duration ? duration : 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        newestOnTop: true,
      });
    case NOTIFICATION.ERROR:
      return toast.error(msg, {
        position: position
          ? toast.POSITION[position]
          : toast.POSITION.TOP_RIGHT,
        autoClose: duration ? duration : 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        newestOnTop: true,
      });

    case NOTIFICATION.DEFAULT:
      return toast(msg, {
        position: position
          ? toast.POSITION[position]
          : toast.POSITION.TOP_RIGHT,
        autoClose: duration ? duration : 2000,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        newestOnTop: true,
        hideProgressBar: true,
      });

    default:
      return toast.info(msg, {
        position: position
          ? toast.POSITION[position]
          : toast.POSITION.TOP_RIGHT,
        autoClose: duration ? duration : 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        newestOnTop: true,
      });
  }
}
