import { pushNotification } from '../../utils/notification';
import { ERRORS } from '../../constants/index';

import {
  GET_DEVICE_PENDING,
  GET_DEVICE_FULFILLED,
  GET_DEVICE_REJECTED,
  GET_END_DEVICE_TYPE_PENDING,
  GET_END_DEVICE_TYPE_FULFILLED,
  GET_END_DEVICE_TYPE_REJECTED,
  GET_PROGRAMMER_END_DEVICE_TYPE_PENDING,
  GET_PROGRAMMER_END_DEVICE_TYPE_FULFILLED,
  GET_PROGRAMMER_END_DEVICE_TYPE_REJECTED,
  GET_DEVICE_BY_ID_PENDING,
  GET_DEVICE_BY_ID_FULFILLED,
  GET_DEVICE_BY_ID_REJECTED,
  DELETE_DEVICE_PENDING,
  DELETE_DEVICE_FULFILLED,
  DELETE_DEVICE_REJECTED,
  UPDATED_DEVICE_PENDING,
  UPDATED_DEVICE_FULFILLED,
  UPDATED_DEVICE_REJECTED,
  ADD_END_DEVICE_PENDING,
  ADD_END_DEVICE_FULFILLED,
  ADD_END_DEVICE_REJECTED,
  UPDATE_DEVICE_SCHEDULE_FULFILLED,
  UPDATE_DEVICE_SCHEDULE_PENDING,
  UPDATE_DEVICE_SCHEDULE_REJECTED,
  GET_END_DEVICES_PENDING,
  GET_END_DEVICES_FULFILLED,
  GET_END_DEVICES_REJECTED,
  GET_TREE_END_DEVICE_TYPE_PENDING,
  GET_TREE_END_DEVICE_TYPE_FULFILLED,
  GET_TREE_END_DEVICE_TYPE_REJECTED,
  GET_ALERT_THRESHOLDS_DATA_FULFILLED,
  GET_DEVICE_TYPE_REJECTED,
  GET_DEVICE_TYPE_FULFILLED,
  GET_DEVICE_TYPE_PENDING,
  ADD_DEVICE_TYPE_PENDING,
  ADD_DEVICE_TYPE_FULFILLED,
  ADD_DEVICE_TYPE_REJECTED,
  UPDATE_DEVICE_TYPE_PENDING,
  UPDATE_DEVICE_TYPE_FULFILLED,
  UPDATE_DEVICE_TYPE_REJECTED,
} from './DeviceActionTypes';
const initialState = {
  deviceData: [],
  deviceTypeData: [],
  programmerTypeData: [],
  isLoading: false,
  processingDelete: false,
  deviceDataById: [],
  updateDeviceData: [],
  propertyFilterData: [],
  deviceFilterData: [],
  projectFilterData: [],
  isLoadingUpdateSchedule: false,
  defaultAlertThreshold: undefined,
  isDeviceTypeLoading: false,
};

const getDeviceSuccess = (state, action) => {
  return {
    ...state,
    deviceData: action.payload.data.data,
    isLoading: false,
  };
};

const getEndDeviceTypesPending = (state) => {
  return {
    ...state,
  };
};

const getEndDeviceTypesSuccess = (state, action) => {
  return {
    ...state,
    deviceTypeData: action.payload.data.data,
  };
};

const getEndDeviceTypesFail = (state) => {
  return {
    ...state,
  };
};

const getProgrammerTypesPending = (state) => {
  return {
    ...state,
  };
};

const getProgrammerTypesSuccess = (state, action) => {
  return {
    ...state,
    programmerTypeData: action.payload.data.data,
  };
};

const getProgrammerTypesFail = (state) => {
  return {
    ...state,
  };
};

const getDeviceByIdPending = (state) => {
  return {
    ...state,
  };
};

const getDeviceByIdSuccess = (state, action) => {
  return {
    ...state,
    deviceDataById: action.payload.data,
  };
};

const getDeviceByIdFail = (state) => {
  return {
    ...state,
  };
};

const deleteDevicePending = (state) => {
  return {
    ...state,
    processingDelete: true,
  };
};

const deleteDeviceSuccess = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const deleteDeviceFail = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const updateDeviceSuccess = (state, action) => {
  return {
    ...state,
    updateDeviceData: action.data,
    isLoading: false,
  };
};

const setLoadingStart = (state) => ({
  ...state,
  isLoading: true,
});

const setLoadingCompleted = (state, action) => {
  if (action.type === UPDATED_DEVICE_REJECTED) {
    pushNotification(ERRORS.EDIT_DEVICE_ERROR, 'error', 'TOP_RIGHT', 3000);
  }
  return {
    ...state,
    isLoading: false,
  };
};

const addEndDevicePending = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const addEndDeviceSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const addEndDeviceFail = (state, action) => {
  if (action.payload.response.status === 404) {
    pushNotification(ERRORS.DEVICE_ERROR, 'error', 'TOP_RIGHT', 3000);
  } else if (action.payload?.response?.data?.message) {
    pushNotification(
      action.payload.response.data.message,
      'error',
      'TOP_RIGHT',
      3000,
    );
  } else {
    pushNotification(ERRORS.ADD_DEVICE_ERROR, 'error', 'TOP_RIGHT', 3000);
  }
  return {
    ...state,
    isLoading: false,
  };
};

const updateDeviceScheduleStart = (state) => {
  return {
    ...state,
    isLoadingUpdateSchedule: true,
  };
};
const updateDeviceScheduleSuccess = (state) => {
  return {
    ...state,
    isLoadingUpdateSchedule: false,
  };
};
const updateDeviceScheduleFail = (state, action) => {
  if (action.payload.response.status === 404) {
    pushNotification(ERRORS.DEVICE_ERROR, 'error', 'TOP_RIGHT', 3000);
  } else {
    pushNotification(ERRORS.DEVICE_SCHEDULE, 'error', 'TOP_RIGHT', 3000);
  }
  return {
    ...state,
    isLoadingUpdateSchedule: false,
  };
};
const getTreeTypesPending = (state) => {
  return {
    ...state,
  };
};
const getTreeTypesSuccess = (state, action) => {
  return {
    ...state,
    treeTypeData: action.payload.data.data,
  };
};
const getAlertThresholdsDataFulfilled = (state, action) => {
  return {
    ...state,
    defaultAlertThreshold: action.payload.data.records[0],
  };
};
const getTreeTypesFail = (state) => {
  return {
    ...state,
  };
};
const getEndDevicesPending = (state) => {
  return {
    ...state,
  };
};
const getEndDevicesSuccess = (state, action) => {
  return {
    ...state,
    defaultAlertThreshold: action.payload.data.data,
  };
};
const getEndDevicesFail = (state) => {
  return {
    ...state,
  };
};
const deleteDeviceTypePending = (state) => {
  return {
    ...state,
    processingDelete: true,
  };
};
const deleteDeviceTypeSuccess = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};
const deleteDeviceTypeFail = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};
const addDevicePending = (state) => {
  return {
    ...state,
    isDeviceTypeLoading: true,
  };
};
const addDeviceSuccess = (state) => {
  return {
    ...state,
    isDeviceTypeLoading: false,
  };
};
const addDeviceFail = (state) => {
  return {
    ...state,
    isDeviceTypeLoading: true,
  };
};
const updateDeviceTypePending = (state) => {
  return {
    ...state,
    isDeviceTypeLoading: true,
  };
};
const updateDeviceTypeSuccess = (state) => {
  return {
    ...state,
    isDeviceTypeLoading: false,
  };
};
const updateDeviceTypeFail = (state) => {
  return {
    ...state,
    isDeviceTypeLoading: false,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DEVICE_PENDING:
      return setLoadingStart(state, action);

    case GET_DEVICE_FULFILLED:
      return getDeviceSuccess(state, action);

    case GET_DEVICE_REJECTED:
      return setLoadingCompleted(state, action);

    case GET_END_DEVICE_TYPE_PENDING:
      return getEndDeviceTypesPending(state, action);

    case GET_END_DEVICE_TYPE_FULFILLED:
      return getEndDeviceTypesSuccess(state, action);

    case GET_END_DEVICE_TYPE_REJECTED:
      return getEndDeviceTypesFail(state, action);

    case GET_PROGRAMMER_END_DEVICE_TYPE_PENDING:
      return getProgrammerTypesPending(state, action);

    case GET_PROGRAMMER_END_DEVICE_TYPE_FULFILLED:
      return getProgrammerTypesSuccess(state, action);

    case GET_PROGRAMMER_END_DEVICE_TYPE_REJECTED:
      return getProgrammerTypesFail(state, action);

    case GET_DEVICE_BY_ID_PENDING:
      return getDeviceByIdPending(state, action);

    case GET_DEVICE_BY_ID_FULFILLED:
      return getDeviceByIdSuccess(state, action);

    case GET_DEVICE_BY_ID_REJECTED:
      return getDeviceByIdFail(state, action);

    case DELETE_DEVICE_PENDING:
      return deleteDevicePending(state, action);

    case DELETE_DEVICE_FULFILLED:
      return deleteDeviceSuccess(state, action);

    case DELETE_DEVICE_REJECTED:
      return deleteDeviceFail(state, action);

    case UPDATED_DEVICE_PENDING:
      return setLoadingStart(state, action);

    case UPDATED_DEVICE_FULFILLED:
      return updateDeviceSuccess(state, action);

    case UPDATED_DEVICE_REJECTED:
      return setLoadingCompleted(state, action);

    case ADD_END_DEVICE_PENDING:
      return addEndDevicePending(state, action);

    case ADD_END_DEVICE_FULFILLED:
      return addEndDeviceSuccess(state, action);

    case ADD_END_DEVICE_REJECTED:
      return addEndDeviceFail(state, action);

    case UPDATE_DEVICE_SCHEDULE_PENDING:
      return updateDeviceScheduleStart(state, action);

    case UPDATE_DEVICE_SCHEDULE_FULFILLED:
      return updateDeviceScheduleSuccess(state, action);

    case UPDATE_DEVICE_SCHEDULE_REJECTED:
      return updateDeviceScheduleFail(state, action);
    case GET_TREE_END_DEVICE_TYPE_PENDING:
      return getTreeTypesPending(state, action);

    case GET_TREE_END_DEVICE_TYPE_FULFILLED:
      return getTreeTypesSuccess(state, action);

    case GET_TREE_END_DEVICE_TYPE_REJECTED:
      return getTreeTypesFail(state, action);
    case GET_END_DEVICES_PENDING:
      return getEndDevicesPending(state, action);
    case GET_END_DEVICES_FULFILLED:
      return getEndDevicesSuccess(state, action);
    case GET_END_DEVICES_REJECTED:
      return getEndDevicesFail(state, action);
    case GET_ALERT_THRESHOLDS_DATA_FULFILLED:
      return getAlertThresholdsDataFulfilled(state, action);
    case GET_DEVICE_TYPE_PENDING:
      return deleteDeviceTypePending(state, action);
    case GET_DEVICE_TYPE_FULFILLED:
      return deleteDeviceTypeSuccess(state, action);
    case GET_DEVICE_TYPE_REJECTED:
      return deleteDeviceTypeFail(state, action);
    case ADD_DEVICE_TYPE_PENDING:
      return addDevicePending(state, action);
    case ADD_DEVICE_TYPE_FULFILLED:
      return addDeviceSuccess(state, action);
    case ADD_DEVICE_TYPE_REJECTED:
      return addDeviceFail(state, action);
    case UPDATE_DEVICE_TYPE_PENDING:
      return updateDeviceTypePending(state, action);
    case UPDATE_DEVICE_TYPE_FULFILLED:
      return updateDeviceTypeSuccess(state, action);
    case UPDATE_DEVICE_TYPE_REJECTED:
      return updateDeviceTypeFail(state, action);
    default:
      return {
        ...state,
      };
  }
}
