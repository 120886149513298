import { clients } from '../constants/clientConfig/clients-config';
import envConfig from '../constants/envConfig/envConfig';
import { ENV_NAME } from './envVariables';

export const getClient = () => {
  const host = window.location.host;
  const metaData = clients?.metaData;
  const currentClient = clients[host];
  const currentMetaData = metaData[currentClient];

  if (currentClient !== undefined) {
    let props = {};
    props[currentClient] = true;
    props.metaData = currentMetaData;
    return [props, currentMetaData];
  }
  // default oxit user
  return [{ isOxit: true }, metaData['isOxit']];
};

export const getClientConfig = () => {
  return envConfig[ENV_NAME];
};

export const getClientUserGroupConfig = () => {
  return envConfig[ENV_NAME]?.USER_GROUPS || envConfig['dev']?.USER_GROUPS;
};

export const getClientId = () => {
  return envConfig[ENV_NAME]?.CLIENT_IDS || envConfig['dev']?.CLIENT_IDS;
};

export const getFooterEnvConfig = () => {
  return envConfig[ENV_NAME]?.FOOTERS || envConfig['prod']?.FOOTERS; // Defaults to prod as prod does not have any env data in footer
};
