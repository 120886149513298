export const clientBuilding = [
  '28 24',
  `
<path d="M6.23047 5.89258H10.476V7.67513H6.23047V5.89258Z" fill="#1f386c"/>
<path d="M6.22998 9.94629H10.4755V11.7288H6.22998V9.94629Z" fill="#1f386c"/>
<path d="M6.22998 14H10.4755V15.7826H6.22998V14Z" fill="#1f386c"/>
<path d="M6.22998 18.0542H10.4755V19.8368H6.22998V18.0542Z" fill="#1f386c"/>
<path d="M14.8141 22.2089V7.64377L24.1221 7.6329V17.4586H25.9036V6.74059C25.9036 6.25434 25.4974 5.84928 25.0118 5.84928L13.9223 5.86223C13.4308 5.86282 13.0326 6.26165 13.0326 6.75353V22.2089H3.66998V4.57185L13.0326 2.05317V4.0767H14.8141V0.89059C14.8141 0.313964 14.2493 -0.12002 13.6921 0.0298669L2.5479 3.02779C2.15887 3.13248 1.88844 3.48541 1.88844 3.88851V22.2089H0V23.9915H27.7921V22.2089H14.8141Z" fill="#1f386c"/>
<path d="M24.1221 19.0625H25.9036V20.723H24.1221V19.0625Z" fill="#1f386c"/>
<path d="M17.3169 9.94531H21.5624V11.7279H17.3169V9.94531Z" fill="#1f386c"/>
<path d="M17.3174 13.998H21.5629V15.7806H17.3174V13.998Z" fill="#1f386c"/>
<path d="M17.3174 18.0518H21.5629V19.8343H17.3174V18.0518Z" fill="#1f386c"/>
`,
];
