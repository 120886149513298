import { getDateByNumberOfDays } from '../../utils/share';
import {
  GET_DASHBOARD_PENDING,
  GET_DASHBOARD_FULFILLED,
  GET_DASHBOARD_REJECTED,
  GET_DASHBOARD_TABS_PENDING,
  GET_DASHBOARD_TABS_FULFILLED,
  GET_DASHBOARD_TABS_REJECTED,
  IS_PHOTO_TABLE,
  SET_DEVICE_STATE,
  SET_ANNOTATION_TYPE,
  SET_DASHBOARD_DATE_FILTER,
  SET_ACTIVE_TILE,
  SET_PROGRAMMER_STATUS,
} from './DashboardActiontypes';

const currentDate = new Date();
const initialState = {
  dashboardData: null,
  isLoading: false,
  dashboardTabs: [],
  isPhotoTable: false,
  annotationType: null,
  deviceState: 1,
  activeTile: null,
  batteryLevel: null,
  programmerStatus: 'online',
  toDate: currentDate,
  fromDate: getDateByNumberOfDays(),
  processingTreeTilt: false,
  treeTilt: [],
};

const getDashboardPending = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const getDashboardFulfilled = (state, action) => {
  return {
    ...state,
    dashboardData: action.payload.data.data,
    isLoading: false,
  };
};

const getDashboardRejected = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const getDashboardTabsPending = (state) => {
  return {
    ...state,
  };
};

const getDashboardTabsFulfilled = (state, action) => {
  return {
    ...state,
    dashboardTabs: action.payload.data.data,
  };
};

const getDashboardTabsRejected = (state) => {
  return {
    ...state,
  };
};

const isPhotoTable = (state, action) => {
  return {
    ...state,
    isPhotoTable: action.payload.isPhotoTable,
  };
};
const annotationType = (state, action) => {
  return {
    ...state,
    annotationType: action.payload.annotationType,
  };
};
const deviceState = (state, action) => {
  return {
    ...state,
    deviceState: action.payload.deviceState,
    batteryLevel: action.payload.batteryLevel,
    deviceMissedUpload: action.payload.deviceMissedUpload,
  };
};
const activeTile = (state, action) => {
  return {
    ...state,
    activeTile: action.payload.activeTile,
  };
};
const programmerStatus = (state, action) => {
  return {
    ...state,
    programmerStatus: action.payload.programmerStatus,
  };
};
const setDashboardDateFilter = (state, action) => {
  return {
    ...state,
    fromDate: action.payload.fromDate,
    toDate: action.payload.toDate,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_PENDING:
      return getDashboardPending(state, action);
    case GET_DASHBOARD_FULFILLED:
      return getDashboardFulfilled(state, action);
    case GET_DASHBOARD_REJECTED:
      return getDashboardRejected(state, action);
    case GET_DASHBOARD_TABS_PENDING:
      return getDashboardTabsPending(state, action);
    case GET_DASHBOARD_TABS_FULFILLED:
      return getDashboardTabsFulfilled(state, action);
    case GET_DASHBOARD_TABS_REJECTED:
      return getDashboardTabsRejected(state, action);
    case IS_PHOTO_TABLE:
      return isPhotoTable(state, action);
    case SET_ANNOTATION_TYPE:
      return annotationType(state, action);
    case SET_DEVICE_STATE:
      return deviceState(state, action);
    case SET_ACTIVE_TILE:
      return activeTile(state, action);
    case SET_DASHBOARD_DATE_FILTER:
      return setDashboardDateFilter(state, action);
    case SET_PROGRAMMER_STATUS:
      return programmerStatus(state, action);

    default:
      return {
        ...state,
      };
  }
}
