import {
  GET_CLIENT_PENDING,
  GET_CLIENT_FULFILLED,
  GET_CLIENT_REJECTED,
  ADD_CLIENT_PENDING,
  ADD_CLIENT_FULFILLED,
  ADD_CLIENT_REJECTED,
  UPDATED_CLIENT_PENDING,
  UPDATED_CLIENT_FULFILLED,
  UPDATED_CLIENT_REJECTED,
  GET_AUTH_TOKEN_FULFILLED,
  DELETE_CLIENT_PENDING,
  DELETE_CLIENT_FULFILLED,
  DELETE_CLIENT_REJECTED,
  GET_CLIENT_BY_ID_PENDING,
  GET_CLIENT_BY_ID_FULFILLED,
  GET_CLIENT_BY_ID_REJECTED,
  GET_CLIENT_ASSIGNED_USER_PENDING,
  GET_CLIENT_ASSIGNED_USER_FULFILLED,
  GET_CLIENT_ASSIGNED_USER_REJECTED,
  GET_CLIENT_CUSTOMER_PENDING,
  GET_CLIENT_CUSTOMER_FULFILLED,
  GET_CLIENT_CUSTOMER_REJECTED,
  SEARCH_PROJECT,
  GET_CLIENT_TYPE_ROLES_FULFILLED,
  SET_THEME_PREVIEW,
  RESET_THEME_PREVIEW,
  HIDE_THEME_PREVIEW_MODAL,
} from './ClientActionTypes';
const initialState = {
  processing: false,
  processingStatus: false,
  processingDelete: false,
  processingUser: false,
  processingCustomer: false,
  clientData: [],
  clientIdData: [],
  authToken: {},
  addClientData: {},
  updateClientData: {},
  errorMessage: '',
  clientAssignedUser: [],
  clientCustomer: [],
  filteredProject: null,
  clientTypeRoles: null,
  previewTheme: {},
  isResetThemePreview: false,
  isHideThemePreviewModal: true,
};

const getAuthTokenSuccess = (state, action) => {
  const authToken = action.payload.data.auth_token;
  localStorage.setItem('AUTH-TOKEN', authToken);
  return {
    ...state,
    authToken: action.payload.data,
  };
};

const getClientSuccess = (state, action) => {
  return {
    ...state,
    clientData: action.payload.data.data,
    processing: false,
  };
};

const addClientSuccess = (state, action) => {
  return {
    ...state,
    addClient: action.data,
    processing: false,
  };
};

const updateClientPending = (state) => {
  return {
    ...state,
    processingStatus: true,
  };
};

const updateClientSuccess = (state, action) => {
  return {
    ...state,
    updateClientData: action.data,
    processingStatus: false,
  };
};

const updateClientFail = (state) => {
  return {
    ...state,
    processingStatus: false,
  };
};

const beginClientDelete = (state) => {
  return {
    ...state,
    processingDelete: true,
  };
};

const deleteClientSuccess = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const deleteClientFail = (state) => {
  return {
    ...state,
    processingDelete: false,
  };
};

const getClientAssignedUserPending = (state) => {
  return {
    ...state,
    processingUser: true,
  };
};

const getClientAssignedUser = (state, action) => {
  return {
    ...state,
    clientAssignedUser: action.payload.data.data.records,
    processingUser: false,
  };
};

const getClientAssignedUserFail = (state) => {
  return {
    ...state,
    processingUser: false,
  };
};

const getClientCustomerPending = (state) => {
  return {
    ...state,
    processingCustomer: true,
  };
};

const getClientCustomer = (state, action) => {
  return {
    ...state,
    clientCustomer: action.payload.data.data,
    processingCustomer: false,
  };
};

const getClientCustomerFail = (state) => {
  return {
    ...state,
    processingCustomer: false,
  };
};

const getClientByIdSuccess = (state, action) => {
  return {
    ...state,
    clientIdData: action.payload.data,
    processing: false,
  };
};

const setProcessingStart = (state) => ({
  ...state,
  processing: true,
});

const setProcessingCompleted = (state) => ({
  ...state,
  processing: false,
});

export const searchProject = (state, action) => {
  return {
    ...state,
    filteredProject: state.clientData.records
      ? state.clientData.records.filter((x) =>
          x.projectName.toLowerCase().includes(action.payload.toLowerCase()),
        )
      : [],
  };
};

const setClientTypeRoles = (state, action) => {
  return {
    ...state,
    clientTypeRoles: action.payload?.data?.data?.userTypeRoles,
  };
};
const setThemePreview = (state, action) => {
  return {
    ...state,
    previewTheme: action.payload,
    isResetThemePreview: false,
  };
};

const resetThemePreview = (state) => {
  return {
    ...state,
    previewTheme: {},
    isResetThemePreview: true,
  };
};
const hideThemePreviewModal = (state, action) => {
  return {
    ...state,
    isHideThemePreviewModal: action.payload,
    clientPreviewTheme: {},
  };
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_PENDING:
      return setProcessingStart(state, action);

    case GET_CLIENT_FULFILLED:
      return getClientSuccess(state, action);

    case GET_CLIENT_REJECTED:
      return setProcessingCompleted(state, action);

    case ADD_CLIENT_PENDING:
      return setProcessingStart(state, action);

    case ADD_CLIENT_FULFILLED:
      return addClientSuccess(state, action);

    case ADD_CLIENT_REJECTED:
      return setProcessingCompleted(state, action);

    case UPDATED_CLIENT_PENDING:
      return updateClientPending(state, action);

    case UPDATED_CLIENT_FULFILLED:
      return updateClientSuccess(state, action);

    case UPDATED_CLIENT_REJECTED:
      return updateClientFail(state, action);

    case GET_AUTH_TOKEN_FULFILLED:
      return getAuthTokenSuccess(state, action);

    case DELETE_CLIENT_PENDING:
      return beginClientDelete(state, action);

    case DELETE_CLIENT_FULFILLED:
      return deleteClientSuccess(state, action);

    case DELETE_CLIENT_REJECTED:
      return deleteClientFail(state, action);

    case GET_CLIENT_BY_ID_PENDING:
      return setProcessingStart(state, action);

    case GET_CLIENT_BY_ID_FULFILLED:
      return getClientByIdSuccess(state, action);

    case GET_CLIENT_BY_ID_REJECTED:
      return setProcessingCompleted(state, action);

    case GET_CLIENT_ASSIGNED_USER_PENDING:
      return getClientAssignedUserPending(state, action);

    case GET_CLIENT_ASSIGNED_USER_FULFILLED:
      return getClientAssignedUser(state, action);

    case GET_CLIENT_ASSIGNED_USER_REJECTED:
      return getClientAssignedUserFail(state, action);

    case GET_CLIENT_CUSTOMER_PENDING:
      return getClientCustomerPending(state, action);

    case GET_CLIENT_CUSTOMER_FULFILLED:
      return getClientCustomer(state, action);

    case GET_CLIENT_CUSTOMER_REJECTED:
      return getClientCustomerFail(state, action);

    case SEARCH_PROJECT:
      return searchProject(state, action);

    case GET_CLIENT_TYPE_ROLES_FULFILLED:
      return setClientTypeRoles(state, action);
    case SET_THEME_PREVIEW:
      return setThemePreview(state, action);
    case RESET_THEME_PREVIEW:
      return resetThemePreview(state);
    case HIDE_THEME_PREVIEW_MODAL:
      return hideThemePreviewModal(state, action);

    default:
      return {
        ...state,
      };
  }
}
