export const GET_TREE_TILT = 'GET_TREE_TILT';
export const GET_TREE_TILT_PENDING = 'GET_TREE_TILT_PENDING';
export const GET_TREE_TILT_FULFILLED = 'GET_TREE_TILT_FULFILLED';
export const GET_TREE_TILT_REJECTED = 'GET_TREE_TILT_REJECTED';
export const GET_TREE_TILT_Z = 'GET_TREE_TILT_Z';
export const GET_TREE_TILT_Z_PENDING = 'GET_TREE_TILT_Z_PENDING';
export const GET_TREE_TILT_Z_FULFILLED = 'GET_TREE_TILT_Z_FULFILLED';
export const GET_TREE_TILT_Z_REJECTED = 'GET_TREE_TILT_Z_REJECTED';
export const GET_PACKET = 'GET_PACKET';
export const GET_PACKET_PENDING = 'GET_PACKET_PENDING';
export const GET_PACKET_FULFILLED = 'GET_PACKET_FULFILLED';
export const GET_PACKET_REJECTED = 'GET_PACKET_REJECTED';
