export const workImage = [
  '432 417',
  `
<path d="M220.801 159.873C143.032 168.402 103.65 201.473 72.5435 200.25C35.1463 198.781 52.4319 255.661 34.279 256.737C11.588 258.083 60.2828 294.307 40.5053 326.378C33.5845 337.601 143.769 301.177 179.468 350.942C208.026 390.754 327.961 336.244 360.581 299.803C391.192 265.606 427.539 196.485 374.192 213.095C320.845 229.704 287.318 152.579 220.801 159.873Z" fill="#5181C2" fill-opacity="0.06"/>
<path d="M413 299.036H22V299.232H413V299.036Z" fill="#C1CEEB"/>
<path d="M150.71 311.095H124.811V311.29H150.71V311.095Z" fill="#C1CEEB"/>
<path d="M224.413 308.976H217.617V309.171H224.413V308.976Z" fill="#C1CEEB"/>
<path d="M78.03 305.676H63.0234V305.871H78.03V305.676Z" fill="#C1CEEB"/>
<path d="M347.141 304.362H326.746V304.558H347.141V304.362Z" fill="#C1CEEB"/>
<path d="M319.777 304.362H301.447V304.558H319.777V304.362Z" fill="#C1CEEB"/>
<path d="M373.83 313.746H300.572V313.942H373.83V313.746Z" fill="#C1CEEB"/>
<path d="M207.333 264.159H56.3363C55.1534 264.157 54.0197 263.686 53.184 262.849C52.3483 262.012 51.8789 260.877 51.8789 259.694V47.4359C51.8892 46.2598 52.3631 45.1352 53.1977 44.3065C54.0323 43.4778 55.1602 43.0118 56.3363 43.0098H207.333C208.517 43.0098 209.653 43.4802 210.49 44.3176C211.327 45.155 211.798 46.2907 211.798 47.475V259.694C211.798 260.878 211.327 262.014 210.49 262.852C209.653 263.689 208.517 264.159 207.333 264.159ZM56.3363 43.1662C55.2053 43.1682 54.1213 43.619 53.3222 44.4195C52.5232 45.22 52.0744 46.3048 52.0744 47.4359V259.694C52.0744 260.825 52.5232 261.91 53.3222 262.71C54.1213 263.511 55.2053 263.962 56.3363 263.964H207.333C208.464 263.962 209.549 263.511 210.35 262.711C211.15 261.911 211.6 260.826 211.602 259.694V47.4359C211.6 46.3041 211.15 45.2193 210.35 44.419C209.549 43.6187 208.464 43.1682 207.333 43.1662H56.3363Z" fill="#C1CEEB"/>
<path d="M376.489 264.159H225.485C224.301 264.157 223.167 263.686 222.33 262.849C221.493 262.012 221.022 260.878 221.02 259.694V47.4359C221.032 46.2591 221.508 45.1345 222.343 44.306C223.179 43.4775 224.308 43.0118 225.485 43.0098H376.489C377.664 43.0138 378.79 43.4807 379.623 44.3092C380.455 45.1377 380.928 46.2611 380.939 47.4359V259.694C380.939 260.876 380.47 262.009 379.636 262.846C378.802 263.683 377.67 264.155 376.489 264.159ZM225.485 43.1662C224.353 43.1682 223.268 43.6187 222.468 44.419C221.668 45.2193 221.217 46.3041 221.215 47.4359V259.694C221.217 260.826 221.668 261.911 222.468 262.711C223.268 263.511 224.353 263.962 225.485 263.964H376.489C377.621 263.962 378.706 263.511 379.506 262.711C380.306 261.911 380.757 260.826 380.759 259.694V47.4359C380.757 46.3041 380.306 45.2193 379.506 44.419C378.706 43.6187 377.621 43.1682 376.489 43.1662H225.485Z" fill="#C1CEEB"/>
<path d="M271.146 296.706H289.562V216.129H271.146V296.706Z" fill="#DAE2F3"/>
<path d="M204.948 299.036H286.033V296.706H204.948V299.036Z" fill="#DAE2F3"/>
<path d="M271.145 216.129H201.406V296.706H271.145V216.129Z" fill="white"/>
<path d="M263.905 220.649H208.648V254.619H263.905V220.649Z" fill="#DAE2F3"/>
<path d="M263.905 258.216H208.648V292.186H263.905V258.216Z" fill="#DAE2F3"/>
<path d="M250.602 226.569H221.958C221.062 226.57 220.175 226.394 219.348 226.051C218.52 225.708 217.769 225.205 217.136 224.57C216.504 223.936 216.004 223.183 215.663 222.354C215.323 221.526 215.15 220.638 215.154 219.742H257.382C257.386 220.636 257.214 221.522 256.876 222.349C256.537 223.176 256.038 223.928 255.409 224.562C254.779 225.196 254.03 225.7 253.205 226.044C252.381 226.389 251.496 226.567 250.602 226.569Z" fill="#ECF0F9"/>
<path d="M250.6 264.135H221.956C220.153 264.135 218.425 263.42 217.151 262.145C215.876 260.871 215.16 259.142 215.16 257.34H257.388C257.388 259.141 256.673 260.868 255.401 262.142C254.128 263.417 252.401 264.133 250.6 264.135Z" fill="#ECF0F9"/>
<path d="M346.359 296.706H364.775V216.129H346.359V296.706Z" fill="#DAE2F3"/>
<path d="M280.155 299.036H361.24V296.706H280.155V299.036Z" fill="#DAE2F3"/>
<path d="M346.358 216.129H276.619V296.706H346.358V216.129Z" fill="white"/>
<path d="M339.117 220.649H283.861V254.619H339.117V220.649Z" fill="#DAE2F3"/>
<path d="M339.117 258.216H283.861V292.186H339.117V258.216Z" fill="#DAE2F3"/>
<path d="M325.807 226.569H297.163C295.36 226.569 293.632 225.853 292.358 224.579C291.083 223.304 290.367 221.576 290.367 219.773H332.595C332.595 221.574 331.88 223.302 330.608 224.576C329.335 225.85 327.608 226.567 325.807 226.569Z" fill="#ECF0F9"/>
<path d="M325.807 264.135H297.163C295.36 264.135 293.632 263.42 292.358 262.145C291.083 260.871 290.367 259.142 290.367 257.34H332.595C332.595 259.141 331.88 260.868 330.608 262.142C329.335 263.417 327.608 264.133 325.807 264.135Z" fill="#ECF0F9"/>
<path d="M109.13 107.869H140.676V71.4045H109.13V107.869Z" fill="#C1CEEB"/>
<path d="M105.534 107.869H139.832V71.4045H105.534V107.869Z" fill="#ECF0F9"/>
<path d="M136.953 104.999V74.2822H108.402V104.999H136.953Z" fill="white"/>
<path d="M130.039 97.5543V96.436C130.037 92.5354 128.487 88.7952 125.729 86.0371C122.971 83.279 119.231 81.7286 115.33 81.7266V82.8448C115.33 86.746 116.88 90.4874 119.638 93.246C122.397 96.0045 126.138 97.5543 130.039 97.5543Z" fill="#ECF0F9"/>
<path d="M246.152 176.692L364.781 176.692V65.7266L246.152 65.7266V176.692Z" fill="#C1CEEB"/>
<path d="M242.476 176.692L363.061 176.692V65.7266L242.476 65.7266V176.692Z" fill="#DAE2F3"/>
<path d="M246.152 180.649L364.781 180.649V176.693L246.152 176.693V180.649Z" fill="#C1CEEB"/>
<path d="M236.345 180.649L356.93 180.649V176.693L236.345 176.693V180.649Z" fill="#DAE2F3"/>
<path d="M357.908 170.734V71.6855H247.615V170.734H357.908Z" fill="white"/>
<path d="M326.635 170.741L308.265 71.6855H278.002L296.371 170.741H326.635Z" fill="white"/>
<path d="M355.53 137.132C355.635 137.124 355.732 137.075 355.801 136.996C355.869 136.917 355.904 136.814 355.898 136.709V75.5648C355.898 75.4673 355.859 75.3738 355.79 75.3049C355.721 75.236 355.628 75.1973 355.53 75.1973C355.433 75.1973 355.339 75.236 355.271 75.3049C355.202 75.3738 355.163 75.4673 355.163 75.5648V136.709C355.156 136.814 355.191 136.917 355.26 136.996C355.329 137.075 355.426 137.124 355.53 137.132Z" fill="#DAE2F3"/>
<path d="M289.867 170.741L271.505 71.6855H259.705L278.066 170.741H289.867Z" fill="white"/>
<path d="M248.271 170.734V71.6855H247.615V170.734H248.271Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 79.3098H359.879L360.356 74.0078H241.727L241.258 79.3098Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 85.4172H359.879L360.356 80.1074H241.727L241.258 85.4172Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 91.5168H359.879L360.356 86.2148H241.727L241.258 91.5168Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 97.6243H359.879L360.356 92.3145H241.727L241.258 97.6243Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 103.724H359.879L360.356 98.4219H241.727L241.258 103.724Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 109.831H359.879L360.356 104.521H241.727L241.258 109.831Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 115.931H359.879L360.356 110.629H241.727L241.258 115.931Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 122.038H359.879L360.356 116.729H241.727L241.258 122.038Z" fill="#C1CEEB"/>
<path opacity="0.4" d="M241.258 128.138H359.879L360.356 122.836H241.727L241.258 128.138Z" fill="#C1CEEB"/>
<path d="M86.7655 167.355H111.375V139.117H86.7655V167.355Z" fill="#C1CEEB"/>
<path d="M83.9804 167.355H110.545V139.117H83.9804V167.355Z" fill="#ECF0F9"/>
<path d="M108.324 165.127V141.339H86.2093V165.127H108.324Z" fill="white"/>
<path d="M102.96 153.67V152.802C102.96 152.054 102.813 151.313 102.526 150.622C102.239 149.931 101.819 149.303 101.29 148.774C100.76 148.245 100.132 147.826 99.4403 147.54C98.7487 147.255 98.0076 147.108 97.2593 147.109C95.7549 147.118 94.3149 147.721 93.254 148.788C92.1931 149.855 91.5976 151.298 91.5977 152.802V153.67C91.5977 155.18 92.1974 156.628 93.2651 157.696C94.3327 158.764 95.7808 159.363 97.2906 159.363C98.7964 159.357 100.238 158.755 101.301 157.688C102.364 156.621 102.96 155.176 102.96 153.67Z" fill="#ECF0F9"/>
<path d="M148.145 157.236H181.936V118.308H148.145V157.236Z" fill="#C1CEEB"/>
<path d="M144.313 157.236H180.926V118.308H144.313V157.236Z" fill="#ECF0F9"/>
<path d="M177.861 154.179V121.39H147.379V154.179H177.861Z" fill="white"/>
<path d="M163.894 131.697L169.681 141.722C169.81 141.946 169.878 142.199 169.878 142.457C169.878 142.716 169.81 142.969 169.681 143.193C169.552 143.416 169.366 143.602 169.142 143.731C168.918 143.86 168.665 143.927 168.407 143.927H156.833C156.575 143.927 156.321 143.86 156.097 143.731C155.874 143.602 155.688 143.416 155.559 143.193C155.429 142.969 155.361 142.716 155.361 142.457C155.361 142.199 155.429 141.946 155.558 141.722L161.345 131.697C161.476 131.474 161.662 131.29 161.885 131.162C162.109 131.034 162.362 130.967 162.62 130.967C162.877 130.967 163.131 131.034 163.354 131.162C163.578 131.29 163.764 131.474 163.894 131.697Z" fill="#ECF0F9"/>
<path d="M92.3962 299.028H110.969L110.969 240.472H92.3962V299.028Z" fill="#C1CEEB"/>
<path d="M95.9233 299.037H92.3965V289.004H99.6222L95.9233 299.037Z" fill="#DAE2F3"/>
<path d="M161.799 299.028H180.371V240.472H161.799V299.028Z" fill="#C1CEEB"/>
<path d="M92.4032 292.874H164.879V240.472H92.4032V292.874Z" fill="white"/>
<path d="M95.1328 243.765V280.339H127.453V243.765H95.1328Z" fill="#C1CEEB"/>
<path d="M129.822 243.765V280.339H162.142V243.765H129.822Z" fill="#C1CEEB"/>
<path d="M142.788 246.572H149.177C150.549 246.576 151.908 246.301 153.17 245.764C154.433 245.226 155.573 244.437 156.52 243.444H135.406C136.358 244.442 137.504 245.235 138.773 245.773C140.043 246.311 141.409 246.583 142.788 246.572Z" fill="#DAE2F3"/>
<path d="M107.769 246.572H114.166C115.538 246.576 116.896 246.301 118.158 245.763C119.421 245.225 120.561 244.436 121.509 243.444H100.395C101.346 244.441 102.491 245.232 103.759 245.77C105.027 246.308 106.391 246.581 107.769 246.572Z" fill="#DAE2F3"/>
<path d="M161.353 299.037H164.88V289.004H157.654L161.353 299.037Z" fill="#DAE2F3"/>
<path d="M142.232 232.629C142.231 233.426 142.466 234.205 142.907 234.868C143.349 235.532 143.977 236.049 144.713 236.355C145.449 236.661 146.259 236.742 147.041 236.588C147.822 236.433 148.541 236.05 149.105 235.487C149.669 234.924 150.053 234.207 150.209 233.425C150.365 232.644 150.286 231.834 149.981 231.097C149.677 230.361 149.16 229.732 148.498 229.289C147.836 228.846 147.057 228.609 146.26 228.609C145.194 228.611 144.172 229.035 143.417 229.788C142.662 230.542 142.237 231.563 142.232 232.629Z" fill="#C1CEEB"/>
<path d="M148.082 234.037C150.302 234.037 152.101 232.237 152.101 230.018C152.101 227.798 150.302 225.998 148.082 225.998C145.862 225.998 144.062 227.798 144.062 230.018C144.062 232.237 145.862 234.037 148.082 234.037Z" fill="#C1CEEB"/>
<path d="M122.096 233.278C122.096 234.073 122.331 234.85 122.773 235.511C123.215 236.172 123.843 236.688 124.577 236.992C125.311 237.296 126.12 237.376 126.899 237.221C127.679 237.065 128.395 236.683 128.957 236.12C129.52 235.558 129.902 234.842 130.057 234.062C130.213 233.283 130.133 232.475 129.829 231.74C129.524 231.006 129.009 230.378 128.348 229.936C127.687 229.495 126.91 229.259 126.115 229.259C125.049 229.259 124.027 229.682 123.273 230.436C122.519 231.19 122.096 232.212 122.096 233.278Z" fill="#C1CEEB"/>
<path d="M153.462 235.609C156.187 235.609 158.396 233.4 158.396 230.675C158.396 227.949 156.187 225.74 153.462 225.74C150.737 225.74 148.527 227.949 148.527 230.675C148.527 233.4 150.737 235.609 153.462 235.609Z" fill="#C1CEEB"/>
<path d="M138.752 226.131C138.75 227.107 139.038 228.062 139.58 228.874C140.121 229.687 140.891 230.32 141.793 230.695C142.694 231.07 143.687 231.169 144.644 230.979C145.602 230.79 146.482 230.32 147.173 229.63C147.864 228.941 148.334 228.061 148.525 227.104C148.716 226.147 148.619 225.154 148.246 224.252C147.873 223.35 147.24 222.579 146.429 222.036C145.617 221.494 144.663 221.204 143.686 221.204C142.38 221.206 141.127 221.726 140.202 222.649C139.278 223.572 138.756 224.824 138.752 226.131Z" fill="#C1CEEB"/>
<path d="M130.932 227.389C130.93 228.366 131.218 229.321 131.759 230.133C132.301 230.946 133.071 231.579 133.972 231.954C134.874 232.329 135.866 232.427 136.824 232.238C137.782 232.048 138.662 231.579 139.352 230.889C140.043 230.199 140.514 229.32 140.705 228.363C140.896 227.405 140.799 226.413 140.426 225.511C140.052 224.609 139.42 223.838 138.608 223.295C137.797 222.752 136.842 222.463 135.866 222.463C134.559 222.463 133.305 222.982 132.38 223.905C131.455 224.829 130.934 226.082 130.932 227.389Z" fill="#C1CEEB"/>
<path d="M134.24 231.37C134.24 232.298 134.515 233.205 135.031 233.976C135.547 234.748 136.279 235.349 137.137 235.705C137.994 236.06 138.937 236.153 139.848 235.972C140.758 235.791 141.594 235.344 142.25 234.687C142.906 234.031 143.353 233.195 143.534 232.285C143.715 231.375 143.622 230.432 143.267 229.574C142.912 228.717 142.311 227.984 141.539 227.468C140.767 226.953 139.86 226.678 138.932 226.678C137.688 226.678 136.494 227.172 135.614 228.052C134.735 228.932 134.24 230.125 134.24 231.37Z" fill="#C1CEEB"/>
<path d="M126.076 231.065C126.076 232.068 126.373 233.047 126.93 233.881C127.487 234.714 128.278 235.363 129.204 235.747C130.13 236.131 131.149 236.231 132.132 236.035C133.115 235.84 134.018 235.357 134.727 234.649C135.435 233.94 135.918 233.037 136.114 232.054C136.309 231.071 136.209 230.052 135.825 229.126C135.442 228.2 134.792 227.409 133.959 226.852C133.125 226.295 132.146 225.998 131.144 225.998C129.8 225.998 128.511 226.532 127.56 227.482C126.61 228.433 126.076 229.721 126.076 231.065Z" fill="#C1CEEB"/>
<path d="M161.329 232.629C161.329 232.629 160.781 240.449 139.613 240.449C118.444 240.449 117.889 232.629 117.889 232.629H161.329Z" fill="#DAE2F3"/>
<path d="M217.501 334.352C301.239 334.352 369.123 330.389 369.123 325.5C369.123 320.611 301.239 316.647 217.501 316.647C133.762 316.647 65.8789 320.611 65.8789 325.5C65.8789 330.389 133.762 334.352 217.501 334.352Z" fill="#DAE2F3"/>
<path d="M262.677 93.7622C262.041 91.6609 261.198 89.6281 260.159 87.6939L261.723 82.5718L251.612 72.4527L246.49 74.0167C244.556 72.978 242.523 72.1345 240.421 71.4986L237.903 66.8066H223.593L221.083 71.4986C218.981 72.1331 216.948 72.9766 215.014 74.0167L209.892 72.4527L199.773 82.5718L201.337 87.6939C200.304 89.6299 199.464 91.6623 198.827 93.7622L194.096 96.2802V110.591L198.827 113.101C199.462 115.201 200.303 117.234 201.337 119.169L199.773 124.291L209.892 134.411L215.014 132.847C216.95 133.881 218.982 134.722 221.083 135.357L223.593 140.049H237.903L240.421 135.357C242.521 134.72 244.554 133.879 246.49 132.847L251.612 134.411L261.731 124.291L260.167 119.169C261.207 117.236 262.05 115.203 262.685 113.101L267.416 110.591V96.2802L262.677 93.7622ZM230.748 123.76C226.727 123.76 222.796 122.567 219.452 120.333C216.109 118.099 213.503 114.924 211.964 111.208C210.425 107.493 210.022 103.405 210.807 99.4611C211.591 95.5171 213.528 91.8943 216.371 89.0508C219.215 86.2073 222.837 84.2709 226.781 83.4864C230.725 82.7019 234.814 83.1045 238.529 84.6434C242.244 86.1823 245.419 88.7883 247.653 92.1318C249.888 95.4754 251.08 99.4064 251.08 103.428C251.08 108.82 248.938 113.992 245.125 117.805C241.312 121.618 236.14 123.76 230.748 123.76Z" fill="#24A5ED"/>
<path opacity="0.2" d="M262.677 93.7622C262.041 91.6609 261.198 89.6281 260.159 87.6939L261.723 82.5718L251.612 72.4527L246.49 74.0167C244.556 72.978 242.523 72.1345 240.421 71.4986L237.903 66.8066H223.593L221.083 71.4986C218.981 72.1331 216.948 72.9766 215.014 74.0167L209.892 72.4527L199.773 82.5718L201.337 87.6939C200.304 89.6299 199.464 91.6623 198.827 93.7622L194.096 96.2802V110.591L198.827 113.101C199.462 115.201 200.303 117.234 201.337 119.169L199.773 124.291L209.892 134.411L215.014 132.847C216.95 133.881 218.982 134.722 221.083 135.357L223.593 140.049H237.903L240.421 135.357C242.521 134.72 244.554 133.879 246.49 132.847L251.612 134.411L261.731 124.291L260.167 119.169C261.207 117.236 262.05 115.203 262.685 113.101L267.416 110.591V96.2802L262.677 93.7622ZM230.748 123.76C226.727 123.76 222.796 122.567 219.452 120.333C216.109 118.099 213.503 114.924 211.964 111.208C210.425 107.493 210.022 103.405 210.807 99.4611C211.591 95.5171 213.528 91.8943 216.371 89.0508C219.215 86.2073 222.837 84.2709 226.781 83.4864C230.725 82.7019 234.814 83.1045 238.529 84.6434C242.244 86.1823 245.419 88.7883 247.653 92.1318C249.888 95.4754 251.08 99.4064 251.08 103.428C251.08 108.82 248.938 113.992 245.125 117.805C241.312 121.618 236.14 123.76 230.748 123.76Z" fill="white"/>
<path d="M230.749 96.6943C229.415 96.6943 228.112 97.0897 227.004 97.8304C225.895 98.5711 225.031 99.6238 224.521 100.856C224.011 102.087 223.877 103.443 224.137 104.75C224.397 106.058 225.039 107.259 225.982 108.202C226.925 109.144 228.126 109.786 229.434 110.046C230.741 110.307 232.097 110.173 233.328 109.663C234.56 109.153 235.613 108.289 236.353 107.18C237.094 106.072 237.49 104.768 237.49 103.435C237.49 101.647 236.779 99.9328 235.515 98.6687C234.251 97.4045 232.536 96.6943 230.749 96.6943Z" fill="#24A5ED"/>
<path d="M364.518 116.464C363.609 113.46 362.407 110.552 360.928 107.784L363.165 100.464L348.721 86.0205L341.394 88.2648C338.635 86.7695 335.729 85.564 332.722 84.6676L329.132 77.9111H308.683L305.086 84.6676C302.08 85.5669 299.174 86.7722 296.413 88.2648L289.094 86.0205L274.635 100.48L276.871 107.799C275.386 110.565 274.184 113.473 273.282 116.479L266.518 120.069V140.518L273.274 144.115C274.175 147.12 275.377 150.025 276.863 152.788L274.627 160.099L289.086 174.559L296.406 172.322C299.169 173.81 302.077 175.01 305.086 175.904L308.683 182.668H329.132L332.722 175.911C335.727 175.008 338.632 173.803 341.394 172.314L348.721 174.551L363.181 160.092L360.944 152.772C362.418 150.006 363.615 147.101 364.518 144.1L371.274 140.502V120.053L364.518 116.464ZM318.904 167.348C311.571 167.348 304.403 165.174 298.306 161.1C292.209 157.026 287.457 151.236 284.651 144.462C281.845 137.687 281.111 130.233 282.542 123.041C283.972 115.849 287.503 109.243 292.688 104.058C297.873 98.8732 304.479 95.3421 311.671 93.9116C318.863 92.4811 326.317 93.2153 333.092 96.0214C339.866 98.8275 345.656 103.579 349.73 109.676C353.804 115.773 355.978 122.941 355.978 130.274C355.978 135.143 355.019 139.964 353.156 144.462C351.293 148.96 348.562 153.047 345.119 156.49C341.677 159.932 337.59 162.663 333.092 164.526C328.594 166.39 323.772 167.348 318.904 167.348Z" fill="#24A5ED"/>
<path d="M318.904 98.3994C312.598 98.401 306.435 100.272 301.193 103.776C295.951 107.281 291.866 112.26 289.454 118.086C287.042 123.912 286.412 130.322 287.643 136.506C288.874 142.69 291.911 148.37 296.37 152.828C300.829 157.286 306.51 160.322 312.694 161.552C318.879 162.781 325.289 162.149 331.114 159.736C336.939 157.323 341.918 153.236 345.421 147.994C348.924 142.751 350.794 136.587 350.794 130.282C350.783 121.828 347.42 113.723 341.441 107.746C335.463 101.769 327.357 98.4077 318.904 98.3994ZM318.904 157.917C313.437 157.919 308.094 156.299 303.548 153.264C299.002 150.228 295.459 145.912 293.366 140.863C291.273 135.813 290.725 130.256 291.79 124.895C292.855 119.534 295.487 114.609 299.352 110.743C303.216 106.877 308.14 104.244 313.501 103.177C318.862 102.11 324.42 102.657 329.47 104.749C334.52 106.84 338.837 110.382 341.874 114.927C344.911 119.472 346.532 124.815 346.532 130.282C346.523 137.607 343.61 144.63 338.431 149.811C333.252 154.992 326.229 157.907 318.904 157.917Z" fill="#24A5ED"/>
<path opacity="0.2" d="M318.904 98.3994C312.598 98.401 306.435 100.272 301.193 103.776C295.951 107.281 291.866 112.26 289.454 118.086C287.042 123.912 286.412 130.322 287.643 136.506C288.874 142.69 291.911 148.37 296.37 152.828C300.829 157.286 306.51 160.322 312.694 161.552C318.879 162.781 325.289 162.149 331.114 159.736C336.939 157.323 341.918 153.236 345.421 147.994C348.924 142.751 350.794 136.587 350.794 130.282C350.783 121.828 347.42 113.723 341.441 107.746C335.463 101.769 327.357 98.4077 318.904 98.3994ZM318.904 157.917C313.437 157.919 308.094 156.299 303.548 153.264C299.002 150.228 295.459 145.912 293.366 140.863C291.273 135.813 290.725 130.256 291.79 124.895C292.855 119.534 295.487 114.609 299.352 110.743C303.216 106.877 308.14 104.244 313.501 103.177C318.862 102.11 324.42 102.657 329.47 104.749C334.52 106.84 338.837 110.382 341.874 114.927C344.911 119.472 346.532 124.815 346.532 130.282C346.523 137.607 343.61 144.63 338.431 149.811C333.252 154.992 326.229 157.907 318.904 157.917Z" fill="black"/>
<path d="M245.653 184.678C245.446 184.678 245.247 184.595 245.1 184.449C244.953 184.302 244.871 184.103 244.871 183.896V182.332C244.871 182.124 244.953 181.926 245.1 181.779C245.247 181.632 245.446 181.55 245.653 181.55C245.86 181.55 246.059 181.632 246.206 181.779C246.353 181.926 246.435 182.124 246.435 182.332V183.896C246.435 184.103 246.353 184.302 246.206 184.449C246.059 184.595 245.86 184.678 245.653 184.678Z" fill="#24A5ED"/>
<path d="M233.477 164.471C232.759 164.01 232.197 163.342 231.866 162.555C231.784 162.364 231.629 162.214 231.437 162.137C231.244 162.06 231.028 162.063 230.837 162.145C230.647 162.226 230.496 162.381 230.419 162.574C230.342 162.767 230.345 162.982 230.427 163.173C230.886 164.241 231.651 165.149 232.624 165.785C232.75 165.866 232.897 165.91 233.046 165.91C233.216 165.911 233.381 165.857 233.517 165.757C233.653 165.656 233.753 165.514 233.802 165.352C233.85 165.19 233.845 165.016 233.786 164.857C233.727 164.698 233.619 164.563 233.477 164.471Z" fill="#24A5ED"/>
<path d="M230.747 154.704C230.954 154.704 231.153 154.621 231.3 154.475C231.446 154.328 231.529 154.129 231.529 153.922V150.927C231.529 150.719 231.446 150.52 231.3 150.374C231.153 150.227 230.954 150.145 230.747 150.145C230.539 150.145 230.341 150.227 230.194 150.374C230.047 150.52 229.965 150.719 229.965 150.927V153.922C229.965 154.129 230.047 154.328 230.194 154.475C230.341 154.621 230.539 154.704 230.747 154.704Z" fill="#24A5ED"/>
<path d="M238.888 165.159H235.886C235.678 165.159 235.479 165.242 235.333 165.388C235.186 165.535 235.104 165.734 235.104 165.941C235.104 166.149 235.186 166.347 235.333 166.494C235.479 166.641 235.678 166.723 235.886 166.723H238.888C239.096 166.723 239.295 166.641 239.441 166.494C239.588 166.347 239.67 166.149 239.67 165.941C239.67 165.734 239.588 165.535 239.441 165.388C239.295 165.242 239.096 165.159 238.888 165.159Z" fill="#24A5ED"/>
<path d="M231.529 159.92V156.925C231.529 156.717 231.446 156.518 231.3 156.372C231.153 156.225 230.954 156.143 230.747 156.143C230.539 156.143 230.341 156.225 230.194 156.372C230.047 156.518 229.965 156.717 229.965 156.925V159.92C229.965 160.127 230.047 160.326 230.194 160.473C230.341 160.619 230.539 160.702 230.747 160.702C230.954 160.702 231.153 160.619 231.3 160.473C231.446 160.326 231.529 160.127 231.529 159.92Z" fill="#24A5ED"/>
<path d="M246.388 170.242C246.361 170.036 246.254 169.85 246.089 169.724C245.925 169.598 245.718 169.542 245.513 169.569C245.411 169.586 245.313 169.623 245.226 169.678C245.138 169.733 245.062 169.805 245.003 169.889C244.943 169.973 244.901 170.069 244.878 170.169C244.856 170.27 244.853 170.374 244.871 170.476C244.896 170.658 244.909 170.84 244.91 171.024V173.37C244.91 173.577 244.993 173.776 245.139 173.922C245.286 174.069 245.485 174.152 245.692 174.152C245.9 174.152 246.099 174.069 246.245 173.922C246.392 173.776 246.474 173.577 246.474 173.37V171.024C246.463 170.761 246.434 170.5 246.388 170.242Z" fill="#24A5ED"/>
<path d="M245.653 175.552C245.446 175.552 245.247 175.634 245.1 175.781C244.953 175.927 244.871 176.126 244.871 176.334V179.337C244.871 179.544 244.953 179.743 245.1 179.89C245.247 180.036 245.446 180.119 245.653 180.119C245.86 180.119 246.059 180.036 246.206 179.89C246.353 179.743 246.435 179.544 246.435 179.337V176.334C246.435 176.126 246.353 175.927 246.206 175.781C246.059 175.634 245.86 175.552 245.653 175.552Z" fill="#24A5ED"/>
<path d="M244.974 167.137C244.208 166.262 243.197 165.639 242.073 165.346C241.973 165.32 241.869 165.315 241.767 165.329C241.666 165.343 241.568 165.378 241.479 165.43C241.39 165.482 241.313 165.552 241.251 165.634C241.189 165.716 241.144 165.81 241.119 165.909C241.093 166.009 241.087 166.112 241.101 166.214C241.116 166.316 241.15 166.414 241.202 166.503C241.255 166.591 241.324 166.669 241.406 166.731C241.488 166.792 241.582 166.837 241.682 166.863C242.503 167.075 243.242 167.53 243.801 168.169C243.874 168.252 243.964 168.319 244.065 168.365C244.167 168.411 244.276 168.435 244.387 168.435C244.538 168.435 244.685 168.391 244.811 168.31C244.937 168.228 245.037 168.112 245.099 167.975C245.161 167.838 245.182 167.686 245.16 167.537C245.138 167.389 245.073 167.25 244.974 167.137Z" fill="#24A5ED"/>
<path d="M230.747 148.674C230.539 148.674 230.341 148.592 230.194 148.445C230.047 148.298 229.965 148.099 229.965 147.892V146.328C229.965 146.12 230.047 145.922 230.194 145.775C230.341 145.628 230.539 145.546 230.747 145.546C230.954 145.546 231.153 145.628 231.3 145.775C231.446 145.922 231.529 146.12 231.529 146.328V147.892C231.529 148.099 231.446 148.298 231.3 148.445C231.153 148.592 230.954 148.674 230.747 148.674Z" fill="#24A5ED"/>
<path d="M282.697 224.051H101.633V226.952H282.697V224.051Z" fill="#ADBAD6"/>
<path d="M282.697 226.951H321.984V224.05H282.697V226.951Z" fill="#ADBAD6"/>
<path opacity="0.2" d="M282.697 226.951H321.984V224.05H282.697V226.951Z" fill="black"/>
<path d="M308.862 230.056H114.754L108.264 226.951H315.353L308.862 230.056Z" fill="#ADBAD6"/>
<path opacity="0.6" d="M308.862 230.056H114.754L108.264 226.951H315.353L308.862 230.056Z" fill="white"/>
<path d="M120.619 230.056V322.598H123.106L126.234 230.056H120.619Z" fill="#ADBAD6"/>
<path d="M149.977 230.056V322.598H152.463L155.591 230.056H149.977Z" fill="#ADBAD6"/>
<path d="M308.863 230.056V322.598H306.384L303.248 230.056H308.863Z" fill="#ADBAD6"/>
<path d="M279.515 230.056V322.598H277.028L273.9 230.056H279.515Z" fill="#ADBAD6"/>
<path d="M220.426 188.923L212.254 222.213L212.027 224.05H268.394L268.629 222.213L276.793 188.923H220.426Z" fill="#ADBAD6"/>
<path opacity="0.3" d="M220.426 188.923L212.254 222.213L212.027 224.05H268.394L268.629 222.213L276.793 188.923H220.426Z" fill="black"/>
<path d="M275.066 188.923H218.691L210.527 222.213H266.894L275.066 188.923Z" fill="#ADBAD6"/>
<path d="M265.696 220.687L273.125 190.447H219.886L212.465 220.687H265.696Z" fill="white"/>
<path d="M252.206 222.213L251.972 224.051H189.904L190.131 222.213H252.206Z" fill="#ADBAD6"/>
<path opacity="0.6" d="M252.206 222.213L251.972 224.051H189.904L190.131 222.213H252.206Z" fill="white"/>
<path d="M134.955 217.997C134.915 216.806 134.449 215.669 133.641 214.793C132.832 213.917 131.736 213.362 130.552 213.227V212.328C130.551 212.276 130.539 212.225 130.518 212.177C130.497 212.129 130.467 212.086 130.429 212.051C130.391 212.015 130.347 211.987 130.298 211.969C130.249 211.951 130.197 211.943 130.145 211.945H121.7C121.647 211.943 121.595 211.951 121.546 211.969C121.497 211.987 121.451 212.015 121.413 212.05C121.374 212.086 121.343 212.129 121.321 212.176C121.299 212.224 121.287 212.275 121.285 212.328V224.058H130.552V222.775C131.738 222.642 132.836 222.086 133.645 221.208C134.454 220.33 134.919 219.19 134.955 217.997ZM130.552 221.258V214.744C131.333 214.859 132.047 215.252 132.564 215.85C133.08 216.448 133.364 217.211 133.364 218.001C133.364 218.791 133.08 219.555 132.564 220.153C132.047 220.751 131.333 221.143 130.552 221.258Z" fill="#ADBAD6"/>
<path d="M293.012 224.051H300.863L298.54 206.854H293.012V224.051Z" fill="#263238"/>
<path d="M281.5 224.051H296.374L299.134 203.922H284.268L281.5 224.051Z" fill="#ADBAD6"/>
<path opacity="0.3" d="M281.5 224.051H296.374L299.134 203.922H284.268L281.5 224.051Z" fill="black"/>
<path d="M279.516 224.051H294.991L297.76 203.922H282.284L279.516 224.051Z" fill="#ADBAD6"/>
<path d="M293.621 222.463L295.952 205.509H283.651L281.32 222.463H293.621Z" fill="white"/>
<path d="M176.476 194.335L175.835 195.023L175.147 195.805C174.686 196.337 174.224 196.877 173.786 197.432C172.91 198.542 172.066 199.676 171.276 200.841C169.705 203.095 168.393 205.519 167.366 208.067C166.888 209.278 166.516 210.529 166.256 211.805C166.13 212.524 166.052 212.837 166.013 213.142C165.97 213.385 165.97 213.634 166.013 213.877C166.144 214.26 166.35 214.612 166.619 214.914C166.887 215.216 167.213 215.462 167.577 215.637C168.105 215.962 168.66 216.242 169.235 216.474C169.537 216.599 169.85 216.716 170.173 216.825C170.471 216.935 170.846 217.044 171.081 217.138V221.447C170.533 221.525 170.134 221.588 169.673 221.619C169.212 221.65 168.766 221.658 168.304 221.619C167.356 221.6 166.412 221.479 165.489 221.259C164.449 221.015 163.453 220.614 162.533 220.071C161.452 219.438 160.523 218.576 159.812 217.545C159.08 216.447 158.594 215.204 158.389 213.901C158.336 213.595 158.302 213.287 158.287 212.978C158.272 212.681 158.272 212.383 158.287 212.086V211.649C158.278 211.531 158.278 211.414 158.287 211.297L158.35 210.609C158.55 208.821 158.927 207.058 159.476 205.346C160.535 202.056 162.026 198.921 163.91 196.024C164.815 194.602 165.799 193.231 166.858 191.919C167.382 191.262 167.921 190.621 168.484 189.987C168.758 189.667 169.047 189.354 169.344 189.041C169.642 188.728 169.915 188.454 170.322 188.063L176.476 194.335Z" fill="#FFD5C0"/>
<path d="M169.523 217.045L173.168 214.37L175.24 221.862C175.24 221.862 172.01 223.058 169.547 221.447L169.523 217.045Z" fill="#FFD5C0"/>
<path d="M178.228 212.571L179.651 218.843L175.24 221.861L173.168 214.37L178.228 212.571Z" fill="#FFD5C0"/>
<path d="M184.124 167.294C185 171.744 185.868 179.9 182.756 182.871C182.756 182.871 183.976 187.384 192.249 187.384C201.344 187.384 196.597 182.871 196.597 182.871C191.632 181.683 191.757 178 192.625 174.543L184.124 167.294Z" fill="#FFD5C0"/>
<path d="M181.566 184.42C180.909 182.989 180.197 181.354 181.675 180.846C183.31 180.283 192.256 179.704 195.337 180.76C195.744 180.871 196.125 181.062 196.458 181.322C196.791 181.582 197.068 181.905 197.274 182.274C197.48 182.642 197.611 183.048 197.658 183.467C197.705 183.887 197.669 184.312 197.55 184.717L181.566 184.42Z" fill="#263238"/>
<path d="M212.808 192.052C214.724 195.5 216.781 199.051 218.861 202.46C220.941 205.87 223.123 209.264 225.453 212.36C226.032 213.142 226.634 213.885 227.228 214.628C227.525 215.011 227.838 215.324 228.143 215.676L228.597 216.192L228.714 216.325L228.769 216.388L228.824 216.458C229.188 216.81 229.626 217.077 230.106 217.24C230.845 217.497 231.607 217.683 232.382 217.795C236.183 218.179 240.015 218.134 243.807 217.662L244.995 221.807C243.94 222.276 242.954 222.589 241.906 222.98C240.859 223.371 239.826 223.629 238.778 223.895C236.557 224.452 234.28 224.756 231.991 224.802C230.705 224.823 229.422 224.694 228.167 224.419C226.625 224.113 225.172 223.466 223.913 222.526L223.389 222.096L223.264 221.987L223.185 221.909L223.037 221.76L222.427 221.158C222.028 220.759 221.59 220.376 221.23 219.954C220.448 219.132 219.713 218.311 219.033 217.467C216.301 214.165 213.78 210.694 211.487 207.074C210.345 205.291 209.245 203.49 208.187 201.67C207.123 199.833 206.099 198.034 205.105 196.095L212.808 192.052Z" fill="#FFD5C0"/>
<path d="M171.861 184.248C166.153 185.554 159.615 201.108 159.615 201.108L171.072 208.928C173.921 204.841 176.449 200.541 178.633 196.064C182.02 188.979 177.695 182.91 171.861 184.248Z" fill="#8F9BB5"/>
<path opacity="0.2" d="M175.945 194.163L170.533 208.583L171.065 208.959C171.065 208.959 173.411 205.666 175.945 201.225V194.163Z" fill="black"/>
<path d="M205.958 184.553C205.958 184.553 212.433 191.872 206.318 237.15H173.764C173.545 232.153 176.68 207.786 171.863 184.24C175.461 183.547 179.1 183.087 182.757 182.864C187.366 182.594 191.988 182.594 196.598 182.864C199.757 183.182 202.887 183.747 205.958 184.553Z" fill="#8F9BB5"/>
<path opacity="0.2" d="M202.338 197.65C203.187 202.789 205.408 207.604 208.766 211.586C208.899 208.669 208.954 206.049 208.946 203.711L202.338 197.65Z" fill="black"/>
<path d="M199.515 189.494C198.733 195.258 210.072 209.975 210.072 209.975L221.482 202.155C221.482 202.155 218.314 194.046 212.989 188.728C206.921 182.628 200.407 183.223 199.515 189.494Z" fill="#8F9BB5"/>
<path d="M242.525 217.701L247.17 215.793L251.948 219.406C251.948 219.406 247.389 223.441 243.245 221.869L242.525 217.701Z" fill="#FFD5C0"/>
<path d="M250.235 215.605L253.739 218.108L251.924 219.406L247.146 215.793L250.235 215.605Z" fill="#FFD5C0"/>
<path d="M186.15 159.224C184.523 165.042 183.272 168.365 185.133 172.283C187.925 178.187 195.862 178.023 199.209 172.815C202.212 168.123 204.37 159.396 199.584 155.196C198.534 154.263 197.262 153.614 195.889 153.31C194.517 153.007 193.09 153.06 191.744 153.463C190.398 153.867 189.177 154.608 188.198 155.616C187.219 156.624 186.514 157.866 186.15 159.224Z" fill="#FFD5C0"/>
<path d="M198.537 159.286C196.191 165.964 193.431 173.299 186.69 171.907C182.342 171.008 181.998 164.009 180.434 160.826C179.05 158.035 179.136 152.42 181.998 152.35C180.716 150.488 187.199 143.677 191.625 147.274C192.759 145.71 200.93 141.104 206.983 148.416C212.684 155.329 209.892 161.092 198.537 159.286Z" fill="#263238"/>
<path d="M193.258 163.556C193.102 165.048 193.437 166.55 194.212 167.833C195.252 169.507 196.777 168.866 197.34 167.153C197.841 165.589 197.896 162.891 196.449 161.804C195.002 160.717 193.477 161.757 193.258 163.556Z" fill="#FFD5C0"/>
<path d="M243.212 318.18C243.994 318.18 244.776 318.071 245.003 317.719C245.068 317.626 245.102 317.515 245.102 317.402C245.102 317.289 245.068 317.178 245.003 317.085C244.899 316.921 244.737 316.801 244.549 316.749C243.533 316.475 241.421 317.688 241.32 317.742C241.293 317.756 241.272 317.777 241.259 317.804C241.246 317.831 241.243 317.862 241.249 317.891C241.255 317.919 241.268 317.945 241.287 317.966C241.306 317.986 241.331 318.001 241.359 318.008C241.97 318.12 242.591 318.177 243.212 318.18ZM244.182 316.999C244.273 316.987 244.365 316.987 244.456 316.999C244.515 317.01 244.571 317.034 244.619 317.07C244.668 317.105 244.708 317.15 244.737 317.203C244.839 317.383 244.808 317.469 244.737 317.516C244.456 317.914 242.915 317.922 241.781 317.758C242.525 317.352 243.34 317.094 244.182 316.999Z" fill="#8F9BB5"/>
<path d="M241.374 318.009H241.437C242.172 317.68 243.626 316.366 243.509 315.663C243.509 315.498 243.368 315.303 242.977 315.256C242.837 315.244 242.695 315.26 242.561 315.303C242.427 315.346 242.303 315.415 242.195 315.506C241.413 316.14 241.257 317.782 241.249 317.852C241.246 317.877 241.25 317.902 241.259 317.926C241.269 317.949 241.284 317.969 241.304 317.985C241.325 317.998 241.349 318.006 241.374 318.009ZM242.86 315.545H242.946C243.204 315.545 243.22 315.678 243.228 315.709C243.298 316.124 242.305 317.156 241.554 317.602C241.616 316.913 241.889 316.261 242.336 315.733C242.482 315.608 242.668 315.541 242.86 315.545Z" fill="#8F9BB5"/>
<path d="M234.297 317.868H240.381L241.765 303.769H235.681L234.297 317.868Z" fill="#FFD5C0"/>
<path d="M240.727 317.164H234.088C233.966 317.165 233.847 317.207 233.752 317.284C233.657 317.36 233.591 317.467 233.564 317.586L232.36 322.966C232.329 323.098 232.328 323.234 232.358 323.365C232.388 323.497 232.447 323.619 232.532 323.724C232.616 323.829 232.723 323.913 232.845 323.97C232.967 324.027 233.101 324.056 233.236 324.053C235.628 324.014 239.093 323.874 242.103 323.874C245.63 323.874 248.672 324.061 252.801 324.061C255.304 324.061 256 321.535 254.952 321.309C250.189 320.269 246.303 320.159 242.189 317.618C241.753 317.335 241.247 317.178 240.727 317.164Z" fill="#263238"/>
<path opacity="0.2" d="M241.757 303.776L241.053 311.041H234.961L235.673 303.776H241.757Z" fill="black"/>
<path d="M218.821 318.173C219.079 318.206 219.341 318.189 219.592 318.12C219.843 318.052 220.078 317.934 220.283 317.774C220.355 317.675 220.394 317.556 220.394 317.434C220.394 317.312 220.355 317.192 220.283 317.094C220.237 317.008 220.173 316.933 220.094 316.875C220.016 316.817 219.925 316.777 219.83 316.757C218.938 316.546 217.257 317.696 217.187 317.743C217.164 317.76 217.147 317.783 217.136 317.809C217.125 317.835 217.121 317.863 217.124 317.891C217.13 317.918 217.143 317.943 217.162 317.962C217.182 317.982 217.207 317.995 217.234 318.001C217.755 318.114 218.287 318.172 218.821 318.173ZM219.603 317.015C219.66 317.007 219.718 317.007 219.775 317.015C219.831 317.024 219.884 317.045 219.93 317.077C219.977 317.11 220.015 317.153 220.041 317.203C220.174 317.445 220.096 317.539 220.041 317.578C219.783 317.915 218.54 317.922 217.625 317.774C218.213 317.376 218.884 317.116 219.587 317.015H219.603Z" fill="#8F9BB5"/>
<path d="M217.274 318.008H217.336C217.97 317.664 219.189 316.335 219.057 315.662C219.036 315.555 218.979 315.457 218.895 315.387C218.811 315.317 218.705 315.279 218.595 315.279C218.486 315.266 218.375 315.276 218.27 315.308C218.165 315.34 218.068 315.394 217.985 315.467C217.281 316.046 217.133 317.813 217.125 317.875C217.125 317.902 217.131 317.928 217.143 317.951C217.155 317.974 217.173 317.994 217.195 318.008C217.221 318.016 217.248 318.016 217.274 318.008ZM218.501 315.545H218.564C218.752 315.545 218.767 315.655 218.775 315.694C218.853 316.093 218.071 317.101 217.446 317.578C217.488 316.884 217.738 316.219 218.165 315.67C218.259 315.59 218.378 315.545 218.501 315.545Z" fill="#8F9BB5"/>
<path d="M210.299 317.868H216.383L219.44 303.769H213.356L210.299 317.868Z" fill="#FFD5C0"/>
<path d="M216.811 317.163H209.977C209.855 317.164 209.738 317.207 209.644 317.284C209.55 317.36 209.485 317.467 209.46 317.586L208.256 322.966C208.228 323.098 208.23 323.235 208.262 323.366C208.294 323.497 208.355 323.62 208.44 323.724C208.526 323.828 208.634 323.912 208.756 323.969C208.879 324.026 209.013 324.055 209.148 324.053C211.533 324.014 212.698 323.873 215.701 323.873C217.554 323.873 219.924 324.061 222.481 324.061C225.038 324.061 225.366 321.535 224.318 321.308C219.626 320.284 219.306 318.868 217.804 317.515C217.527 317.281 217.174 317.155 216.811 317.163Z" fill="#263238"/>
<g opacity="0.2">
<path opacity="0.2" d="M213.357 303.776H219.441L217.861 311.041H211.777L213.357 303.776Z" fill="black"/>
</g>
<path d="M206.317 237.157C206.317 237.157 251.908 241.982 251.407 265.239C251.084 279.845 248.276 294.289 243.102 307.952H234.062C234.062 307.952 234.524 293.195 234.219 269.383C234.125 262.244 219.713 259.89 205.629 259.061C199.665 258.686 193.924 256.652 189.055 253.188C184.186 249.724 180.381 244.968 178.071 239.456L177.109 237.157H206.317Z" fill="#263238"/>
<path d="M245.318 305.527C245.364 305.527 244.731 309.805 244.731 309.805H232.219L231.875 305.989L245.318 305.527Z" fill="#8F9BB5"/>
<path d="M196.558 237.157C196.558 237.157 229.606 248.105 230.443 261.681C232.007 286.658 219.721 307.952 219.721 307.952H210.595C210.595 307.952 214.06 297.395 215.287 274.764C216.851 246.338 163.808 274.717 173.748 237.157H196.558Z" fill="#263238"/>
<path d="M222.48 305.527C222.527 305.527 221.33 309.805 221.33 309.805H208.818L209.045 305.989L222.48 305.527Z" fill="#8F9BB5"/>
<path d="M220.394 261.102H169.564C169.564 259.563 170.176 258.087 171.264 256.999C172.352 255.911 173.828 255.3 175.367 255.3H214.592C216.131 255.3 217.607 255.911 218.695 256.999C219.783 258.087 220.394 259.563 220.394 261.102Z" fill="#24A5ED"/>
<path d="M171.918 261.095L164.254 325.344H165.896L175.616 261.095H171.918Z" fill="#8F9BB5"/>
<path opacity="0.4" d="M171.918 261.095L164.254 325.344H165.896L175.616 261.095H171.918Z" fill="black"/>
<path d="M182.17 261.095L182.021 325.344H183.656L185.869 261.095H182.17Z" fill="#8F9BB5"/>
<path opacity="0.4" d="M182.17 261.095L182.021 325.344H183.656L185.869 261.095H182.17Z" fill="black"/>
<path d="M218.041 261.095L225.704 325.344H224.062L214.35 261.095H218.041Z" fill="#8F9BB5"/>
<path opacity="0.4" d="M218.041 261.095L225.704 325.344H224.062L214.35 261.095H218.041Z" fill="black"/>
<path d="M207.787 261.095L207.935 325.344H206.301L204.088 261.095H207.787Z" fill="#8F9BB5"/>
<path opacity="0.4" d="M207.787 261.095L207.935 325.344H206.301L204.088 261.095H207.787Z" fill="black"/>
`,
];
