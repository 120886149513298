import {
  SET_VALID_FIELDS,
  SET_IN_VALID_FIELDS,
  SET_OTA_UPDATE_DETAILS,
} from './OtaUpdateActionTypes';

const initialState = {
  otaUpdateFormField: {},
  invalidField: [],
  validField: [],
};

const setValidField = (state, action) => {
  return {
    ...state,
    validField: action.payload,
  };
};

const setInvalidField = (state, action) => {
  return {
    ...state,
    invalidField: action.payload,
  };
};

const setOtaUpdateDetails = (state, action) => {
  return {
    ...state,
    otaUpdateFormField: action.payload.data,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VALID_FIELDS:
      return setValidField(state, action);

    case SET_IN_VALID_FIELDS:
      return setInvalidField(state, action);

    case SET_OTA_UPDATE_DETAILS:
      return setOtaUpdateDetails(state, action);

    default:
      return {
        ...state,
      };
  }
}
