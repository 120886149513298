import {
  GET_SENSOR_INVENTORY_PENDING,
  GET_SENSOR_INVENTORY_FULFILLED,
  GET_SENSOR_INVENTORY_REJECTED,
  ADD_SENSOR_INVENTORY_PENDING,
  ADD_SENSOR_INVENTORY_FULFILLED,
  ADD_SENSOR_INVENTORY_REJECTED,
  UPDATED_SENSOR_INVENTORY_PENDING,
  UPDATED_SENSOR_INVENTORY_FULFILLED,
  UPDATED_SENSOR_INVENTORY_REJECTED,
  DELETE_SENSOR_INVENTORY_PENDING,
  DELETE_SENSOR_INVENTORY_FULFILLED,
  DELETE_SENSOR_INVENTORY_REJECTED,
  GET_SENSOR_INVENTORY_BY_ID_FULFILLED,
  GET_SENSOR_INVENTORY_BY_ID_PENDING,
  GET_SENSOR_INVENTORY_BY_ID_REJECTED,
} from './SensorInventoryActionTypes';
const initialState = {
  sensorInventoryData: [],
  isLoading: false,
  addSensorInventoryData: {},
  updateSensorInventoryData: {},
  processing: false,
};

const getSensorInventorySuccess = (state, action) => {
  return {
    ...state,
    sensorInventoryData: action.payload.data.data,
    isLoading: false,
  };
};

const addSensorInventorySuccess = (state, action) => {
  return {
    ...state,
    addSensorInventoryData: action.data,
    processing: false,
  };
};

const updateSensorInventorySuccess = (state, action) => {
  return {
    ...state,
    updateSensorInventoryData: action.data,
    processing: false,
  };
};

const getSensorInventoryByIdSuccess = (state, action) => {
  return {
    ...state,
    sensorInventoryIdData: action.payload.data,
    isLoading: false,
  };
};

const setLoadingStart = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};
const setLoadingCompleted = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};
const setProcessingStart = (state) => {
  return {
    ...state,
    processing: true,
  };
};

const setProcessingCompleted = (state) => {
  return {
    ...state,
    processing: false,
  };
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SENSOR_INVENTORY_PENDING:
      return setLoadingStart(state, action);

    case GET_SENSOR_INVENTORY_FULFILLED:
      return getSensorInventorySuccess(state, action);

    case GET_SENSOR_INVENTORY_REJECTED:
      return setLoadingCompleted(state, action);

    case ADD_SENSOR_INVENTORY_PENDING:
      return setProcessingStart(state, action);

    case ADD_SENSOR_INVENTORY_FULFILLED:
      return addSensorInventorySuccess(state, action);

    case ADD_SENSOR_INVENTORY_REJECTED:
      return setProcessingCompleted(state, action);

    case UPDATED_SENSOR_INVENTORY_PENDING:
      return setProcessingStart(state, action);

    case UPDATED_SENSOR_INVENTORY_FULFILLED:
      return updateSensorInventorySuccess(state, action);

    case UPDATED_SENSOR_INVENTORY_REJECTED:
      return setProcessingCompleted(state, action);

    case DELETE_SENSOR_INVENTORY_PENDING:
      return setProcessingStart(state, action);

    case DELETE_SENSOR_INVENTORY_FULFILLED:
      return setProcessingCompleted(state, action);

    case DELETE_SENSOR_INVENTORY_REJECTED:
      return setProcessingCompleted(state, action);

    case GET_SENSOR_INVENTORY_BY_ID_PENDING:
      return setLoadingStart(state, action);

    case GET_SENSOR_INVENTORY_BY_ID_FULFILLED:
      return getSensorInventoryByIdSuccess(state, action);

    case GET_SENSOR_INVENTORY_BY_ID_REJECTED:
      return setLoadingCompleted(state, action);

    default:
      return {
        ...state,
      };
  }
}
