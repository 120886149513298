export const GET_PROPERTY_PENDING = 'GET_PROPERTY_PENDING';
export const GET_PROPERTY_FULFILLED = 'GET_PROPERTY_FULFILLED';
export const GET_PROPERTY_REJECTED = 'GET_PROPERTY_REJECTED';
export const ADD_PROPERTY_PENDING = 'ADD_PROPERTY_PENDING';
export const ADD_PROPERTY_FULFILLED = 'ADD_PROPERTY_FULFILLED';
export const ADD_PROPERTY_REJECTED = 'ADD_PROPERTY_REJECTED';
export const UPDATED_PROPERTY_PENDING = 'UPDATED_PROPERTY_PENDING';
export const UPDATED_PROPERTY_FULFILLED = 'UPDATED_PROPERTY_FULFILLED';
export const UPDATED_PROPERTY_REJECTED = 'UPDATED_PROPERTY_REJECTED';
export const GET_AUTH_TOKEN_FULFILLED = 'GET_AUTH_TOKEN_FULFILLED';
export const DELETE_PROPERTY_PENDING = 'DELETE_PROPERTY_PENDING';
export const DELETE_PROPERTY_FULFILLED = 'DELETE_PROPERTY_FULFILLED';
export const DELETE_PROPERTY_REJECTED = 'DELETE_PROPERTY_REJECTED';
export const GET_PROPERTY_BY_ID_FULFILLED = 'GET_PROPERTY_BY_ID_FULFILLED';
export const GET_ASSIGNED_USER_PENDING = 'GET_ASSIGNED_USER_PENDING';
export const GET_ASSIGNED_USER_FULFILLED = 'GET_ASSIGNED_USER_FULFILLED';
export const GET_ASSIGNED_USER_REJECTED = 'GET_ASSIGNED_USER_REJECTED';
export const ADD_PROPERTY_ASSIGNED_USER_PENDING =
  'ADD_PROPERTY_ASSIGNED_USER_PENDING';
export const ADD_PROPERTY_ASSIGNED_USER_FULFILLED =
  'ADD_PROPERTY_ASSIGNED_USER_FULFILLED';
export const ADD_PROPERTY_ASSIGNED_USER_REJECTED =
  'ADD_PROPERTY_ASSIGNED_USER_REJECTED';
export const ADD_PROPERTY_UNASSIGNED_USER_PENDING =
  'ADD_PROPERTY_UNASSIGNED_USER_PENDING';
export const ADD_PROPERTY_UNASSIGNED_USER_FULFILLED =
  'ADD_PROPERTY_UNASSIGNED_USER_FULFILLED';
export const ADD_PROPERTY_UNASSIGNED_USER_REJECTED =
  'ADD_PROPERTY_UNASSIGNED_USER_REJECTED';
export const GET_PROPERTY = 'GET_PROPERTY';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const UPDATED_PROPERTY = 'UPDATED_PROPERTY';
export const GET_PROPERTY_BY_ID = 'GET_PROPERTY_BY_ID';
export const ADD_PROPERTY_UNASSIGNED_USER = 'ADD_PROPERTY_UNASSIGNED_USER';
export const ADD_PROPERTY_ASSIGNED_USER = 'ADD_PROPERTY_ASSIGNED_USER';
export const GET_ASSIGNED_USER = 'GET_ASSIGNED_USER';
export const UPLOAD_END_DEVICE_HISTORY = 'UPLOAD_END_DEVICE_HISTORY';
export const GET_OWNER_LIST = 'GET_OWNER_LIST';
export const GET_OWNER_LIST_FULFILLED = 'GET_OWNER_LIST_FULFILLED';
