import {
  GET_PROJECT_BY_ID_PENDING,
  GET_PROJECT_BY_ID_FULFILLED,
  GET_PROJECT_BY_ID_REJECTED,
} from './ProjectActionTypes';
const initialState = {
  processing: false,
  projectIdData: {},
};

const getProjectInfoByIdPending = (state) => ({
  ...state,
  processing: true,
});

const getProjectInfoByIdSuccess = (state, action) => {
  return {
    ...state,
    projectIdData: action.payload.data.data,
    processing: false,
  };
};

const getProjectInfoByIdRejected = (state) => ({
  ...state,
  processing: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_BY_ID_PENDING:
      return getProjectInfoByIdPending(state, action);

    case GET_PROJECT_BY_ID_FULFILLED:
      return getProjectInfoByIdSuccess(state, action);

    case GET_PROJECT_BY_ID_REJECTED:
      return getProjectInfoByIdRejected(state, action);

    default:
      return {
        ...state,
      };
  }
}
