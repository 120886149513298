import {
  SET_ANNOTATION,
  UPDATE_ANNOTATION,
  SET_ANNOTATION_TYPES,
  SET_PHOTOS,
  UPDATE_PHOTO,
  RESET_PHOTOS,
} from './AnnotationActionType';

const initialState = {
  photos: [],
  totalPhotos: 0,
  annotations: [],
  totalAnnotations: 0,
  filteredAnnotation: [],
  isLoadingAnnotation: true,
  annotationTypes: [],
  totalAnnotationTypes: 0,
  annotationTypesLoading: true,
};

export const setPhotos = (state, action) => {
  return {
    ...state,
    photos: action.payload.records.map((image) => {
      return { ...image, isLoading: false, isAnnotated: true };
    }),
    totalPhotos: action.payload.totalCount,
  };
};

export const resetPhotos = (state) => {
  return {
    ...state,
    photos: [],
    totalPhotos: 0,
  };
};

export const updatePhoto = (state, action) => {
  const { id } = action.payload;
  return {
    ...state,
    photos: state.photos.map((item) =>
      item.id === id ? { ...item, ...action.payload } : item,
    ),
  };
};

export const setAnnotations = (state, action) => {
  return {
    ...state,
    annotations: action.payload.records,
    totalAnnotations: action.payload.totalCount,
    filteredAnnotation: action?.payload?.records?.map((annotation) => {
      return { ...annotation, isLoading: false, isAnnotated: true };
    }),
    isLoadingAnnotation: false,
  };
};

export const updateSelectedAnnotation = (state, action) => {
  const { id } = action.payload;
  return {
    ...state,
    filteredAnnotation: state.filteredAnnotation.map((item) =>
      item.id === id ? { ...item, ...action.payload } : item,
    ),
  };
};

export const startAnnotationTypeLoading = (state) => ({
  ...state,
  annotationTypesLoading: true,
});

export const setAnnotationTypes = (state = initialState, action) => {
  return {
    ...state,
    annotationTypes: action.payload.records,
    totalAnnotationTypes: action.payload.totalCount,
    annotationTypesLoading: false,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ANNOTATION:
      return setAnnotations(state, action);
    case UPDATE_ANNOTATION:
      return updateSelectedAnnotation(state, action);
    case SET_ANNOTATION_TYPES:
      return setAnnotationTypes(state, action);
    case SET_PHOTOS:
      return setPhotos(state, action);
    case RESET_PHOTOS:
      return resetPhotos(state);
    case UPDATE_PHOTO:
      return updatePhoto(state, action);
    default:
      return {
        ...state,
      };
  }
}
