export const projectfullIcon = [
  '22 20',
  `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.83203 5.52222H8.12351V6.94813H4.83203V5.52222Z" fill="#7C7C7E"/>
<path d="M4.83203 8.76489H8.12351V10.1908H4.83203V8.76489Z" fill="#7C7C7E"/>
<path d="M4.83203 12.0078H8.12351V13.4337H4.83203V12.0078Z" fill="#7C7C7E"/>
<path d="M4.83203 15.2505H8.12351V16.6764H4.83203V15.2505Z" fill="#7C7C7E"/>
<path d="M11.4851 18.574V6.92302L18.7014 6.91433V14.7742H20.0826V6.20054C20.0826 5.81159 19.7676 5.48757 19.3911 5.48757L10.7937 5.49792C10.4126 5.4984 10.1039 5.81743 10.1039 6.2109V18.574H2.84526V4.46572L10.1039 2.45097V4.06964H11.4851V1.521C11.4851 1.05974 11.0472 0.712587 10.6152 0.832485L1.97534 3.23059C1.67373 3.31434 1.46407 3.59665 1.46407 3.9191V18.574H0V19.9999H21.5466V18.574H11.4851Z" fill="#7C7C7E"/>
<path d="M18.7031 16.0569H20.0843V17.3851H18.7031V16.0569Z" fill="#7C7C7E"/>
<path d="M13.4238 8.76392H16.7153V10.1898H13.4238V8.76392Z" fill="#7C7C7E"/>
<path d="M13.4238 12.0056H16.7153V13.4315H13.4238V12.0056Z" fill="#7C7C7E"/>
<path d="M13.4238 15.2483H16.7153V16.6742H13.4238V15.2483Z" fill="#7C7C7E"/>
</svg>`,
];
