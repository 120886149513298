export const notesIcon = [
  '27 27',
  `<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="8" fill="#24A5ED" fill-opacity="0.15"/>
<path d="M42 16H24C23.7352 16.0037 23.4816 16.1073 23.29 16.29L17.29 22.29C17.1973 22.3834 17.124 22.4943 17.0742 22.6161C17.0245 22.7379 16.9993 22.8684 17 23V47C17 47.2652 17.1054 47.5196 17.2929 47.7071C17.4804 47.8946 17.7348 48 18 48H42C42.2652 48 42.5196 47.8946 42.7071 47.7071C42.8947 47.5196 43 47.2652 43 47V17C43 16.7348 42.8947 16.4804 42.7071 16.2929C42.5196 16.1054 42.2652 16 42 16V16ZM23 19.41V22H20.41L23 19.41ZM41 46H19V24H24C24.2652 24 24.5196 23.8946 24.7071 23.7071C24.8947 23.5196 25 23.2652 25 23V18H41V46Z" fill="#24A5ED"/>
<path d="M32 30H22V32H32V30Z" fill="#24A5ED"/>
<path d="M38 35H22V37H38V35Z" fill="#24A5ED"/>
<path d="M32 40H22V42H32V40Z" fill="#24A5ED"/>
</svg> `,
];
