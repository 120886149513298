export const GET_ZONES_PENDING = 'GET_ZONES_PENDING';
export const GET_ZONES_FULFILLED = 'GET_ZONES_FULFILLED';
export const GET_ZONES_REJECTED = 'GET_ZONES_REJECTED';
export const GET_ZONES_BY_ID_PENDING = 'GET_ZONES_BY_ID_PENDING';
export const GET_ZONES_BY_ID_FULFILLED = 'GET_ZONES_BY_ID_FULFILLED';
export const GET_ZONES_BY_ID_REJECTED = 'GET_ZONES_BY_ID_REJECTED';
export const ADD_ZONE_PENDING = 'ADD_ZONE_PENDING';
export const ADD_ZONE_FULFILLED = 'ADD_ZONE_FULFILLED';
export const ADD_ZONE_REJECTED = 'ADD_ZONE_REJECTED';
export const DELETE_ZONE_PENDING = 'DELETE_ZONE_PENDING';
export const DELETE_ZONE_FULFILLED = 'DELETE_ZONE_FULFILLED';
export const DELETE_ZONE_REJECTED = 'DELETE_ZONE_REJECTED';
export const UPDATED_ZONE_PENDING = 'UPDATED_ZONE_PENDING';
export const UPDATED_ZONE_FULFILLED = 'UPDATED_ZONE_FULFILLED';
export const UPDATED_ZONE_REJECTED = 'UPDATED_ZONE_REJECTED';
export const GET_ASSIGNED_ZONE_GATEWAYS_PENDING =
  'GET_ASSIGNED_ZONE_GATEWAYS_PENDING';
export const GET_ASSIGNED_ZONE_GATEWAYS_FULFILLED =
  'GET_ASSIGNED_ZONE_GATEWAYS_FULFILLED';
export const GET_ASSIGNED_ZONE_GATEWAYS_REJECTED =
  'GET_ASSIGNED_ZONE_GATEWAYS_REJECTED';
export const GET_ASSIGNED_ZONE_DEVICES_PENDING =
  'GET_ASSIGNED_ZONE_DEVICES_PENDING';
export const GET_ASSIGNED_ZONE_DEVICES_FULFILLED =
  'GET_ASSIGNED_ZONE_DEVICES_FULFILLED';
export const GET_ASSIGNED_ZONE_DEVICES_REJECTED =
  'GET_ASSIGNED_ZONE_DEVICES_REJECTED';
export const GET_ASSIGNED_ZONE_USERS_PENDING =
  'GET_ASSIGNED_ZONE_USERS_PENDING';
export const GET_ASSIGNED_ZONE_USERS_FULFILLED =
  'GET_ASSIGNED_ZONE_USERS_FULFILLED';
export const GET_ASSIGNED_ZONE_USERS_REJECTED =
  'GET_ASSIGNED_ZONE_USERS_REJECTED';
export const ASSIGNED_ZONE_TO_USER_PENDING = 'ASSIGNED_ZONE_TO_USER_PENDING';
export const ASSIGNED_ZONE_TO_USER_FULFILLED =
  'ASSIGNED_ZONE_TO_USER_FULFILLED';
export const ASSIGNED_ZONE_TO_USER_REJECTED = 'ASSIGNED_ZONE_TO_USER_REJECTED';
export const UNASSIGNED_ZONE_FROM_USER_PENDING =
  'UNASSIGNED_ZONE_FROM_USER_PENDING';
export const UNASSIGNED_ZONE_FROM_USER_FULFILLED =
  'UNASSIGNED_ZONE_FROM_USER_FULFILLED';
export const UNASSIGNED_ZONE_FROM_USER_REJECTED =
  'UNASSIGNED_ZONE_FROM_USER_REJECTED';
export const GET_ZONES = 'GET_ZONES';
export const GET_ZONES_BY_ID = 'GET_ZONES_BY_ID';
export const ADD_ZONE = 'ADD_ZONE';
export const DELETE_ZONE = 'DELETE_ZONE';
export const UPDATED_ZONE = 'UPDATED_ZONE';
export const ASSIGNED_ZONE_TO_USER = 'ASSIGNED_ZONE_TO_USER';
export const UNASSIGNED_ZONE_FROM_USER = 'UNASSIGNED_ZONE_FROM_USER';
