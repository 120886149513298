export const FUOTA_JOB_TABS = [
  { title: 'Step 1', value: '1' },
  { title: 'Step 2 ', value: '2' },
  { title: 'Step 3 ', value: '3' },
  { title: 'Step 4', value: '4' },
];

export const FRAGMENTATION_LIST = [
  { value: 'NB_TRANS', label: 'Retransmit' },
  { value: 'FWD_ERR_CORR', label: 'Forward Error Correction' },
];

export const REDUNDANCY_LIST = [
  { value: 0, label: '0%' },
  { value: 0.1, label: '10%' },
  { value: 0.2, label: '20%' },
  { value: 0.3, label: '30%' },
  { value: 0.4, label: '40%' },
  { value: 0.5, label: '50%' },
  { value: 0.6, label: '60%' },
  { value: 0.7, label: '70%' },
  { value: 0.8, label: '80%' },
  { value: 0.9, label: '90%' },
  { value: 1, label: '100%' },
];

export const TX_CHANNEL_LIST_US = [
  { value: 0, label: '923.3' },
  { value: 1, label: '923.9' },
  { value: 2, label: '924.5' },
  { value: 3, label: '925.1' },
  { value: 4, label: '925.7' },
  { value: 5, label: '926.3' },
  { value: 6, label: '926.9' },
  { value: 7, label: '927.5' },
];

export const TX_CHANNEL_LIST_EU = [
  { value: 0, label: '868.1' },
  { value: 1, label: '868.3' },
  { value: 2, label: '868.5' },
  { value: 3, label: '867.1' },
  { value: 4, label: '867.3' },
  { value: 5, label: '867.5' },
  { value: 6, label: '867.7' },
  { value: 7, label: '867.9' },
  { value: 18, label: '869.525' },
];

export const TX_DATA_RATE_LIST_US = [
  { label: 'DR8: SF12 / 500kHz', value: 8 },
  { label: 'DR9: SF11 / 500kHz', value: 9 },
  { label: 'DR10: SF10 / 500kHz', value: 10 },
  { label: 'DR11: SF9 / 500kHz', value: 11 },
  { label: 'DR12: SF8 / 500kHz', value: 12 },
  { label: 'DR13: SF7 / 500kHz', value: 13 },
];

export const TX_DATA_RATE_LIST_EU = [
  { label: 'DR0: SF12 / 125kHz', value: 0 },
  { label: 'DR1: SF11 / 125kHz', value: 1 },
  { label: 'DR2: SF10 / 125kHz', value: 2 },
  { label: 'DR3: SF9 / 125kHz', value: 3 },
  { label: 'DR4: SF8 / 125kHz', value: 4 },
  { label: 'DR5: SF7 / 125kHz', value: 5 },
  { label: 'DR6: SF7 / 250kHz', value: 6 },
  { label: 'DR8: SF-1 / 137kHz', value: 8 },
  { label: 'DR9: SF-1 / 137kHz', value: 9 },
  { label: 'DR10: SF-1 / 336kHz', value: 10 },
  { label: 'DR11: SF-1 / 336kHz', value: 11 },
];

export const FUOTA_VERSION_LIST = [
  { value: 1, label: 'V 1.0' },
  { value: 2, label: 'V 2.0' },
];

export const MULTI_PACKAGE_SUPPORT_LIST = [
  { value: false, label: 'Disabled' },
  { value: true, label: 'Enabled' },
];

export const DEVICE_CLASS_LIST = [
  { value: 'A', label: 'Class A' },
  { value: 'C', label: 'Class C' },
];

//JOB TYPES
export const JOB_TYPES = [
  { value: 'device', label: 'Device' },
  { value: 'tag', label: 'Tags' },
];

export const SCHEDULE_FUOTA_JOB = [
  { value: 'later', label: 'Schedule FUOTA for later' },
  {
    value: 'interval',
    label: 'Start FUOTA based on device heartbeat interval',
  },
];
export const HEARTBEAT_MARGIN_OPTIONS = [
  { value: 0, label: '0%' },
  { value: 5, label: '5%' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
  { value: 25, label: '25%' },
  { value: 30, label: '30%' },
];

export const REGION_OPTIONS = [
  { value: 'US', label: 'United States' },
  { value: 'EU', label: 'Europe' },
];
