import {
  GET_PRODUCTS_PENDING,
  GET_PRODUCTS_FULFILLED,
  GET_PRODUCTS_REJECTED,
  GET_PRODUCT_BY_ID_PENDING,
  GET_PRODUCT_BY_ID_FULFILLED,
  GET_PRODUCT_BY_ID_REJECTED,
  DELETE_PRODUCT_PENDING,
  DELETE_PRODUCT_FULFILLED,
  DELETE_PRODUCT_REJECTED,
} from './ProductActionTypes';
const initialState = {
  productData: [],
  isLoading: false,
  productIdData: {},
  processingData: false,
  processingDelete: false,
  categoryData: [],
};

const getProductSuccess = (state, action) => {
  return {
    ...state,
    productData: action.payload.data.data,
    isLoading: false,
  };
};

const getProductByIdPending = (state) => ({
  ...state,
  processingData: true,
});

const getProductByIdSuccess = (state, action) => {
  return {
    ...state,
    productIdData: action.payload.data.data,
    processingData: false,
  };
};

const getProductByIdRejected = (state) => ({
  ...state,
  processingData: false,
});

const setProcessingPending = (state) => ({
  ...state,
  isLoading: true,
});

const setProcessingRejected = (state) => ({
  ...state,
  isLoading: false,
});

const deleteProductPending = (state) => ({
  ...state,
  processingDelete: true,
});

const deleteProductRejected = (state) => ({
  ...state,
  processingDelete: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_PENDING:
      return setProcessingPending(state, action);

    case GET_PRODUCTS_FULFILLED:
      return getProductSuccess(state, action);

    case GET_PRODUCTS_REJECTED:
      return setProcessingRejected(state, action);

    case GET_PRODUCT_BY_ID_PENDING:
      return getProductByIdPending(state, action);

    case GET_PRODUCT_BY_ID_FULFILLED:
      return getProductByIdSuccess(state, action);

    case GET_PRODUCT_BY_ID_REJECTED:
      return getProductByIdRejected(state, action);

    case DELETE_PRODUCT_PENDING:
      return deleteProductPending(state, action);

    case DELETE_PRODUCT_FULFILLED:
      return deleteProductRejected(state, action);

    case DELETE_PRODUCT_REJECTED:
      return deleteProductRejected(state, action);

    default:
      return {
        ...state,
      };
  }
}
