import {
  ADD_JOB_FULFILLED,
  ADD_JOB_PENDING,
  ADD_JOB_REJECTED,
  NEXT_TAB,
  PREVIOUS_TAB,
  RESET_FORM,
  SAVE_FORM,
  GET_FUOTA_JOB_BY_ID_FULFILLED,
  GET_FUOTA_JOB_BY_ID_PENDING,
  GET_FUOTA_JOB_BY_ID_REJECTED,
} from './FuotaActionTypes';
import { FUOTA_JOB_TABS } from './FuotaConstants';
import { NOTIFICATION } from '../../constants/index';
const initialState = {
  tabs: FUOTA_JOB_TABS,
  activeTab: '1',
  resetState: false,
  massage: undefined,
  addJobStatus: undefined,
  jobData: {},
  processing: false,
  fuotaJobIdData: [],
};

const nextTab = (state) => {
  return {
    ...state,
    activeTab: `${Number(state.activeTab) + 1}`,
  };
};
const previousTab = (state) => {
  return {
    ...state,
    activeTab: `${Number(state.activeTab) - 1}`,
  };
};
const resetForm = (state) => {
  return {
    ...initialState,
    resetState: !state.resetState,
  };
};
const saveForm = (state, action) => {
  return {
    ...state,
    jobData: { ...state.jobData, ...action.payload },
  };
};

const addJobPending = (state) => {
  return {
    ...state,
    massage: undefined,
    addJobStatus: undefined,
  };
};
const addJobFulfilled = (state) => {
  return {
    ...state,
    message: 'Job added successfully',
    addJobStatus: NOTIFICATION.SUCCESS,
  };
};
const addJobRejected = (state, action) => {
  const errorMessage = action.payload.response.data.message;
  return {
    ...state,
    message: errorMessage,
    addJobStatus: NOTIFICATION.ERROR,
  };
};

const setProcessingStart = (state) => {
  return {
    ...state,
    processing: true,
  };
};

const getFuotaJobByIdSuccess = (state, action) => {
  return {
    ...state,
    processing: false,
    fuotaJobIdData: action.payload.data,
  };
};

const setProcessingCompleted = (state) => {
  return {
    ...state,
    processing: false,
  };
};
export default function (state = initialState, action) {
  switch (action.type) {
    case NEXT_TAB:
      return nextTab(state);
    case PREVIOUS_TAB:
      return previousTab(state);
    case RESET_FORM:
      return resetForm(state);
    case SAVE_FORM:
      return saveForm(state, action);
    case ADD_JOB_PENDING:
      return addJobPending(state, action);
    case ADD_JOB_FULFILLED:
      return addJobFulfilled(state, action);
    case ADD_JOB_REJECTED:
      return addJobRejected(state, action);
    case GET_FUOTA_JOB_BY_ID_PENDING:
      return setProcessingStart(state);
    case GET_FUOTA_JOB_BY_ID_FULFILLED:
      return getFuotaJobByIdSuccess(state, action);
    case GET_FUOTA_JOB_BY_ID_REJECTED:
      return setProcessingCompleted(state);
    default:
      return {
        ...state,
      };
  }
}
