export const timeZoneMapping = [
  //   {
  //     code: 'GMT',
  //     name: 'Greenwich Mean Time',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'UTC',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'ECT',
  //     name: 'European Central Time',
  //     zone: 'Europe/Amsterdam',
  //   },
  //   {
  //     code: 'EET',
  //     name: 'Eastern European Time',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'ART',
  //     name: '(Arabic) Egypt Time',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'EAT',
  //     name: 'Eastern African Time',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'MET',
  //     name: 'Middle East Time',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'NET',
  //     name: 'Near East Time',
  //     zone: 'test',
  //   },
  //   {
  //     code: 'PLT',
  //     zone: 'Asia/Karachi',
  //   },
  //   {
  //     code: 'IST',
  //     zone: 'Asia/Calcutta',
  //   },
  //   {
  //     code: 'BST',
  //     name: 'Bangladesh Time',
  //   },
  //   {
  //     code: 'VST',
  //     name: 'Vietnam Time',
  //   },
  //   {
  //     code: 'CTT',
  //     zone: 'Asia/Hong_Kong',
  //   },
  //   {
  //     code: 'JST',
  //     name: 'Japan',
  //   },
  //   {
  //     code: 'ACT',
  //     zone: 'Australia/Adelaide',
  //   },
  //   {
  //     code: 'AET',
  //     name: 'Australia/ACT',
  //   },
  {
    code: 'SST',
    zone: 'Pacific/Guadalcanal',
  },
  {
    code: 'NST',
    zone: 'Pacific/Auckland',
  },
  {
    code: 'MIT',
    zone: 'Pacific/Midway',
  },
  {
    code: 'HT',
    zone: 'US/Hawaii',
  },
  {
    code: 'AT',
    zone: 'US/Alaska',
  },
  {
    code: 'PT',
    zone: 'America/Los_Angeles',
  },
  {
    code: 'PNT',
    zone: 'America/Phoenix',
  },
  {
    code: 'MT',
    zone: 'America/Denver',
  },
  {
    code: 'CT',
    zone: 'America/Chicago',
  },
  {
    code: 'ET',
    zone: 'America/New_York',
  },
  {
    code: 'IET',
    zone: 'America/Adak',
  },
  {
    code: 'PRT',
    zone: 'America/Puerto_Rico',
  },
  {
    code: 'CNT',
    zone: 'Canada/Newfoundland',
  },
  {
    code: 'AGT',
    zone: 'America/Argentina/Buenos_Aire',
  },
  {
    code: 'BET',
    zone: 'Brazil/East',
  },
  {
    code: 'CAT',
    zone: 'America/Adak',
  },
  {
    code: 'HST',
    zone: 'America/Adak',
  },
  {
    code: 'AST',
    zone: 'US/Alaska',
  },
  {
    code: 'PST',
    zone: 'America/Los_Angeles',
  },
  {
    code: 'MST',
    zone: 'America/Denver',
  },
  {
    code: 'CST',
    zone: 'America/Chicago',
  },
  {
    code: 'EST',
    zone: 'America/New_York',
  },
];
