import {
  GET_MODEL_PENDING,
  GET_MODEL_FULFILLED,
  GET_MODEL_REJECTED,
} from './ModelActionTypes';
const initialState = {
  isLoading: false,
  modelData: {},
};

const getModelPending = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const getModelSuccess = (state, action) => {
  return {
    ...state,
    modelData: action.payload.data.data,
    isLoading: false,
  };
};

const getModelFail = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MODEL_PENDING:
      return getModelPending(state, action);

    case GET_MODEL_FULFILLED:
      return getModelSuccess(state, action);

    case GET_MODEL_REJECTED:
      return getModelFail(state, action);

    default:
      return {
        ...state,
      };
  }
}
