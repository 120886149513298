export const GET_END_DEVICE_PENDING = 'GET_END_DEVICE_PENDING';
export const GET_END_DEVICE_FULFILLED = 'GET_END_DEVICE_FULFILLED';
export const GET_END_DEVICE_REJECTED = 'GET_END_DEVICE_REJECTED';

export const GET_END_DEVICE_BY_ID_PENDING = 'GET_END_DEVICE_BY_ID_PENDING';
export const GET_END_DEVICE_BY_ID_FULFILLED = 'GET_END_DEVICE_BY_ID_FULFILLED';
export const GET_END_DEVICE_BY_ID_REJECTED = 'GET_END_DEVICE_BY_ID_REJECTED';

export const ADD_END_DEVICE_PENDING = 'ADD_END_DEVICE_PENDING';
export const ADD_END_DEVICE_FULFILLED = 'ADD_END_DEVICE_FULFILLED';
export const ADD_END_DEVICE_REJECTED = 'ADD_END_DEVICE_REJECTED';

export const DELETE_END_DEVICE_PENDING = 'DELETE_END_DEVICE_PENDING';
export const DELETE_END_DEVICE_FULFILLED = 'DELETE_END_DEVICE_FULFILLED';
export const DELETE_END_DEVICE_REJECTED = 'DELETE_END_DEVICE_REJECTED';

export const UPDATED_END_DEVICE_PENDING = 'UPDATED_END_DEVICE_PENDING';
export const UPDATED_END_DEVICE_FULFILLED = 'UPDATED_END_DEVICE_FULFILLED';
export const UPDATED_END_DEVICE_REJECTED = 'UPDATED_END_DEVICE_REJECTED';

export const GET_END_DEVICE = 'GET_END_DEVICE';
export const GET_END_DEVICE_BY_ID = 'GET_END_DEVICE_BY_ID';
export const ADD_END_DEVICE = 'ADD_END_DEVICE';
export const DELETE_END_DEVICE = 'DELETE_END_DEVICE';
export const UPDATED_END_DEVICE = 'UPDATED_END_DEVICE';
