export const GET_CLIENT_PENDING = 'GET_CLIENT_PENDING';
export const GET_CLIENT_FULFILLED = 'GET_CLIENT_FULFILLED';
export const GET_CLIENT_REJECTED = 'GET_CLIENT_REJECTED';
export const ADD_CLIENT_PENDING = 'ADD_CLIENT_PENDING';
export const ADD_CLIENT_FULFILLED = 'ADD_CLIENT_FULFILLED';
export const ADD_CLIENT_REJECTED = 'ADD_CLIENT_REJECTED';
export const UPDATED_CLIENT_PENDING = 'UPDATED_CLIENT_PENDING';
export const UPDATED_CLIENT_FULFILLED = 'UPDATED_CLIENT_FULFILLED';
export const UPDATED_CLIENT_REJECTED = 'UPDATED_CLIENT_REJECTED';
export const GET_AUTH_TOKEN_FULFILLED = 'GET_AUTH_TOKEN_FULFILLED';
export const DELETE_CLIENT_PENDING = 'DELETE_CLIENT_PENDING';
export const DELETE_CLIENT_FULFILLED = 'DELETE_CLIENT_FULFILLED';
export const DELETE_CLIENT_REJECTED = 'DELETE_CLIENT_REJECTED';
export const GET_CLIENT_BY_ID_PENDING = 'GET_CLIENT_BY_ID_PENDING';
export const GET_CLIENT_BY_ID_FULFILLED = 'GET_CLIENT_BY_ID_FULFILLED';
export const GET_CLIENT_BY_ID_REJECTED = 'GET_CLIENT_BY_ID_REJECTED';
export const GET_CLIENT_ASSIGNED_USER_PENDING =
  'GET_CLIENT_ASSIGNED_USER_PENDING';
export const GET_CLIENT_ASSIGNED_USER_FULFILLED =
  'GET_CLIENT_ASSIGNED_USER_FULFILLED';
export const GET_CLIENT_ASSIGNED_USER_REJECTED =
  'GET_CLIENT_ASSIGNED_USER_REJECTED';
export const GET_CLIENT_CUSTOMER_PENDING = 'GET_CLIENT_CUSTOMER_PENDING';
export const GET_CLIENT_CUSTOMER_FULFILLED = 'GET_CLIENT_CUSTOMER_FULFILLED';
export const GET_CLIENT_CUSTOMER_REJECTED = 'GET_CLIENT_CUSTOMER_REJECTED';
export const GET_CLIENT = 'GET_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const UPDATED_CLIENT = 'UPDATED_CLIENT';
export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID';
export const GET_CLIENT_ASSIGNED_USER = 'GET_CLIENT_ASSIGNED_USER';
export const GET_CLIENT_CUSTOMER = 'GET_CLIENT_CUSTOMER';
export const SEARCH_PROJECT = 'SEARCH_PROJECT';
export const GET_CLIENT_TYPE_ROLES = 'GET_CLIENT_TYPE_ROLES';
export const GET_CLIENT_TYPE_ROLES_FULFILLED =
  'GET_CLIENT_TYPE_ROLES_FULFILLED';
export const SET_THEME_PREVIEW = 'SET_THEME_PREVIEW';
export const RESET_THEME_PREVIEW = 'RESET_THEME_PREVIEW';
export const HIDE_THEME_PREVIEW_MODAL = 'HIDE_THEME_PREVIEW_MODAL';
